import React from 'react';
import { navigate } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';
import { SHOP_URL } from '../../../constants';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../../styles/buttons';
import { bpWidthTailwind, colors } from '../../../styles/variables';
import { isBrowser } from '../../../utils/helper';
import { tiktokClickButton } from '../../../utils/tiktokEvents';

const ShopButton = styled.button`
  ${btnSmPrimaryThinOrangeStyles};
  cursor: pointer;
  width: auto;
  padding: 0 20px;

  :hover {
    border-color: ${colors.orange700};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${btnLgPrimaryThinOrangeStyles};
    cursor: pointer;
    width: auto;
    padding: 0 20px;
    pointer-events: auto;

    :hover {
      border-color: ${colors.orange700};
    }
  }
`;

function FixedShopCta() {
  const ttq = isBrowser ? window.ttq || {} : {};

  const shopButtonClick = (e) => {
    e.preventDefault();

    if (_.has(ttq, 'track')) {
      // ensure ttq has been loaded
      tiktokClickButton(ttq);
    }

    return navigate(SHOP_URL);
  };

  return (
    <ShopButton type="button" className="bg-orange-400 hover:bg-orange-700" onClick={shopButtonClick}>
      Learn more
    </ShopButton>
  );
}

export default FixedShopCta;
