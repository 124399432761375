import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bpWidth } from '../../../styles/variables';

const CategoriesRow = styled(Row)`
  justify-content: center;
  gap: 30px;
`;

const CategoryCol = styled(Col)`
  width: calc(100% / 3 - 20px);

  @media (${bpWidth.tablet}) {
    width: calc(100% / 2 - 20px);
  }

  @media (${bpWidth.mobile}) {
    width: 100%;
  }
`;

const PromoteCategories = ({ blok }) => {
  const { category } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <CategoriesRow>
        {category?.map((nestedBlok) => (
          <CategoryCol key={nestedBlok._uid}>
            <StoryblokComponent blok={nestedBlok} />
          </CategoryCol>
        ))}
      </CategoriesRow>
    </Col>
  );
};

PromoteCategories.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default PromoteCategories;
