import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { func } from 'prop-types';
import * as Yup from 'yup';
import { STATUS_FAILURE, STATUS_INIT, STATUS_SUCCESS } from '../../common/constants/formikStatus.constant';
import { consentChoiceValue } from '../../common/constants/privacyPolicyPopup.constant';
import PrivacyPolicyConfirmationPopup from '../PrivacyPolicy/PrivacyPolicyConfirmationPopup';
import ContactForm from './ContactForm.jsx';

function FormikWrapper(props) {
  const { formStatus } = props;

  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);

  const formik = useFormik({
    initialStatus: STATUS_INIT,
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      newsletterSubscribe: false,
      topic: '',
      message: '',
      consent: 0,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      topic: Yup.string().required('Please choose a topic'),
      message: Yup.string(),
    }),
    onSubmit: (values, actions) => {
      if (!values.consent) {
        setOpenPrivacyPolicyPopup(true);
        return;
      }

      setOpenPrivacyPolicyPopup(false);

      const { setStatus, resetForm, setSubmitting } = actions;

      const data = {
        fields: [
          {
            name: 'firstname',
            value: values.firstname,
          },
          {
            name: 'lastname',
            value: values.lastname,
          },
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'website_inbound_topic',
            value: values.topic,
          },
          {
            name: 'gita_newsletter_subscriber',
            value: values.newsletterSubscribe,
          },
          {
            name: 'website_inbound_message',
            value: values.message,
          },
        ],
        context: {
          pageName: document.title ?? '',
          pageUri: window.location?.href ?? '',
        },
      };

      // Add hubspot tracking cookie if available
      const hubspotTrackingCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');

      if (hubspotTrackingCookie !== '') data.context.hutk = hubspotTrackingCookie;

      // Selecting HubSpot form from production or staging
      let url = '';
      if (
        typeof window !== 'undefined' &&
        typeof document !== 'undefined' &&
        !window.location.href.includes('localhost:') &&
        !window.location.href.includes('staging')
      ) {
        // Production
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/07c49513-9732-4114-a0af-fd5103fdb706';
      } else {
        // Staging
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/54911527-c2dd-4933-a02c-fa120aaeeadb';
      }

      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          // response.ok checks if the response.status is in the range 200-299, inclusive
          setStatus(response.ok ? STATUS_SUCCESS : STATUS_FAILURE);

          // Save status response in 'formStatus' variable in order to trigger successful submission copy
          formStatus(response.ok ? STATUS_SUCCESS : STATUS_FAILURE);

          // Clear selected fields after form submits
          resetForm();
        })
        .catch(() => {
          setStatus(STATUS_FAILURE);
          formStatus(STATUS_FAILURE);
          resetForm({
            values: {
              ...values,
              consent: 0,
            },
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const onChoosingConsent = (choice) => {
    if (choice === consentChoiceValue.consent) {
      formik.values.consent = choice;
      formik.handleSubmit();
    } else {
      setOpenPrivacyPolicyPopup(false);
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <FormikProvider value={formik}>
        <ContactForm {...formik} />
      </FormikProvider>
      {openPrivacyPolicyPopup && <PrivacyPolicyConfirmationPopup onPopUpClose={onChoosingConsent} />}
    </>
  );
}

FormikWrapper.propTypes = {
  formStatus: func,
};

export default FormikWrapper;
