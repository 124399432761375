import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bool, object } from 'prop-types';

const TabItem = ({ blok, stickyTop }) => (
  <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12} className="tabs-component--content-item">
    {blok.content?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} stickyTop={stickyTop} />
    ))}
  </Col>
);

TabItem.propTypes = {
  blok: object.isRequired,
  stickyTop: bool,
};

export default TabItem;
