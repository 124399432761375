import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  bodyLRegularUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  headerMStyles,
  headerSStyles,
} from '../../../styles/typography';
import { bpWidthTailwind, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { FadeInOnView } from '../../Animation/FadeInOnView';
import { TabbedSlickSlider } from '../../Carousels/CarouselStyles';

const CopyRow = styled(Row)`
  margin-top: 50px;

  @media (${bpWidthTailwind.desktopSm}) {
    margin-top: 70px;
  }
`;

export const CaptionHeading = styled.h2`
  padding-right: 20px;
  margin-top: -6px;
  margin-bottom: 8px;

  ${bodyMBoldBrownStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.tablet}) {
    margin-bottom: 12px;
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerMStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

export const Caption = styled.div`
  ${bodySRegularUtopiaStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyMRegularUtopiaStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyLRegularUtopiaStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const Slider3 = ({ blok }) => {
  const {
    assets: slideList = [],
    customClass = 'healthier-you',
    swipe = false,
    touchMove = false,
    swipeToSlide = false,
    centerMode = false,
    darkMode = false,
  } = blok;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [captionHeadingRichText, setCaptionHeadingRichText] = useState(null);
  const [captionRichText, setCaptionRichText] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    swipe,
    touchMove,
    swipeToSlide,
    centerMode,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlideIndex(newIndex);
    },
    onInit: () => {
      setCurrentSlideIndex(0);
    },
  };

  useEffect(() => {
    const currentSlideData = slideList[currentSlideIndex];
    setCurrentSlide(currentSlideData);
    setCaptionHeadingRichText(renderRichTextReact(currentSlideData?.captionHeading));
    setCaptionRichText(renderRichTextReact(currentSlideData?.caption));
  }, [slideList, currentSlideIndex]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {currentSlide?.content?.map((nestedBlok) => (
        <Row key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </Row>
      ))}
      <Row>
        <Col xsOffset={0} xs={8} smOffset={0} sm={8} mdOffset={2} md={6} lgOffset={2} lg={8}>
          <TabbedSlickSlider {...settings} className={customClass} style={{ marginTop: '25px', height: 'auto' }}>
            {slideList?.map((nestedBlok) => (
              <Row key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </Row>
            ))}
          </TabbedSlickSlider>
          {(captionHeadingRichText || captionRichText) && (
            <CopyRow>
              <Col xsOffset={1} xs={6} lg={9}>
                <FadeInOnView>
                  {captionHeadingRichText && (
                    <CaptionHeading darkMode={darkMode}>{captionHeadingRichText}</CaptionHeading>
                  )}
                  {captionRichText && <Caption darkMode={darkMode}>{captionRichText}</Caption>}
                </FadeInOnView>
              </Col>
            </CopyRow>
          )}
        </Col>
      </Row>
    </Col>
  );
};

Slider3.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider3;
