import { useState } from 'react';
import * as THEME from '../constants/theme';
import { sizes } from '../styles/variables';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

/**
 * @param {number} viewWidth
 */
export function useCurrentTheme(viewWidth) {
  const [currentTheme, setCurrentTheme] = useState(THEME.THEME_DESKTOP_LARGE);

  // Update grid column count on window resize
  useIsomorphicLayoutEffect(() => {
    if (viewWidth < sizes.containerMinWidth.tablet) {
      setCurrentTheme(THEME.THEME_MOBILE);
    } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
      setCurrentTheme(THEME.THEME_TABLET);
    } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
      setCurrentTheme(THEME.THEME_DESKTOP_SMALL);
    } else {
      setCurrentTheme(THEME.THEME_DESKTOP_LARGE);
    }
  }, [viewWidth]);

  return currentTheme;
}
