import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { bool, object } from 'prop-types';
import styled, { css } from 'styled-components';
import { useAppState } from '../../../context/app-state.context';
import { usePressPageState } from '../../../context/press-page-state.context';
import { colors } from '../../../styles/variables';
import FilterDropdown from '../press/FilterDropdown';

const FilterRow = styled(Row)`
  background-color: ${colors.beige100};
  width: 100%;
  padding-bottom: 1.8em;

  &.is-sticky {
    position: sticky;
    z-index: 4;
    top: ${({ tabHeaderRowHeight }) => `${tabHeaderRowHeight - 1}px`};
    transition: transform 0.5s;
  }

  ${({ tabHeaderRowHeight, headerHeight, translateY }) => {
    let yValue;
    if (translateY < 0) {
      yValue = 0;
    } else if (translateY > 1) {
      yValue = 1;
    } else {
      yValue = translateY;
    }

    yValue = (yValue || 0) * headerHeight * -1;

    return css`
      &.is-pinned {
        top: ${headerHeight + tabHeaderRowHeight - 1}px;
        transform: translate3d(0, ${yValue}px, 0);
      }
    `;
  }};
`;

const AccordionList = ({ blok, stickyTop }) => {
  const { items = [], darkMode = false, hasFilter = false } = blok;
  const { headerHeight, headerTranslateY } = useAppState();
  const { tabHeaderRowRef, filterRowRef } = usePressPageState();
  const [accordionList, setAccordionList] = useState([]);
  const [filteredList, setFilteredList] = useState([...accordionList]);
  /**
   * @type {DatasourceEntry[]}
   */
  const categoriesInitial = [];
  const [categories, setCategories] = useState(categoriesInitial);

  const filterClickHandler = (value) => {
    const filteredListData =
      value === 'all'
        ? [...accordionList]
        : accordionList
            .filter((item) => item._uid === value)
            .map((item) => {
              if (item._uid === value) {
                return {
                  ...item,
                  isExpand: true,
                };
              }

              return item;
            });
    setFilteredList(filteredListData);
  };

  useEffect(() => {
    setAccordionList(items);

    /**
     * @type {DatasourceEntry[]}
     */
    const categoriesData = items.map((item) => ({
      id: item._uid,
      name: item.heading,
      value: item._uid,
      dimension_value: null,
    }));
    setCategories(categoriesData);
  }, [items]);

  useEffect(() => {
    filterClickHandler('all');
  }, [accordionList]);

  const handleExpand = useCallback(
    (_uid) => {
      const newValueItems = (filteredList || []).map((value) => {
        if (value._uid === _uid) {
          return {
            ...value,
            isExpand: !value.isExpand,
          };
        }

        return value;
      });

      setFilteredList(newValueItems);
    },
    [accordionList, filteredList],
  );

  const tabHeaderRowHeight = useMemo(
    () => tabHeaderRowRef?.current?.clientHeight || 0,
    [tabHeaderRowRef?.current?.clientHeight],
  );
  const filterRowOffsetTop = useMemo(() => filterRowRef?.current?.offsetTop || 0, [filterRowRef?.current?.offsetTop]);
  const filterRowCssClass = useMemo(
    () =>
      [stickyTop ? 'is-sticky' : undefined, filterRowOffsetTop > tabHeaderRowHeight ? 'is-pinned' : undefined]
        .filter(Boolean)
        .join(' '),
    [stickyTop, tabHeaderRowHeight, filterRowOffsetTop],
  );

  return accordionList?.length ? (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {hasFilter && (
        <FilterRow
          tabHeaderRowHeight={tabHeaderRowHeight}
          headerHeight={headerHeight}
          translateY={headerTranslateY}
          className={filterRowCssClass}
          ref={filterRowRef}
        >
          <FilterDropdown optionList={categories} onFilter={filterClickHandler} />
        </FilterRow>
      )}
      <Row>
        {filteredList.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onExpand={handleExpand} darkMode={darkMode} />
        ))}
      </Row>
    </Col>
  ) : undefined;
};

AccordionList.propTypes = {
  blok: object.isRequired,
  stickyTop: bool,
};

export default AccordionList;
