import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { array } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { bpWidth, sizes } from '../../../styles/variables';

const SwipeSlider = styled(Slider)`
  height: 100%;
  width: 100%;

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained.slideImage {
    width: 100%;
  }
`;

const Slide = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
  }
`;

const SwipeCarousel = (props) => {
  const { variantSlides } = props;
  const { viewWidth } = useViewport();

  const [slides, setSlides] = useState([]);

  const settings = useMemo(
    () => ({
      arrows: false,
      dots: false,
      dotsClass: 'slick-dots slick-thumb',
      fade: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      swipe: true,
      touchMove: true,
      swipeToSlide: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: sizes.containerMinWidth.desktopSm,
          settings: {
            dots: true,
          },
        },
      ],
    }),
    [viewWidth],
  );

  React.useEffect(() => {
    setSlides(variantSlides);
  }, [variantSlides]);

  return (
    slides &&
    slides.length && (
      <SwipeSlider {...settings}>
        {slides.map((slide, index) => {
          if (!slide.assetLg?.filename) {
            return undefined;
          }

          const loadType = index < 4 ? 'eager' : 'lazy';

          // eslint-disable-next-line max-len
          let imageUrl =
            slide.assetLg?.filename || slide.assetMd?.filename || slide.assetSm?.filename || slide.assetXs?.filename;

          if (viewWidth < sizes.containerMinWidth.tablet) {
            if (slide.assetXs?.filename) {
              imageUrl = slide.assetXs.filename;
            }
          } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
            if (slide.assetSm?.filename) {
              imageUrl = slide.assetSm.filename;
            }
          } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
            if (slide.assetMd?.filename) {
              imageUrl = slide.assetMd.filename;
            }
          }

          /** @type { GetGatsbyImageOptions } */
          const options = {
            layout: slide.layout,
            quality: slide.quality,
          };

          const artDirected = [];
          if (slide.assetXs?.filename) {
            artDirected.push({
              media: `(${bpWidth.mobile})`,
              image: getGatsbyImage(slide.assetXs.filename, options),
            });
          }

          if (slide.assetSm?.filename) {
            artDirected.push({
              media: `(${bpWidth.tablet})`,
              image: getGatsbyImage(slide.assetSm.filename, options),
            });
          }

          if (slide.assetMd?.filename) {
            artDirected.push({
              media: `(${bpWidth.desktopSm})`,
              image: getGatsbyImage(slide.assetMd.filename, options),
            });
          }

          const imageData = withArtDirection(getGatsbyImage(imageUrl, options), artDirected);
          let media;

          if (slide.mediaType === 'video') {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            media = (
              <video autoPlay loop muted playsInline>
                <source src={`${imageUrl}`} type="video/webm" />
              </video>
            );
          } else {
            media = <GatsbyImage image={imageData} loading={loadType} alt="" className="slideImage" />;
          }

          return (
            <Slide key={slide._uid}>
              {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
              {media}
            </Slide>
          );
        })}
      </SwipeSlider>
    )
  );
};

SwipeCarousel.propTypes = {
  variantSlides: array,
};
SwipeCarousel.defaultProps = {
  variantSlides: [],
};

export default React.memo(SwipeCarousel);
