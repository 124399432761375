import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bool, func, object } from 'prop-types';
import styled from 'styled-components';
import {
  bodySRegularBrownStyles,
  bodyXsBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsBoldBrownStyles,
  bodyXxsRegularBrownStyles,
  bodyXxxsBoldBrownStyles,
} from '../../../styles/typography.js';
import { bpWidth, colors } from '../../../styles/variables.js';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const BannerWrap = styled.div`
  position: relative;
  z-index: 4;
`;

const BannerRow = styled(Row)`
  background: ${colors.orange400};
  padding: 10px 0;
  width: 100%;

  @media (${bpWidth.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const BannerBodyCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  text-align: center;

  a {
    text-decoration: none;
  }

  p {
    ${bodySRegularBrownStyles};
    color: ${colors.white};

    @media (${bpWidth.desktopSm}) {
      ${bodyXsRegularBrownStyles};
      color: ${colors.white};
    }

    @media (${bpWidth.mobile}) {
      ${bodyXxsRegularBrownStyles};
      color: ${colors.white};
      padding-bottom: 2px;
    }
  }
`;

const BannerCtaCol = styled(Col)`
  display: flex;
  justify-content: end;

  div {
    ${bodyXsBoldBrownStyles};
    color: ${colors.white};
    cursor: pointer;
    flex: 1 0 auto;
    text-align: right;

    @media (${bpWidth.desktopSm}) {
      ${bodyXxsBoldBrownStyles};
      color: ${colors.white};
    }

    @media (${bpWidth.mobile}) {
      ${bodyXxxsBoldBrownStyles};
      color: ${colors.white};
    }
  }
`;

function Banner(props) {
  const { data, onBannerClick, bannerClicked } = props;

  // Banner CTA clicked
  const onClickBanner = () => {
    if (!bannerClicked) {
      return onBannerClick(true);
    }
    return onBannerClick(false);
  };

  return (
    <BannerWrap>
      <BannerRow id="activePromoBanner">
        <BannerBodyCol
          smOffset={1}
          mdOffset={2}
          lgOffset={2}
          xs={8}
          sm={data.content.includeCtaButton ? 4 : 6}
          md={6}
          lg={8}
        >
          {data.content.link ? (
            <a
              href={data.content.link?.cached_url || data.content.link?.url}
              target={data.content.link.target}
              rel={data.content.link.target === '_blank' ? 'noreferrer' : undefined}
            >
              {renderRichTextReact(data.content.body)}
            </a>
          ) : (
            <span>{renderRichTextReact(data.content.body)}</span>
          )}
        </BannerBodyCol>

        {data.content.includeCtaButton && (
          <BannerCtaCol xs={8} sm={2} md={1} lg={1}>
            <div role="presentation" onClick={onClickBanner}>
              {data.content.ctaText}
            </div>
          </BannerCtaCol>
        )}
      </BannerRow>
    </BannerWrap>
  );
}

Banner.propTypes = {
  onBannerClick: func,
  bannerClicked: bool,
  data: object,
};
Banner.defaultProps = {
  bannerClicked: false,
};

export default Banner;
