import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EXTERNAL_URL } from '../../../constants/URL';
import CtaArrow from '../../../img/CTA-arrow.svg';
import { btnLgTertiaryBlueStyles, btnSmTertiaryBlueStyles } from '../../../styles/buttons';
import {
  bodyLItalicUtopiaStyles,
  bodyMBoldBrownStyles,
  bodySItalicUtopiaStyles,
  bodyXlBoldBrownStyles,
  bodyXlItalicUtopiaStyles,
  bodyXxlBoldBrownStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';

const TestimonialItemContainerRow = styled(Row)`
  margin-top: ${(props) => (props.index === 0 ? '70px' : '60px')};

  @media (${bpWidth.desktopSm}) {
    margin-top: ${(props) => (props.index === 0 ? '50px' : '60px')};
  }

  @media (${bpWidth.tablet}) {
    display: flex;
    flex-direction: column;
    margin-top: ${(props) => (props.index === 0 ? '70px' : '60px')};
    text-align: center;
  }

  @media (${bpWidth.mobile}) {
    margin-top: ${(props) => (props.index === 0 ? '50px' : '60px')};
  }

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const ImageCol = styled(Col)`
  order: ${({ index }) => (index % 2 === 0 ? '1' : '2')};

  @media (${bpWidth.tablet}) {
    order: unset;
    padding-bottom: 20px;
  }
`;

const TextCol = styled(Col)`
  order: ${({ index }) => (index % 2 === 0 ? '2' : '1')};

  @media (${bpWidth.tablet}) {
    order: unset;
  }
`;

const TestimonialItemTextContainer = styled.div`
  @media (${bpWidth.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TestimonialAuthorName = styled.h3`
  ${bodyXxlBoldBrownStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodyXlBoldBrownStyles};
  }

  @media (${bpWidth.mobile}) {
    ${bodyMBoldBrownStyles};
    letter-spacing: -0.16px;
  }
`;

const TestimonialText = styled.p`
  margin-top: 10px;
  ${bodyXlItalicUtopiaStyles};
  text-indent: ${(props) => (props.width > 0 ? `-${props.width}px` : 0)};

  @media (${bpWidth.desktopSm}) {
    ${bodyLItalicUtopiaStyles};
  }

  @media (${bpWidth.tablet}) {
    text-indent: unset;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 5px;
    ${bodySItalicUtopiaStyles};
    letter-spacing: -0.14px;
  }
`;

const FirstLetter = styled.span``;

const TestimonialCTA = styled.a`
  ${btnLgTertiaryBlueStyles};
  margin-top: 35px;
  display: block;
  text-decoration: none;
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${btnSmTertiaryBlueStyles};
    margin-top: 20px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 15px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 10px;
  }

  svg {
    width: 4px;
    margin-bottom: 2px;
    margin-left: 8px;

    @media (${bpWidth.desktopSm}) {
      margin-left: 6px;
      margin-bottom: 1px;
    }
  }
  svg,
  svg * {
    color: ${colors.cerulean400};
    fill: ${colors.cerulean400};
  }
  :hover svg,
  :hover svg * {
    color: ${colors.cerulean700};
    fill: ${colors.cerulean700};
  }
`;

const TestimonialItemText = (props) => {
  const { authorName, content, link, linkText, linkUrl } = props;

  const firstLetterEl = useRef(null);
  const [firstLetterWidth, setFirstLetterWidth] = useState(0);

  const testimonialTextFirstLetter = content.slice(0, 1);
  const testimonialTextTheRestOf = content.slice(1);

  useEffect(() => {
    if (firstLetterEl.current) {
      setFirstLetterWidth(firstLetterEl.current.offsetWidth);
    }
  }, []);

  return (
    <TestimonialItemTextContainer>
      <TestimonialAuthorName>{authorName}</TestimonialAuthorName>
      <TestimonialText width={firstLetterWidth}>
        <FirstLetter ref={firstLetterEl}>{testimonialTextFirstLetter}</FirstLetter>
        {testimonialTextTheRestOf}
      </TestimonialText>
      <TestimonialCTA href={linkUrl} target={link?.target} rel={link?.target ? 'noreferrer' : undefined}>
        {linkText} <CtaArrow />
      </TestimonialCTA>
    </TestimonialItemTextContainer>
  );
};

TestimonialItemText.propTypes = {
  authorName: PropTypes.string,
  content: PropTypes.string,
  link: PropTypes.any,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
};

TestimonialItemText.defaultProps = {
  authorName: '',
  content: '',
  linkText: 'Read more',
  linkUrl: EXTERNAL_URL.MY_GITA_BLOG_URL,
};

const Testimonial = ({ blok, index }) => {
  const { image, authorName, content, link } = blok;
  const linkUrl = link?.story?.url || link?.cached_url || link?.url;
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (image?.filename) {
      const options = { layout: 'constrained' };
      setImageData(getGatsbyImage(image.filename, options));
    }
  }, []);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <TestimonialItemContainerRow middle="xs" index={index}>
        <ImageCol xs={3} sm={3} md={4} lg={5} index={index}>
          {imageData && <GatsbyImage image={imageData} alt={image.alt || ''} loading="lazy" />}
        </ImageCol>

        <TextCol xs={5} sm={5} md={6} lg={7} index={index}>
          <Row>
            {index % 2 === 0 ? (
              <Col xsOffset={0} mdOffset={2} lgOffset={2} xs={8} md={8} lg={10}>
                <TestimonialItemText authorName={authorName} content={content} link={link} linkUrl={linkUrl} />
              </Col>
            ) : (
              <Col xs={8} md={8} lg={10}>
                <TestimonialItemText authorName={authorName} content={content} link={link} linkUrl={linkUrl} />
              </Col>
            )}
          </Row>
        </TextCol>
      </TestimonialItemContainerRow>
    </Col>
  );
};

Testimonial.propTypes = {
  blok: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default Testimonial;
