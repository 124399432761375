import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { format } from 'date-fns';
import { object, string } from 'prop-types';
import styled from 'styled-components';
import {
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodyXxsBoldBrownStyles,
  headerLFillStyles,
  headerLOutlineStyles,
  headerMStyles,
} from '../../../styles/typography.js';
import { bpWidth, colors } from '../../../styles/variables.js';
import { calculateReadTime } from '../../../utils/calculateReadTime';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';

const PostHeaderRow = styled(Row)`
  margin-top: 73px;

  @media (${bpWidth.mobile}) {
    margin-top: 55px;
  }
`;

const PostHeaderCol = styled(Col)`
  border-bottom: 0.75px solid ${colors.gray300};

  span {
    ${bodyXxsBoldBrownStyles};
    color: ${colors.gray300};
    line-height: 1;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    div:first-child {
      border-right: 0.75px solid ${colors.gray300};
      padding-right: 10px;
      margin-right: 10px;
    }
  }
  h3,
  h3 p {
    ${bodyLRegularUtopiaStyles};
    color: ${colors.navy600};
    margin-bottom: 20px;

    a {
      color: ${colors.cerulean400};
      text-decoration: none;

      &:hover {
        font-style: italic;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 64px;

    h3,
    h3 p {
      ${bodyMRegularUtopiaStyles};
      color: ${colors.navy600};
      margin-bottom: 20px;
    }
  }
`;

const Heading = styled.div`
  margin-bottom: 12px;

  p {
    ${headerLFillStyles};
    color: ${colors.navy600};

    code {
      ${headerLOutlineStyles};
      color: ${colors.navy600};
    }
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 10px;

    p {
      ${headerMStyles};
      color: ${colors.navy600};

      code {
        ${headerMStyles};
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px ${colors.navy600};
      }
    }
  }
`;

// Reformat and style post's publish date
const formatPublishDate = (date) => {
  const newDate = new Date(date);
  return format(newDate, 'MM. dd. yyyy');
};

function PostHeader(props) {
  // Data
  const { publishDate, heading, subHeading, article } = props;

  return (
    <PostHeaderRow>
      <PostHeaderCol xsOffset={1} mdOffset={3} lgOffset={4} xs={6} md={4} lg={4}>
        <span>
          {publishDate && <div>{formatPublishDate(publishDate)}</div>}
          <div>{calculateReadTime({ raw: renderPlainText(article) })}</div>
        </span>
        <Heading>{renderRichTextReact(heading)}</Heading>
        {subHeading && <h3>{renderRichTextReact(subHeading)}</h3>}
      </PostHeaderCol>
    </PostHeaderRow>
  );
}

PostHeader.propTypes = {
  publishDate: string.isRequired,
  heading: object.isRequired,
  subHeading: object,
  article: object.isRequired,
};

export default PostHeader;
