import React, { useEffect, useMemo, useState } from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes, { bool } from 'prop-types';
import styled from 'styled-components';
import { buttonLStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { updateStoryblokMediaUrl } from '../../../utils/helper';

const Anchor = styled.a`
  ${buttonLStyles};
  color: ${colors.cerulean400};
  text-transform: unset;

  &:hover {
    color: ${colors.cerulean700};
  }

  &.navFontColorLight {
    color: ${colors.beige100};

    &:hover {
      color: ${colors.beige100};
    }
  }

  &:disabled,
  &.disabled {
    color: ${colors.cerulean400};
    opacity: 0.35;
  }
`;

const ImgSvg = styled.span`
  width: 25px;
  height: 25px;
  margin-left: ${({ hasText }) => (hasText ? '15px' : 0)};
  display: inline-block;
  background: ${colors.gray300};
  mask: ${({ src }) => `url(${src})`};

  &:hover {
    background: ${colors.gray700};
  }

  @media (${bpWidth.tablet}) {
    width: 29px;
    height: 29px;
    margin-left: ${({ hasText }) => (hasText ? '10px' : 0)};
  }
`;

const Img = styled.img`
  width: 25px;
  height: 25px;
  margin-left: ${({ hasText }) => (hasText ? '15px' : 0)};
  object-fit: contain;

  @media (${bpWidth.tablet}) {
    width: 29px;
    height: 29px;
    margin-left: ${({ hasText }) => (hasText ? '10px' : 0)};
  }
`;

const Link = ({ blok, navFontColorLight = false }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const cssClass = useMemo(
    () => ['link-component', navFontColorLight ? 'navFontColorLight' : undefined].filter(Boolean).join(' '),
    [navFontColorLight],
  );

  useEffect(() => {
    if (blok.image?.filename?.endsWith('.svg')) {
      setImageUrl(updateStoryblokMediaUrl(blok.image.filename));
    } else {
      setImageUrl(blok.image?.filename);
    }
  }, [blok.image?.filename]);

  let href = blok.url?.cached_url || blok.url?.url || '';
  if (blok.url?.anchor) {
    href += `#${blok.url.anchor}`;
  }

  return (
    <Anchor
      {...storyblokEditable(blok)}
      href={href}
      target={blok.url?.target}
      rel={blok.url?.target ? 'noreferrer' : undefined}
      className={cssClass}
    >
      <span className="link-component--text">{blok.text}</span>
      {imageUrl &&
        (imageUrl.endsWith('.svg') ? (
          <ImgSvg src={imageUrl} alt="image-link" hasText={!!blok.text} className="link-component--icon" />
        ) : (
          <Img src={imageUrl} alt="image-link" hasText={!!blok.text} className="link-component--icon" />
        ))}
    </Anchor>
  );
};

Link.propTypes = {
  blok: PropTypes.object.isRequired,
  navFontColorLight: bool,
};

export default Link;
