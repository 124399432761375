import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { func, number, string } from 'prop-types';
import styled from 'styled-components';
import Chevron from '../../icons/arrow-LCerulean.svg';
import { bodyMBoldBrownStyles, bodySBoldBrownStyles } from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';

const PaginationCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  height: fit-content;

  &.nextCtaOnly {
    justify-content: flex-end;
  }
  &.prevCtaOnly {
    justify-content: flex-start;
  }
  &.bothCtas {
    justify-content: space-between;
  }

  svg {
    margin-left: 10px;
    width: 5px;
    height: 10px;
    path#Side {
      stroke: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 3px;
      height: 9px;
    }
  }
`;

const NextButton = styled.div`
  ${bodyMBoldBrownStyles};
  line-height: 1;
  color: ${colors.navy600};
  background: none;
  border: none;
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${bodySBoldBrownStyles};
    line-height: 1;
    color: ${colors.navy600};
  }
`;

const PreviousButton = styled(NextButton)`
  svg {
    margin-left: 0;
    margin-right: 10px;
    transform: rotate(180deg);
  }
`;

function Pagination(props) {
  // Effects
  const { paginationClass, postsShown, postsPerPage, totalPosts, previousPosts, nextPosts } = props;

  // Data

  return (
    <PaginationCol xsOffset={0} xs={8} md={10} lg={12} className={paginationClass}>
      {postsShown > postsPerPage && (
        <PreviousButton onClick={() => previousPosts()}>
          <Chevron /> Previous
        </PreviousButton>
      )}
      {postsShown < totalPosts && (
        <NextButton onClick={() => nextPosts()}>
          Next <Chevron />
        </NextButton>
      )}
    </PaginationCol>
  );
}

Pagination.propTypes = {
  paginationClass: string,
  postsShown: number,
  postsPerPage: number,
  totalPosts: number,
  previousPosts: func,
  nextPosts: func,
};
Pagination.defaultProps = {
  paginationClass: '',
  postsShown: 0,
  postsPerPage: 0,
  totalPosts: 0,
  previousPosts: () => {},
  nextPosts: () => {},
};

export default Pagination;
