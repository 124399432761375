import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

const PressPageStateContext = React.createContext({});

export const usePressPageState = () => React.useContext(PressPageStateContext);

const PressPageStateProvider = ({ children }) => {
  const tabHeaderRowRef = useRef(null);
  const filterRowRef = useRef(null);

  const contextValue = useMemo(
    () => ({
      tabHeaderRowRef,
      filterRowRef,
    }),
    [tabHeaderRowRef, filterRowRef],
  );

  return <PressPageStateContext.Provider value={contextValue}>{children}</PressPageStateContext.Provider>;
};

PressPageStateProvider.propTypes = {
  children: PropTypes.any,
};

export default PressPageStateProvider;
