/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable object-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';

const TabContentRow = ({ activeTab, uid, className, children }) =>
  activeTab === uid ? <div className={className}>{children}</div> : undefined;
TabContentRow.propTypes = {
  uid: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default TabContentRow;
