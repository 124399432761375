import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { any, array, func, number } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import Chevron from '../../../icons/arrow-LCerulean.svg';
import CloseIcon from '../../../icons/close-icon.svg';
import {
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  bodySRegularUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { fadeTextStyle, onScrollFadeClassChange, toggleFadeClass } from '../../../utils/textTransform';

const SectionRow = styled(Row)`
  flex-grow: 1;
  max-height: 100%;
`;

const SectionsCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 164px;
  max-height: 100%;

  @media (${bpWidth.desktopSm}) {
    padding-top: 130px;
    padding-bottom: 70px;
  }
  @media (${bpWidth.tablet}) {
    padding-top: 70px;
  }
  @media (${bpWidth.mobile}) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
`;

const BioSlider = styled(Slider)`
  width: 91.67%;

  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-track {
    display: flex;

    .slick-slide {
      display: flex;
      height: auto;

      > div {
        width: 100%;
      }
    }
  }

  @media (${bpWidth.tablet}) {
    width: 100%;
  }
`;

const Slide = styled.div`
  height: 100%;
`;

const SlideRow = styled(Row)`
  height: 100%;

  @media (${bpWidth.tablet}) {
    display: flex;
    flex-flow: column;
  }
`;

const HeadshotCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-right: 12px;

  .media-col {
    flex-basis: auto;
  }

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    margin-bottom: 15px;
    max-width: 202px;
  }
  h2 {
    ${eyebrowMStyles};
    margin-bottom: 5px;
  }
  h3 {
    ${bodyMRegularUtopiaStyles};
  }
  h2,
  h3 {
    color: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    padding-right: 10px;

    h2 {
      ${eyebrowSStyles};
    }
    h3,
    span {
      ${bodySRegularUtopiaStyles};
    }
    h2,
    h3,
    span {
      color: ${colors.navy600};
    }
    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      max-width: 160px;
    }
  }
  @media (${bpWidth.tablet}) {
    height: fit-content;
    padding-right: 0;
    margin-bottom: 35px;
    flex: 0 1 auto;

    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      max-width: 202px;
    }
  }
  @media (${bpWidth.mobile}) {
    margin-bottom: 25px;
  }
`;

const BioCol = styled(Col)`
  display: flex;
  flex-direction: column;
  padding-left: 12px;

  max-height: 100%;
  overflow: hidden;

  div.copyContainer {
    flex-grow: 1;
    overflow-y: auto;

    &.fadedText {
      ${fadeTextStyle};
    }
    .copyInner {
      margin-right: 8.35%;

      p {
        ${bodyMRegularUtopiaStyles};
        color: ${colors.navy600};
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    padding-left: 10px;

    div.copyContainer .copyInner {
      margin-right: 5%;

      p {
        ${bodySRegularUtopiaStyles};
        color: ${colors.navy600};
      }
    }
  }
  @media (${bpWidth.tablet}) {
    flex: 1 1 auto;
    padding-left: 0;

    div.copyContainer .copyInner {
      margin-right: 0;
    }
  }
`;

const PaginationCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-shrink: initial;
  max-height: 20px;
  margin-top: 51px;
  padding-right: 8.35%;

  @media (${bpWidth.desktopSm}) {
    margin-top: 50px;
    padding-right: 5%;
  }
  @media (${bpWidth.tablet}) {
    margin-top: 70px;
    padding-right: 0;
  }
  @media (${bpWidth.mobile}) {
    margin-top: 31px;
  }
`;

const NextButton = styled.div`
  ${bodyMBoldBrownStyles};
  color: ${colors.cerulean400};
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  flex-grow: unset !important;

  svg {
    margin-left: 10px;
    width: 5px;
    height: 10px;
    path#Side {
      stroke: ${colors.cerulean400};
    }
  }
  :hover {
    color: ${colors.cerulean700};

    svg path#Side {
      stroke: ${colors.cerulean700};
    }
  }

  &.fade {
    color: ${colors.cerulean100};
    cursor: unset;

    svg path#Side {
      stroke: ${colors.cerulean200};
    }
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySBoldBrownStyles};
    color: ${colors.cerulean400};
    line-height: 1;

    svg {
      width: 3px;
      height: 9px;
    }
  }
`;

const PreviousButton = styled(NextButton)`
  svg {
    margin-left: 0;
    margin-right: 10px;
    transform: rotate(180deg);
  }
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  height: fit-content;
  padding-top: 5px;

  svg {
    background: transparent;
    width: 16px;
    z-index: 1;

    polygon.st0 {
      fill: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 12px;
    }
  }
  @media (${bpWidth.tablet}) {
    margin-left: -5%;
    svg {
      width: 15px;
    }
  }
  @media (${bpWidth.tablet}) {
    margin-left: -6%;
  }
`;

function BioPopUp({ bioIndexClicked, biosOpen, members, handleBioClose }) {
  // State

  const [updateCount, setUpdateCount] = useState(bioIndexClicked);
  const [hidePrev, setHidePrev] = useState('');
  const [hideNext, setHideNext] = useState('');

  const sliderRef = useRef();

  const { viewWidth } = useViewport();

  const settings = {
    dots: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
    infinite: true,
    initialSlide: bioIndexClicked,
    afterChange: (current) => setUpdateCount(current),
  };

  // Trigger slide change
  const changeSlide = (btnClicked) => {
    if (btnClicked === 'prev' && updateCount > 0) {
      sliderRef.current.slickPrev();
    } else if (btnClicked === 'next' && updateCount !== members.length - 1) {
      sliderRef.current.slickNext();
    }
  };

  useEffect(() => {
    setHidePrev('');
    setHideNext('');

    if (updateCount === 0) {
      setHidePrev('fade');
    }

    if (updateCount === members.length - 1) {
      setHideNext('fade');
    }
  }, [updateCount]);

  // Fade bio copy if there's an overflow
  let currentSlide = {};
  let copyContainer = {};
  let copyInner = {};

  let copyContainerHeight = 0;
  let copyInnerHeight = 0;

  // Compare & trigger functions based off container length vs inner copy length
  const compareCopyHeights = () => {
    [currentSlide] = document.getElementsByClassName('slick-active');
    [copyContainer] = currentSlide.getElementsByClassName('copyContainer');
    [copyInner] = currentSlide.getElementsByClassName('copyInner');
    if (currentSlide && copyContainer && copyInner) {
      copyContainerHeight = copyContainer.offsetHeight;
      copyInnerHeight = copyInner.offsetHeight;

      if (copyContainerHeight < copyInnerHeight) {
        toggleFadeClass('add', copyContainer);

        copyContainer.addEventListener('scroll', () => onScrollFadeClassChange(copyContainer));
      } else {
        toggleFadeClass('remove', copyContainer);
      }
    }

    return () => {
      if (copyContainer) {
        copyContainer.removeEventListener('scroll', () => onScrollFadeClassChange(copyContainer));
      }
    };
  };

  useEffect(() => {
    if (biosOpen) {
      compareCopyHeights();
    }
  }, [biosOpen, updateCount]);

  useEffect(() => {
    compareCopyHeights();
  }, [viewWidth]);

  return (
    <SectionRow>
      <SectionsCol xsOffset={1} mdOffset={2} xs={6} lgOffset={3}>
        {/* Carousel */}
        <BioSlider {...settings} ref={sliderRef}>
          {members.map((member) => (
            <Slide key={member._uid}>
              <SlideRow>
                <HeadshotCol xs={8} md={4} lg={5}>
                  {/* Headshot */}
                  {member.image?.map((nestedBlok) => (
                    <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                  ))}

                  {/* Name */}
                  <h2>{renderRichTextReact(member.name)}</h2>

                  {/* Title */}
                  <h3>{renderRichTextReact(member.title)}</h3>
                </HeadshotCol>

                <BioCol xs={8} md={6} lg={7}>
                  {/* Bio */}
                  <div className="copyContainer">
                    <div className="copyInner">{renderRichTextReact(member.bio)}</div>
                  </div>

                  {/* Pagination */}
                  <PaginationCol xsOffset={0} xs={8} md={10} lg={12}>
                    <PreviousButton className={hidePrev} onClick={() => changeSlide('prev')}>
                      <Chevron /> Previous
                    </PreviousButton>
                    <NextButton className={hideNext} onClick={() => changeSlide('next')}>
                      Next <Chevron />
                    </NextButton>
                  </PaginationCol>
                </BioCol>
              </SlideRow>
            </Slide>
          ))}
        </BioSlider>

        {/* Close bio popup */}
        <CloseButton onClick={() => handleBioClose(true)}>
          <CloseIcon />
        </CloseButton>
      </SectionsCol>
    </SectionRow>
  );
}

BioPopUp.propTypes = {
  bioIndexClicked: number,
  biosOpen: any,
  members: array,
  handleBioClose: func,
};

export default BioPopUp;
