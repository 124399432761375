import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  bodyLBoldBrownStyles,
  bodyLRegularUtopiaStyles,
  bodyMBoldBrownStyles,
  bodySRegularUtopiaStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { CarouselTabbedCTACol, CarouselTabbedTopRow, TabbedSlickSlider } from '../../Carousels/CarouselStyles';

const TopRow = styled(CarouselTabbedTopRow)`
  @media (${bpWidth.mobile}) {
    .two-columns-title-text-component {
      &--title,
      &--text {
        br {
          content: '';

          &:after {
            content: ' ';
          }
        }
      }
    }
  }
`;

const CopyRow = styled(Row)`
  margin-top: 30px;

  h3.caption-heading {
    ${bodyLBoldBrownStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    margin-bottom: 10px;
  }

  div.caption {
    ${bodyLRegularUtopiaStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidth.desktopSm}) {
    margin-top: 20px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 10px;

    div.caption {
      ${bodySRegularUtopiaStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    }
  }
  @media (${bpWidth.mobile}) {
    margin-top: 8px;

    h3.caption-heading {
      ${bodyMBoldBrownStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    }
  }
`;

const Slider1 = ({ blok }) => {
  const { link, assets: slideList = [], darkMode = false } = blok;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [currentContent, setCurrentContent] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    swipe: true,
    touchMove: true,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlideIndex(newIndex);
    },
    onInit: () => {
      setCurrentSlideIndex(0);
    },
  };

  useEffect(() => {
    const content = slideList[currentSlideIndex]?.content;
    setCurrentContent(content);
  }, [slideList, currentSlideIndex]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {currentContent?.length > 0 && (
        <TopRow>
          <Row>
            <Col xsOffset={1} xs={6} mdOffset={0} md={10} lg={12}>
              <Row>
                {currentContent.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </Row>
            </Col>
          </Row>
        </TopRow>
      )}
      <Row>
        <Col xs={8} md={10} lg={12}>
          <TabbedSlickSlider {...settings} className="healthier-you">
            {slideList?.map((nestedBlok) => {
              const caption = renderRichTextReact(nestedBlok.caption);

              return (
                <div key={nestedBlok._uid}>
                  <Row>
                    <StoryblokComponent blok={nestedBlok} />
                  </Row>
                  {(nestedBlok.captionHeading || caption) && (
                    <CopyRow darkMode={darkMode}>
                      <Col xs={8} sm={8} md={8} lg={10}>
                        <h3 className="caption-heading">{nestedBlok.captionHeading}</h3>
                        <div className="caption">{caption}</div>
                      </Col>
                    </CopyRow>
                  )}
                </div>
              );
            })}
          </TabbedSlickSlider>
        </Col>
      </Row>
      {link?.length > 0 && (
        <Row>
          <CarouselTabbedCTACol xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={4} md={2} lgOffset={9} lg={3}>
            {link?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </CarouselTabbedCTACol>
        </Row>
      )}
    </Col>
  );
};

Slider1.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider1;
