import React, { useEffect, useMemo, useState } from 'react';
import { EmailShareButton, TwitterShareButton } from 'react-share';
import { Col, Row } from 'react-styled-flexboxgrid';
import { string } from 'prop-types';
import styled from 'styled-components';
import Email from '../../../icons/PFF-brand-icons-social-sharing-email.svg';
import Facebook from '../../../icons/PFF-brand-icons-social-sharing-facebook.svg';
import ShareLink from '../../../icons/PFF-brand-icons-social-sharing-link.svg';
import LinkedIn from '../../../icons/PFF-brand-icons-social-sharing-linkedin.svg';
import Twitter from '../../../icons/PFF-brand-icons-social-sharing-twitter.svg';
import { bodyXxsBoldBrownStyles } from '../../../styles/typography.js';
import { colors } from '../../../styles/variables.js';

const SharingLinksCol = styled(Col)`
  border-top: 0.75px solid ${colors.gray300};
  padding-top: 40px;
  margin-bottom: 80px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .copyText {
    display: none;
    ${bodyXxsBoldBrownStyles};
    color: ${colors.gray700};
  }
  .copyText.visibleCopyText {
    display: flex;
  }
`;

const Icon = styled.a.attrs({
  target: '_blank',
})`
  svg {
    cursor: pointer;
    width: 30px;
    path.st0,
    polygon.st0 {
      fill: ${colors.gray300};
    }
    path#linkedin {
      fill: ${colors.black};
    }
  }

  &:hover {
    svg path.st0,
    polygon.st0,
    path#linkedin {
      fill: ${colors.gray700};
    }
  }
`;

function SharingLinks(props) {
  // Data
  const { postHeading } = props;

  const [urlCopiedClass, setUrlCopiedClass] = useState('copyText');
  const [blogUrl, setBlogUrl] = useState('');

  useEffect(() => {
    setBlogUrl(window.location.href);
  }, []);

  // Copy page url on ShareLink click
  const copyUrl = async () => {
    await navigator.clipboard.writeText(blogUrl);
    setUrlCopiedClass('copyText visibleCopyText');
  };

  // Social links
  const shareLinks = useMemo(() => {
    if (!blogUrl) {
      return undefined;
    }

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${blogUrl}`;
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${blogUrl}`;

    return (
      <IconContainer>
        {/* Facebook */}
        <Icon href={facebookUrl} target="_blank">
          <Facebook aria-label="Facebook" />
        </Icon>

        {/* Twitter */}
        <TwitterShareButton title={postHeading} url={blogUrl}>
          <Icon>
            <Twitter aria-label="Twitter" />
          </Icon>
        </TwitterShareButton>

        {/* LinkedIn */}
        <Icon href={linkedInUrl} target="_blank">
          <LinkedIn aria-label="LinkedIn" />
        </Icon>

        {/* Email */}
        <EmailShareButton url={blogUrl} subject={postHeading}>
          <Icon>
            <Email aria-label="Email" />
          </Icon>
        </EmailShareButton>

        {/* Page Link */}
        <div className="copyIcon">
          <Icon onClick={copyUrl}>
            <ShareLink aria-label="Share Link" />
          </Icon>
          <div className={urlCopiedClass}>Copied!</div>
        </div>
      </IconContainer>
    );
  }, [blogUrl, postHeading, urlCopiedClass]);

  useEffect(() => {
    if (urlCopiedClass.includes('visibleCopyText')) {
      setTimeout(() => {
        setUrlCopiedClass('copyText');
      }, 3000);
    }
  });

  return (
    <Row>
      <SharingLinksCol xsOffset={1} mdOffset={3} lgOffset={4} xs={6} md={4} lg={4}>
        {shareLinks}
      </SharingLinksCol>
    </Row>
  );
}

SharingLinks.propTypes = {
  postHeading: string,
};
SharingLinks.defaultProps = {
  postHeading: '',
};

export default SharingLinks;
