import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { graphql, Link, useStaticQuery, withPrefix } from 'gatsby';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import {
  bodyLBoldBrownStyles,
  bodyLRegularUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodyXsBoldBrownStyles,
  bodyXxsBoldBrownStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { calculateReadTime } from '../../../utils/calculateReadTime';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';
import Pagination from '../../Pagination/Pagination';
import SearchBar from './SearchBar';
import SearchHeader from './SearchHeader';
import SearchStatus from './SearchStatus';
import SearchSuggestions from './SearchSuggestions';

const SearchRowOuter = styled(Row)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100vw;
  height: 100vh;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0s linear;
  transition-delay: 250ms;

  &.show {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }
`;

const SearchColOuter = styled(Col)`
  background: none;
  height: 100vh;
  transition: background-color 100ms ease;

  &.show {
    background-color: rgba(243, 241, 237, 0.7);
  }
`;

const SearchRowInner = styled(Row)`
  height: 100%;
`;

const SearchColInner = styled(Col)`
  display: flex;
  flex-flow: column;
  height: 100%;

  background-color: ${colors.beige100};
  opacity: 1;
  width: 28%;
  max-width: 28%;
  flex-basis: 28%;
  padding-bottom: 118px;
  transform: translateX(100%);
  transition: transform 250ms ease;

  &.show {
    transform: translateX(0%);
  }

  @media (${bpWidth.desktopSm}) {
    flex-basis: 35%;
    max-width: 35%;
    width: 35%;
  }

  @media (${bpWidth.tablet}) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`;

const Post = styled.div`
  padding-bottom: 40px;

  a {
    text-decoration: none;

    &:hover {
      h3,
      div {
        font-style: italic;
      }
    }

    h3,
    div p a {
      color: ${colors.gray500};
    }
  }

  span {
    ${bodyXsBoldBrownStyles};
    line-height: 1;
    color: ${colors.gray300};
    margin-bottom: 15px;
  }

  h3 {
    ${bodyLBoldBrownStyles};
    color: ${colors.gray500};
    margin-bottom: 3px;
  }

  div {
    ${bodyLRegularUtopiaStyles};
    color: ${colors.gray500};
  }

  @media (${bpWidth.desktopSm}) {
    span {
      ${bodyXxsBoldBrownStyles};
      line-height: 1;
      color: ${colors.gray300};
    }

    h3 {
      ${bodyMBoldBrownStyles};
      color: ${colors.gray500};
    }

    div {
      ${bodyMRegularUtopiaStyles};
      color: ${colors.gray500};
    }
  }
`;
const ResultsRow = styled(Row)`
  flex: 1 1 auto;
  overflow: scroll;
  display: flex;
  align-content: space-between;
`;

function Search({ searchIsOpen, handleSearchToggle }) {
  // Data
  const allPostsQuery = useStaticQuery(graphql`
    {
      allStoryblokEntry(filter: { full_slug: { regex: "/^blog//" }, is_startpage: { eq: false } }) {
        nodes {
          id
          name
          created_at
          published_at
          uuid
          slug
          full_slug
          content
          is_startpage
          parent_id
          group_id
          internalId
        }
      }
    }
  `);
  const stories = (allPostsQuery.allStoryblokEntry.nodes || []).map((story) => ({
    ...story,
    content: JSON.parse(story.content),
  }));
  const postsPerPage = React.useMemo(() => 10, []);

  const [searchedPosts, setSearchedPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [paginationClass, setPaginationClass] = useState('');
  const [postsShown, setPostsShown] = useState(postsPerPage);
  const [postsPaginated, setPostsPaginated] = useState(searchedPosts.slice(0, postsPerPage));
  const [totalPosts, setTotalPosts] = useState(searchedPosts.length);

  // Clear searchQuery
  const clearSearch = () => {
    setSearchQuery('');
    setSearchedPosts([]);
    setPostsPaginated([]);
  };

  // Search through posts
  const handleInputChange = (event) => {
    const searchTerm = event;

    // Search through a posts heading, subHeading, summary, and body
    if (searchTerm.length > 0) {
      const searchedPostsFiltered = stories.filter((story) => {
        const post = story.content?.body?.find((b) => b.component === 'post');
        if (post) {
          const { heading, subHeading, summary, body } = post;
          return (
            renderPlainText(heading).toLowerCase().includes(event.toLowerCase()) ||
            renderPlainText(subHeading).toLowerCase().includes(event.toLowerCase()) ||
            renderPlainText(summary).toLowerCase().includes(event.toLowerCase()) ||
            renderPlainText(body).toLowerCase().includes(event.toLowerCase())
          );
        }

        return false;
      });
      setSearchedPosts(searchedPostsFiltered);
      setSearchQuery(searchTerm);
    } else if (searchTerm.length === 0) {
      clearSearch();
    }
  };

  const searchResultsReturned = searchedPosts.length > 0 && searchQuery.length > 0;

  // View change when search tab is open
  useEffect(() => {
    if (!searchIsOpen) {
      // Clear search results if search bar is closed
      clearSearch();
    }
  }, [searchIsOpen]);

  // Pagination
  const previousPosts = () => {
    if (postsShown > postsPerPage) {
      if (postsShown - postsPerPage > 0 && totalPosts !== postsShown) {
        setPostsPaginated(searchedPosts.slice(postsShown - 2 * postsPerPage, postsShown - postsPerPage));
        setPostsShown(postsShown - postsPerPage);
      } else if (totalPosts === postsShown) {
        if (totalPosts % postsPerPage !== 0) {
          const remainingPosts = totalPosts % postsPerPage;
          setPostsPaginated(
            searchedPosts.slice(postsShown - postsPerPage - remainingPosts, postsShown - remainingPosts),
          );
          setPostsShown(postsShown - remainingPosts);
        } else {
          setPostsPaginated(searchedPosts.slice(postsShown - 2 * postsPerPage, postsShown - postsPerPage));
          setPostsShown(postsShown - postsPerPage);
        }
      }
    }
  };

  const nextPosts = () => {
    const remainder = totalPosts - postsShown;
    if (postsShown < totalPosts) {
      if (remainder > postsPerPage) {
        setPostsPaginated(searchedPosts.slice(postsShown, postsShown + postsPerPage));
        setPostsShown(postsShown + postsPerPage);
      } else {
        const remainingPosts = postsShown + (totalPosts - postsShown);
        setPostsPaginated(searchedPosts.slice(postsShown, remainingPosts));
        setPostsShown(remainingPosts);
      }
    }
  };

  useEffect(() => {
    if (postsShown === 0) {
      setPostsShown(postsPerPage);
    }

    setPostsPaginated(searchedPosts.slice(0, postsPerPage));
    setTotalPosts(searchedPosts.length);
  }, [searchedPosts]);

  useEffect(() => {
    if (postsShown > postsPerPage && postsShown < totalPosts) {
      setPaginationClass('bothCtas');
    } else if (postsShown > postsPerPage && postsShown >= totalPosts) {
      setPaginationClass('prevCtaOnly');
    } else if (postsShown <= postsPerPage && postsShown < totalPosts) {
      setPaginationClass('nextCtaOnly');
    }
  }, [postsShown]);

  return (
    <SearchRowOuter id="searchRowOuter" className={searchIsOpen ? 'show' : undefined}>
      <SearchColOuter xs={8} sm={8} md={10} lg={12} id="searchColOuter" className={searchIsOpen ? 'show' : undefined}>
        <SearchRowInner end="md" id="outerSearchEl">
          <SearchColInner xs={8} sm={8} md={4} lg={4} id="searchColInner" className={searchIsOpen ? 'show' : undefined}>
            {/* Search header */}
            <SearchHeader handleSearchToggle={handleSearchToggle} />

            {/* Search bar */}
            <SearchBar
              searchIsOpen={searchIsOpen}
              searchQuery={searchQuery}
              onSearchSubmit={(searchTerm) => searchIsOpen && handleInputChange(searchTerm)}
            />

            {/* Search status */}
            {searchQuery.length > 0 && (
              <SearchStatus searchQuery={searchQuery} searchedPostsLength={searchedPosts.length} />
            )}

            {/* Categories & popular searches */}
            {searchIsOpen && searchedPosts.length === 0 && (
              <SearchSuggestions onSuggestionClick={(searchTerm) => searchIsOpen && handleInputChange(searchTerm)} />
            )}

            {searchResultsReturned && (
              <ResultsRow>
                {/* Results */}
                <Col xsOffset={1} xs={6} md={8} lg={10}>
                  {postsPaginated.map((story) => {
                    const post = story.content?.body?.find((b) => b.component === 'post');
                    if (!post) {
                      return undefined;
                    }

                    const body = renderPlainText(post.body);
                    const heading = renderRichTextReact(post.heading);
                    const subHeading = renderRichTextReact(post.subHeading);

                    return (
                      <Post key={post._uid}>
                        <Link to={withPrefix(story.path || story.full_slug)}>
                          {body && <span>{calculateReadTime({ raw: body })}</span>}
                          {heading && <h3>{heading}</h3>}
                          {subHeading && <div>{subHeading}</div>}
                        </Link>
                      </Post>
                    );
                  })}
                </Col>

                {/* Pagination */}

                <Col xsOffset={1} xs={6} md={8} lg={10}>
                  <Row>
                    <Pagination
                      paginationClass={paginationClass}
                      postsShown={postsShown}
                      postsPerPage={postsPerPage}
                      totalPosts={totalPosts}
                      previousPosts={previousPosts}
                      nextPosts={nextPosts}
                    />
                  </Row>
                </Col>
              </ResultsRow>
            )}
          </SearchColInner>
        </SearchRowInner>
      </SearchColOuter>
    </SearchRowOuter>
  );
}

Search.propTypes = {
  searchIsOpen: bool,
  handleSearchToggle: func,
};
Search.defaultProps = {
  searchIsOpen: false,
  handleSearchToggle: () => {},
};

export default Search;
