import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { bpWidthTailwind, colors, sizes } from '../../../styles/variables';
import { isBoolean, isFloat, isInt, mapColWidthProperties, mapSpaceClasses } from '../../../utils/helper';

const Background = styled.div`
  background: ${({ withBg, customBg }) => (withBg ? customBg || colors.beige200 : undefined)};
  position: relative;

  ${({ angled }) => {
    if (angled === 'topRight' || angled === 'topLeft') {
      return css`
        &::before {
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          width: 0;
          height: 0;
          border: 0;
          border-top: 20vw solid ${colors.beige100};
          border-left: ${() => (angled === 'topRight' ? '100vw solid transparent' : undefined)};
          border-right: ${() => (angled === 'topLeft' ? '100vw solid transparent' : undefined)};
        }
      `;
    }

    if (angled === 'bottomRight' || angled === 'bottomLeft') {
      return css`
        &::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 0;
          height: 0;
          border: 0;
          border-bottom: 20vw solid ${colors.beige100};
          border-left: ${() => (angled === 'bottomRight' ? '100vw solid transparent' : undefined)};
          border-right: ${() => (angled === 'bottomLeft' ? '100vw solid transparent' : undefined)};
        }
      `;
    }

    return undefined;
  }};
`;

const SectionCol = styled(Col)`
  &.custom-section-col {
    ${({ xs }) => {
      if (isInt(xs) || isFloat(xs)) {
        return css`
          flex-basis: ${(100 / sizes.gridCount.mobile) * xs}%;
          max-width: ${(100 / sizes.gridCount.mobile) * xs}%;
          display: block;
        `;
      }

      if (isBoolean(xs)) {
        if (xs) {
          return css`
            flex-grow: 1;
            flex-basis: 0;
            max-width: 100%;
            display: block;
          `;
        }

        return css`
          display: none;
        `;
      }

      return undefined;
    }};

    @media only screen and (${bpWidthTailwind.tablet}) {
      ${({ sm }) => {
        if (isInt(sm) || isFloat(sm)) {
          return css`
            flex-basis: ${(100 / sizes.gridCount.tablet) * sm}%;
            max-width: ${(100 / sizes.gridCount.tablet) * sm}%;
            display: block;
          `;
        }

        if (isBoolean(sm)) {
          if (sm) {
            return css`
              flex-grow: 1;
              flex-basis: 0;
              max-width: 100%;
              display: block;
            `;
          }

          return css`
            display: none;
          `;
        }

        return undefined;
      }};
    }

    @media only screen and (${bpWidthTailwind.desktopSm}) {
      ${({ md }) => {
        if (isInt(md) || isFloat(md)) {
          return css`
            flex-basis: ${(100 / sizes.gridCount.desktopSm) * md}%;
            max-width: ${(100 / sizes.gridCount.desktopSm) * md}%;
            display: block;
          `;
        }

        if (isBoolean(md)) {
          if (md) {
            return css`
              flex-grow: 1;
              flex-basis: 0;
              max-width: 100%;
              display: block;
            `;
          }

          return css`
            display: none;
          `;
        }

        return undefined;
      }};
    }

    @media only screen and (${bpWidthTailwind.desktopLg}) {
      ${({ lg }) => {
        if (isInt(lg) || isFloat(lg)) {
          return css`
            flex-basis: ${(100 / sizes.gridCount.desktopLg) * lg}%;
            max-width: ${(100 / sizes.gridCount.desktopLg) * lg}%;
            display: block;
          `;
        }

        if (isBoolean(lg)) {
          if (lg) {
            return css`
              flex-grow: 1;
              flex-basis: 0;
              max-width: 100%;
              display: block;
            `;
          }

          return css`
            display: none;
          `;
        }

        return undefined;
      }};
    }
  }
`;

const Section = ({ blok }) => {
  const { withBg, customBg = colors.beige200, anchorId, angled } = blok;
  const [colWidth, setColWidth] = useState(null);
  const [marginClasses, setMarginClasses] = useState([]);
  const [paddingClasses, setPaddingClasses] = useState([]);
  const [sectionColStyles, setSectionColStyles] = useState(null);
  const { viewWidth } = useViewport();

  useIsomorphicLayoutEffect(() => {
    const colWidthData = mapColWidthProperties(blok.config);
    setColWidth(colWidthData);

    const space = mapSpaceClasses(blok.config);
    setMarginClasses(space.marginClasses);
    setPaddingClasses(space.paddingClasses);
  }, [blok.config]);

  const sectionColRef = useCallback(
    (node) => {
      if (node !== null) {
        const styles = getComputedStyle(node);
        setSectionColStyles(styles);
      }
    },
    [colWidth, viewWidth],
  );

  const marginClassName = useMemo(
    () =>
      [
        'w-full box-border',
        sectionColStyles?.display === 'none' ? undefined : marginClasses.filter(Boolean).join(' '),
      ].join(' '),
    [marginClasses, sectionColStyles],
  );

  const paddingClassName = useMemo(
    () =>
      [
        'w-full box-border',
        sectionColStyles?.display === 'none' ? undefined : paddingClasses.filter(Boolean).join(' '),
      ].join(' '),
    [paddingClasses, sectionColStyles],
  );

  return (
    colWidth && (
      <div {...storyblokEditable(blok)} className={`section-component--container w-full box-border ${marginClassName}`}>
        <Background withBg={withBg} customBg={customBg} id={anchorId} angled={angled}>
          <div className={paddingClassName}>
            <Row>
              <SectionCol {...colWidth} className="custom-section-col" ref={sectionColRef}>
                {blok.container.map((nestedBlok) => (
                  <Row key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} />
                  </Row>
                ))}
              </SectionCol>
            </Row>
          </div>
        </Background>
      </div>
    )
  );
};

Section.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Section;
