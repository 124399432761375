import React, { useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { STATUS_INIT, STATUS_SUCCESS } from '../../../common/constants/formikStatus.constant';
import { btnLgTertiaryBlueStyles, btnSmTertiaryBlueStyles } from '../../../styles/buttons';
import {
  bodyLSemiBoldUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodyMSemiBoldUtopiaStyles,
  bodySRegularUtopiaStyles,
  bodySSemiBoldUtopiaStyles,
  headerLFillStyles,
  headerLOutlineStyles,
  headerMFillStyles,
  headerMOutlineStyles,
  headerMStyles,
  headerXlFillStyles,
  headerXlOutlineStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import FormikWrapper from '../../Support/FormikWrapper';

const SupportCol = styled(Col)`
  margin-top: 130px;
  margin-bottom: auto;
  padding-bottom: 50px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 110px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 100px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 90px;
  }
`;

const SupportFormSection = styled(Row)``;

const FormHeadingCol = styled(Col)`
  h1 {
    margin-bottom: 20px;

    p {
      ${headerXlOutlineStyles};
      -webkit-text-stroke: 1px ${colors.cerulean400};

      @media (${bpWidth.desktopSm}) {
        ${headerLOutlineStyles};
        -webkit-text-stroke: 1px ${colors.cerulean400};
      }

      @media (${bpWidth.mobile}) {
        ${headerMOutlineStyles};
        -webkit-text-stroke: 1px ${colors.cerulean400};
      }

      u {
        ${headerXlFillStyles};
        text-decoration: none;
        color: ${colors.cerulean400};
        -webkit-text-fill-color: ${colors.cerulean400};

        @media (${bpWidth.desktopSm}) {
          ${headerLFillStyles};
          color: ${colors.cerulean400};
          -webkit-text-fill-color: ${colors.cerulean400};
        }

        @media (${bpWidth.mobile}) {
          ${headerMFillStyles};
          color: ${colors.cerulean400};
          -webkit-text-fill-color: ${colors.cerulean400};
        }
      }
    }
  }
`;

const FormBodyCol = styled(Col)`
  > p:first-child,
  p:last-child {
    ${bodyMRegularUtopiaStyles};
    margin: 15px 0;
  }

  p {
    a {
      font-style: italic;
      text-decoration: none;
      color: ${colors.cerulean400};
    }

    b {
      ${bodyLSemiBoldUtopiaStyles};
    }
  }

  @media (${bpWidth.desktopSm}) {
    p {
      ${bodySRegularUtopiaStyles};

      b {
        ${bodyMSemiBoldUtopiaStyles};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    p {
      b {
        ${bodySSemiBoldUtopiaStyles};
      }
    }
  }
`;

const SupportFormCol = styled(Col)``;

const SupportFormSubmittedSection = styled.div`
  width: 100%;
  padding-bottom: 50px;
`;

const SubmittedHeadingCol = styled(Col)`
  h1 {
    margin-bottom: 30px;

    p {
      ${headerXlFillStyles};
      line-height: 1;
      color: ${colors.cerulean400};

      u {
        ${headerXlOutlineStyles};
        text-decoration: none;
        -webkit-text-stroke: 1px ${colors.cerulean400};
        line-height: 1;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    h1 {
      p {
        ${headerLFillStyles};
        line-height: 1;
        color: ${colors.cerulean400};

        u {
          ${headerLOutlineStyles};
          text-decoration: none;
          -webkit-text-stroke: 1px ${colors.cerulean400};
          line-height: 1;
        }
      }
    }
  }

  @media (${bpWidth.mobile}) {
    h1 {
      p {
        ${headerMStyles};
        line-height: 1;
        color: ${colors.cerulean400};

        u {
          ${headerMStyles};
          text-decoration: none;
          line-height: 1;
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 0.75px ${colors.cerulean400};
        }
      }
    }
  }
`;

const SubmittedBodyCol = styled(Col)`
  p {
    ${bodyMRegularUtopiaStyles};

    a {
      text-decoration: none;
      color: ${colors.cerulean400};
    }

    b {
      ${bodyMSemiBoldUtopiaStyles};
    }
  }

  @media (${bpWidth.desktopSm}) {
    p {
      ${bodySRegularUtopiaStyles};

      b {
        ${bodySSemiBoldUtopiaStyles};
      }
    }
  }
`;

const CtaCol = styled(Col)`
  padding-top: 40px;

  button {
    ${btnLgTertiaryBlueStyles};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${colors.cerulean400};

    svg {
      margin-left: 15px;
      width: 7px;
    }

    &:hover {
      path#Side {
        stroke: ${colors.cerulean700};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    padding-top: 30px;

    button {
      ${btnSmTertiaryBlueStyles};
      color: ${colors.cerulean400};

      svg {
        margin-left: 10px;
        width: 5px;
      }
    }
  }
`;

const Support = ({ blok }) => {
  const [formStatusType, setFormStatusType] = useState(STATUS_INIT);

  // Trigger success copy if form submits successfully
  const formSucceededCheck = React.useCallback(
    (formStatus) => {
      setFormStatusType(formStatus);
      return formStatusType;
    },
    [setFormStatusType],
  );

  // Trigger resetting form if user returns to form from success copy
  const resetSupportForm = React.useCallback(() => {
    setFormStatusType(STATUS_INIT);
    return formStatusType;
  }, [setFormStatusType]);

  const ctaClicked = () => {
    resetSupportForm();
  };

  return (
    <SupportCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {formStatusType !== STATUS_SUCCESS && (
        <SupportFormSection>
          {/* Heading */}
          <Col xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={2} md={3} lgOffset={3} lg={4}>
            <Row>
              <FormHeadingCol xs={8} md={10} lg={12}>
                <h1>{renderRichTextReact(blok.formHeading)}</h1>
              </FormHeadingCol>
            </Row>

            <Row>
              <FormBodyCol xs={8} md={9} lg={11}>
                {renderRichTextReact(blok.formBody)}
              </FormBodyCol>
            </Row>
          </Col>

          {/* Support form */}
          {!blok.hideForm && (
            <SupportFormCol xsOffset={1} xs={6} smOffset={1} sm={4} mdOffset={1} md={3} lgOffset={0} lg={3}>
              <FormikWrapper formStatus={formSucceededCheck} />
            </SupportFormCol>
          )}
        </SupportFormSection>
      )}

      {/* Form successfully submitted view */}
      {formStatusType === STATUS_SUCCESS && (
        <SupportFormSubmittedSection>
          {/* Heading */}
          <Row>
            <SubmittedHeadingCol xsOffset={1} xs={6} mdOffset={2} md={5} lgOffset={3}>
              <h1>{renderRichTextReact(blok.thankYouHeading)}</h1>
            </SubmittedHeadingCol>
          </Row>

          {/* Body */}
          <Row>
            <SubmittedBodyCol xsOffset={1} xs={6} sm={4} mdOffset={2} md={3} lgOffset={3}>
              {renderRichTextReact(blok.thankYouBody)}
            </SubmittedBodyCol>
          </Row>

          {/* CTA */}
          <Row>
            <CtaCol xsOffset={1} xs={6} sm={4} mdOffset={2} md={3} lgOffset={3}>
              {blok.backButton?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} onClick={ctaClicked} />
              ))}
            </CtaCol>
          </Row>
        </SupportFormSubmittedSection>
      )}
    </SupportCol>
  );
};

Support.propTypes = {
  blok: object.isRequired,
};

export default Support;
