import React, { useMemo, useState } from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import {
  bodyMBoldBrownStyles,
  bodyMRegularBrownStyles,
  bodySBoldBrownStyles,
  bodySRegularBrownStyles,
  bodyXsBoldBrownStyles,
  eyebrowSStyles,
  headerSStyles,
  headerXsStyles,
} from '../../../styles/typography';
import { bpWidthTailwind, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const Team = styled.div`
  ${eyebrowSStyles};
  color: ${({ color }) => color};
`;

const Name = styled.div`
  transition: font-size 0.3s ease-in-out;

  ${bodyXsBoldBrownStyles};
  color: ${colors.beige100};
  color: ${({ color }) => color};
  font-style: italic;
  font-size: ${({ isMouseOver }) => (isMouseOver ? '14px' : '12px')};
  line-height: ${({ isMouseOver }) => (isMouseOver ? 1.7 : 2)};

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyMBoldBrownStyles};
    color: ${colors.beige100};
    color: ${({ color }) => color};
    font-style: italic;
    font-size: ${({ isMouseOver }) => (isMouseOver ? '18px' : '16px')};
    line-height: ${({ isMouseOver }) => (isMouseOver ? 1.3333 : 1.5)};
  }
`;

const Position = styled.div`
  transition: font-size 0.3s ease-in-out;

  ${headerXsStyles};
  color: ${colors.gray100};
  font-size: ${({ isMouseOver }) => (isMouseOver ? '28px' : '22px')};

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerSStyles};
    color: ${colors.gray100};
    font-size: ${({ isMouseOver }) => (isMouseOver ? '36px' : '33px')};
    letter-spacing: ${({ isMouseOver }) => (isMouseOver ? '-0.36px' : '-0.28px')};
  }
`;

const Department = styled.div`
  transition: font-size 0.3s ease-in-out;

  ${bodySBoldBrownStyles};
  color: ${colors.beige100};
  color: ${({ color }) => color};
  font-size: ${({ isMouseOver }) => (isMouseOver ? '16px' : '14px')};
  line-height: ${({ isMouseOver }) => (isMouseOver ? 1.5 : 1.7)};

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyMBoldBrownStyles};
    color: ${colors.beige100};
    color: ${({ color }) => color};
    font-size: ${({ isMouseOver }) => (isMouseOver ? '18px' : '16px')};
    line-height: ${({ isMouseOver }) => (isMouseOver ? 1.3 : 1.5)};
  }
`;

const Division = styled.div`
  transition: font-size 0.3s ease-in-out;

  ${bodySRegularBrownStyles};
  color: ${colors.beige100};
  font-size: ${({ isMouseOver }) => (isMouseOver ? '16px' : '14px')};
  line-height: ${({ isMouseOver }) => (isMouseOver ? 1.25 : 1.4)};

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyMRegularBrownStyles};
    color: ${colors.beige100};
    font-size: ${({ isMouseOver }) => (isMouseOver ? '18px' : '16px')};
    line-height: ${({ isMouseOver }) => (isMouseOver ? 1.3 : 1.5)};
  }
`;

const Avatar = styled.div`
  .gatsby-image-wrapper {
    img {
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      margin-right: 0;
      margin-left: auto;
    }
  }

  @media (${bpWidthTailwind.desktopSm}) {
    --pff-b2b-team-avatar-scale: ${({ isMouseOver }) => (isMouseOver ? 1.08 : 1)};

    .gatsby-image-wrapper {
      overflow: ${({ isMouseOver }) => (isMouseOver ? 'initial' : 'hidden')};

      img {
        transform: scale(var(--pff-b2b-team-avatar-scale));
        transform-origin: right bottom;
        transition:
          opacity 0.25s linear,
          transform 0.3s linear,
          transform-origin 0.1s linear;
      }
    }
  }
`;

const TeamCard = ({ blok }) => {
  const name = useMemo(() => renderRichTextReact(blok.name), [blok.name]);
  const position = useMemo(() => renderRichTextReact(blok.position), [blok.position]);
  const department = useMemo(() => renderRichTextReact(blok.department), [blok.department]);
  const division = useMemo(() => renderRichTextReact(blok.division), [blok.division]);
  const team = useMemo(() => blok.team, [blok.team]);
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <div
      {...storyblokEditable(blok)}
      className="team-card-container
      w-[19.6875rem] sm:w-[21.625rem] md:w-full md:max-w-[15.6875rem] lg:max-w-[22.5rem]
      h-[21.625rem] md:h-[21.6rem] lg:h-[28.5rem]
      bg-[#041928] rounded-2xl border border-solid border-navy-100 relative"
      onMouseOver={() => setIsMouseOver(true)}
      onFocus={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      onBlur={() => setIsMouseOver(false)}
    >
      <div className="absolute md:top-8 lg:top-14 md:left-6 lg:left-8 right-4 hidden md:flex flex-col gap-6">
        <Name isMouseOver={isMouseOver} color={colors.sparkCitron}>
          {name}
        </Name>
        <Position isMouseOver={isMouseOver}>{position}</Position>
      </div>
      <div className="absolute md:top-52 lg:top-64 md:left-6 lg:left-8 hidden md:flex flex-col gap-2">
        <Department isMouseOver={isMouseOver} color={colors.sparkCitron}>
          {department}
        </Department>
        <Division isMouseOver={isMouseOver}>{division}</Division>
      </div>
      {blok.picture?.length > 0 && (
        <>
          {blok.glowColor && (
            <>
              <div className="hidden md:block absolute w-full h-full overflow-hidden">
                <div
                  className="absolute bottom-20 right-0 w-[175px] h-[174px] rounded-[175px] bg-cerulean-100 blur-[100px]"
                  style={{ background: blok.glowColor }}
                ></div>
              </div>
              <div className="md:hidden absolute w-full h-full overflow-hidden">
                <div
                  className="absolute bottom-10 left-0 w-[101px] h-[100px] rounded-[101px] bg-cerulean-100 blur-[100px]"
                  style={{ background: blok.glowColor }}
                ></div>
              </div>
              <div className="md:hidden absolute w-full h-full overflow-hidden">
                <div
                  className="absolute bottom-0 right-0 w-[175px] h-[174px] rounded-[175px] bg-cerulean-100 blur-[100px]"
                  style={{ background: blok.glowColor }}
                ></div>
              </div>
            </>
          )}
          <Avatar className="absolute right-0 bottom-0 left-0" isMouseOver={isMouseOver}>
            {blok.picture?.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </Avatar>
        </>
      )}
      <div className="flex sm:hidden absolute w-full top-12 px-4 justify-center">
        <Team color={blok.glowColor}>{team}</Team>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  blok: object.isRequired,
};

export default TeamCard;
