import React from 'react';
import PropTypes from 'prop-types';
import AppStateProvider from '../../context/app-state.context';
import GlobalStyles from '../../styles/globalStyles';
import Layout from './layouts/Layout';
import '../../styles/fonts/brown.css';
import '../../styles/fonts/utopia.css';

const DefaultPage = ({ blok }) => (
  <AppStateProvider>
    <GlobalStyles />
    <Layout blok={blok} />
  </AppStateProvider>
);

DefaultPage.propTypes = {
  blok: PropTypes.any.isRequired,
};

export default DefaultPage;
