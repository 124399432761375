import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../styles/typography';
import { bpWidth } from '../../styles/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const LeftCol = styled(Col)`
  @media (${bpWidth.tablet}) {
    order: 2;
  }
`;

const RightCol = styled(Col)`
  @media (${bpWidth.desktopSm}) {
    margin-bottom: 0;
  }

  @media (${bpWidth.tablet}) {
    order: 1;
    margin-bottom: 40px;
  }
`;

const Title = styled.div`
  ${eyebrowMStyles};
  margin-bottom: 20px;

  @media (${bpWidth.tablet}) {
    ${eyebrowSStyles};
    margin-bottom: 10px;
  }
`;

const Content = styled.div`
  ${bodyMRegularUtopiaStyles};

  p {
    margin-bottom: 20px;

    @media (${bpWidth.tablet}) {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const TwoColumnsTextWithImage = ({ blok }) => {
  const { title, content, asset } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Row>
        <LeftCol xs={8} md={4} lg={5}>
          <Title>{title}</Title>
          <Content>{renderRichTextReact(content)}</Content>
        </LeftCol>
        <RightCol xsOffset={0} xs={8} mdOffset={1} md={5} lg={6}>
          {asset?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </RightCol>
      </Row>
    </Col>
  );
};

TwoColumnsTextWithImage.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoColumnsTextWithImage;
