/* eslint-disable no-nested-ternary */
import { useMemo } from 'react';
import _ from 'lodash';

// hook to return the discount status of a line item
export function useCartDiscountStatus(cart) {

  // this returns whether a discount is currently applied
  const cartDiscountStatus = useMemo(() => {
    if(cart.totalQuantity === 0) {
      return false;
    }

    return cart.discountAllocations?.length > 0;
  }, [cart])

  /*
    returns an array of the cart's currently applied:
    1) discount codes if applicable
    2) automatic discount titles if applicable
  */
  const cartDiscountNames = useMemo(() => {

    // discount codes
    const discountCodes = !_.isEmpty(cart.discountCodes) ? cart.discountCodes.map(discountCode => discountCode.code) : [];

    // automatic discount titles
    const discountTitles = !_.isEmpty(cart.discountAllocations) ? cart.discountAllocations.map(discountAllocation => discountAllocation.title).filter(el => el) : [];

    // concat codes and titles
    const discountNames = discountCodes.concat(discountTitles);

    return !_.isEmpty(discountNames) ? discountNames : null;
  }, [cart])
  
  /*
    total applied discount for cart
  */
  const cartDiscountAmount = useMemo(() => {
    if(!_.isEmpty(cart.discountAllocations)) {
      return cart.discountAllocations.map(discountAllocation => {
        return discountAllocation?.discountedAmount?.amount
      }).reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue)
      }, 0).toFixed(2);
    }
    
    return 0;
  }, [cart])

  return {
    cartDiscountStatus,
    cartDiscountNames,
    cartDiscountAmount,
  };
}
