import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { bool } from 'prop-types';
import { colors } from './variables.js';
import { DYNAMIC_HEADER_INDICATOR, SCROLL_DOWN, SCROLL_UP } from '../constants';

const GlobalStyle = createGlobalStyle`
  ${reset};

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    background-color: ${colors.beige100};
    font-size: 16px;
    background-color: ${(props) => (props.dark ? colors.navy600 : undefined)};
    color: ${(props) => (props.dark ? colors.beige100 : undefined)};
  }

  body.popUpFreezeScroll{
    overflow: hidden!important;
  }

  body.${SCROLL_DOWN} .${DYNAMIC_HEADER_INDICATOR} {
    transform: translate3d(0, -100%, 0);
  }
  body.${SCROLL_UP} .${DYNAMIC_HEADER_INDICATOR} {
    transform: none;
  }

  html, body, #___gatsby, #gatsby-focus-wrapper, #layoutGrid {
    height: 100%;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  .overflow-hidden,
  .overflow {
    overflow: hidden!important;
  }

  // hide HubSpot chatflow on all pages except /support
  body #hubspot-messages-iframe-container {
    display: ${(props) => (props.displayHubSpotChatflow ? 'block !important' : 'none !important')};
  }

  html.is-locked body {
    height: calc(var(--window-inner-height) - 1px);
    overflow: hidden;
    box-sizing: border-box;
  }

  p {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  ul {
    margin-bottom: 25px;
    list-style: disc;
    padding-left: 15px;
  }

  ol {
    list-style: lower-alpha;
    margin-left: 20px;

    p {
      margin-bottom: 30px;
    }

    ol {
      list-style: decimal;
    }
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  b, strong {
    font-weight: bold;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  .truste_box_overlay {
    position: fixed!important;
  }
`;

GlobalStyle.propTypes = {
  displayHubSpotChatflow: bool,
  dark: bool,
};
GlobalStyle.defaultProps = {
  displayHubSpotChatflow: false,
  dark: false,
};

export default GlobalStyle;
