import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import PiaggioGroupLogo from '../../../img/Piaggio-Group-K.svg';
import { spacerXxStyles } from '../../../styles/structureSpacing';
import { bodyXsRegularBrownStyles, bodyXxxsRegularBrownStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';

const MenuFooterRow = styled(Row)`
  border-top: 0.75px solid ${colors.beige100};
`;

const MenuFooterCol = styled(Col)`
  ${bodyXxxsRegularBrownStyles};
  ${spacerXxStyles};
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg.pffLogo {
    width: 125px;
  }

  svg.pgLogo {
    width: 100px;

    g {
      fill: ${colors.beige100};
    }
  }

  @media (${bpWidth.mobile}) {
    display: flex;
    flex-direction: column;

    svg.pffLogo {
      width: 80px;
    }

    svg.pgLogo {
      width: 70px;
    }
  }
`;

const MenuFooterRowOne = styled(Row)`
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (${bpWidth.mobile}) {
    .copyrightDesktop {
      display: none;
    }
  }
`;

const MenuFooterColOne = styled(Col)`
  align-items: center;
`;

const PffIcon = styled.div`
  img {
    width: 123px;
    max-width: 100%;
  }

  @media (${bpWidth.mobile}) {
    img {
      width: 96px;
    }
  }
`;

const Copyright = styled.div`
  p {
    ${bodyXsRegularBrownStyles};
    color: ${colors.beige100};
    height: fit-content;
    margin-bottom: 0;
    text-align: center;
  }

  @media (${bpWidth.mobile}) {
    p {
      display: none;
    }
  }
`;

const MenuFooterRowTwo = styled(Row)`
  display: none;

  p {
    ${bodyXsRegularBrownStyles};
    color: ${colors.beige100};
  }

  @media (${bpWidth.mobile}) {
    display: flex;
    padding-top: 10px;
    width: 100%;
  }
`;

const BusinessMenuFooter = () => {
  const { footerBlok } = useBusinessAppState();
  const { logo, rightsText } = footerBlok || {};
  const copyrightDate = new Date().getFullYear();

  return (
    <MenuFooterRow>
      <MenuFooterCol xsOffset={1} xs={6} md={8} lg={10}>
        <MenuFooterRowOne>
          <MenuFooterColOne xs={8} md={10} lg={12}>
            {/* PFF logo */}
            {logo?.filename && (
              <PffIcon>
                <a href="/">
                  <img src={logo.filename} alt="Piaggio Fast Forward Logo" />
                </a>
              </PffIcon>
            )}

            {/* copyright */}
            <Copyright>
              <p>{`${rightsText} ${copyrightDate}`}</p>
            </Copyright>

            {/* PG logo */}
            <a href="https://www.piaggiogroup.com" target="_blank" rel="noreferrer">
              <PiaggioGroupLogo alt="Piaggio Group Logo" className="pgLogo" />
            </a>
          </MenuFooterColOne>
        </MenuFooterRowOne>
        <MenuFooterRowTwo>
          <Col xs={8} md={0}>
            {/* copyright */}
            <p>{`${rightsText} ${copyrightDate}`}</p>
          </Col>
        </MenuFooterRowTwo>
      </MenuFooterCol>
    </MenuFooterRow>
  );
};

export default BusinessMenuFooter;
