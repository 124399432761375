import React from 'react';
import PropTypes from 'prop-types';
import BusinessAppStateProvider from '../../context/business-app-state.context';
import GlobalStyles from '../../styles/globalStyles';
import BusinessLayout from './layouts/BusinessLayout';
import '../../styles/fonts/brown.css';
import '../../styles/fonts/utopia.css';

const BusinessPage = ({ blok }) => (
  <BusinessAppStateProvider>
    <GlobalStyles dark />
    <BusinessLayout blok={blok} />
  </BusinessAppStateProvider>
);

BusinessPage.propTypes = {
  blok: PropTypes.any.isRequired,
};

export default BusinessPage;
