import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bool, number } from 'prop-types';
import styled from 'styled-components';
import { StoreContext } from '../../context/store-context';
import {
  bodyLItalicUtopiaStyles,
  bodyMItalicUtopiaStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../styles/typography.js';
import { bpWidth } from '../../styles/variables.js';
import { legacyLineItems } from '../../utils';
import LineItem from './LineItem.jsx';

const CartBodyRow = styled(Row)`
  flex: 1;
  padding-top: 75px;
  overflow-y: scroll;
  position: relative;

  @media (${bpWidth.desktopSm}) {
    padding-top: 65px;
  }

  @media (${bpWidth.tablet}) {
    padding-top: 55px;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 50px;
  }
`;

const CartBodyCol = styled(Col)`
  padding-right: 4.5%;
  padding-left: 4.5%;
  pointer-events: ${(props) => (props.accessoriesExpanded ? 'none' : 'auto')};

  @media (${bpWidth.desktopSm}) {
    padding-right: 17%;
    padding-left: 17%;
  }

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    padding-left: 0;
  }

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const CartTitle = styled.h2`
  ${eyebrowMStyles};

  @media (${bpWidth.mobile}) {
    ${eyebrowSStyles};
  }
`;

const CartEmptyStateText = styled.p`
  ${bodyLItalicUtopiaStyles};
  font-weight: normal;
  padding-top: 40px;

  @media (${bpWidth.desktopSm}) {
    ${bodyMItalicUtopiaStyles};
  }

  @media (${bpWidth.mobile}) {
    letter-spacing: -0.16px;
  }
`;

const CartBody = React.forwardRef((props, ref) => {
  const { accessoriesExpanded, lastLineItemBottomPadding } = props;

  const { cart, didJustAddToCart } = useContext(StoreContext);

  const lineItems = useMemo(() => legacyLineItems(cart.lines), [cart]);

  const accessoriesContainerEl = ref;

  const [cartTitle, setCartTitle] = useState('YOUR CART');

  useEffect(() => {
    if (didJustAddToCart) {
      setCartTitle('Added to your cart!');
    } else {
      setCartTitle('YOUR CART');
    }
  }, [didJustAddToCart]);

  return (
    <CartBodyRow>
      <CartBodyCol
        xsOffset={1}
        xs={6}
        smOffset={1}
        sm={6}
        mdOffset={0}
        md={10}
        lgOffset={1}
        lg={10}
        accessoriesExpanded={accessoriesExpanded}
      >
        <CartTitle>{cartTitle}</CartTitle>
        {cart.totalQuantity === 0 ? (
          <CartEmptyStateText>Looks like your cart is empty!</CartEmptyStateText>
        ) : (
          lineItems.map((lineItem, index) => (
            <LineItem
              lineItemData={lineItem}
              index={index}
              arrayLength={cart.lines?.edges?.length ?? 0}
              key={lineItem.id}
              ref={accessoriesContainerEl}
              accessoriesExpanded={accessoriesExpanded}
              lastLineItemBottomPadding={lastLineItemBottomPadding}
            />
          ))
        )}
      </CartBodyCol>
    </CartBodyRow>
  );
});

CartBody.propTypes = {
  accessoriesExpanded: bool,
  lastLineItemBottomPadding: number,
};
CartBody.defaultProps = {
  accessoriesExpanded: false,
  lastLineItemBottomPadding: 140,
};

export default CartBody;
