import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { useCurrentTheme } from '../hooks';
import { useViewport } from './viewport.context';

/**
 * @type {React.Context<RootState>}
 */
const RootStateContext = React.createContext({});

/**
 * @typedef {Object} RootState
 * @property {ISbStories} categoryPostsSbStories
 * @property {(value: ISbStories) => void} setCategoryPostsSbStories
 * @property {string} categoryFullSlug
 * @property {(value: string) => void} setCategoryFullSlug
 * @property {string} categoryFeaturedPostFullSlug
 * @property {(value: string) => void} setCategoryFeaturedPostFullSlug
 * @property {*} post
 * @property {(value: any) => void} setPost
 * @property {any[]} relatedPosts
 * @property {(value: any[]) => void} setRelatedPosts
 * @property {any} shopifyProduct
 * @property {(value: any) => void} setShopifyProduct
 * @property {any} allShopifyProducts
 * @property {(value: any) => void} setAllShopifyProducts
 * @property {any[]} productColors
 * @property {(value: any[]) => void} setProductColors
 * @property {'show' | 'hide' | 'showHide'} fixedBottomSetting
 * @property {(value: 'show' | 'hide' | 'showHide') => void} setFixedBottomSetting
 * @property {any} productAffirms
 * @property {(value: any) => void} setProductAffirms
 */

/**
 * @returns {RootState}
 */
export const useRootState = () => {
  /**
   * @type {RootState}
   */
  const context = useContext(RootStateContext);
  if (!context) {
    throw new Error('useRootState must be used within the RootStateProvider');
  }
  return context;
};

const RootStateProvider = ({ children }) => {
  const [categoryPostsSbStories, setCategoryPostsSbStories] = useState(null);
  const [categoryFullSlug, setCategoryFullSlug] = useState(null);
  const [categoryFeaturedPostFullSlug, setCategoryFeaturedPostFullSlug] = useState(null);
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [shopifyProduct, setShopifyProduct] = useState(null);
  const [allShopifyProducts, setAllShopifyProducts] = useState([]);
  const [productColors, setProductColors] = useState([]);
  const [fixedBottomSetting, setFixedBottomSetting] = useState('hide');
  const [productAffirms, setProductAffirms] = useState({});

  const { viewWidth } = useViewport();
  const currentTheme = useCurrentTheme(viewWidth);

  const contextValue = useMemo(
    () => ({
      categoryPostsSbStories,
      setCategoryPostsSbStories,
      categoryFullSlug,
      setCategoryFullSlug,
      categoryFeaturedPostFullSlug,
      setCategoryFeaturedPostFullSlug,
      post,
      setPost,
      relatedPosts,
      setRelatedPosts,
      shopifyProduct,
      setShopifyProduct,
      allShopifyProducts,
      setAllShopifyProducts,
      productColors,
      setProductColors,
      fixedBottomSetting,
      setFixedBottomSetting,
      productAffirms,
      setProductAffirms,
    }),
    [
      categoryPostsSbStories,
      categoryFullSlug,
      categoryFeaturedPostFullSlug,
      post,
      relatedPosts,
      shopifyProduct,
      allShopifyProducts,
      productColors,
      fixedBottomSetting,
      productAffirms,
    ],
  );

  return (
    <ThemeProvider theme={currentTheme}>
      <RootStateContext.Provider value={contextValue}>{children}</RootStateContext.Provider>
    </ThemeProvider>
  );
};

RootStateProvider.propTypes = {
  children: PropTypes.any,
};

export default RootStateProvider;
