exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-business-contact-jsx": () => import("./../../../src/pages/business/contact.jsx" /* webpackChunkName: "component---src-pages-business-contact-jsx" */),
  "component---src-pages-business-jsx": () => import("./../../../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-business-kilo-product-sheet-jsx": () => import("./../../../src/pages/business/kilo-product-sheet.jsx" /* webpackChunkName: "component---src-pages-business-kilo-product-sheet-jsx" */),
  "component---src-pages-business-support-jsx": () => import("./../../../src/pages/business/support.jsx" /* webpackChunkName: "component---src-pages-business-support-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-data-policy-jsx": () => import("./../../../src/pages/data-policy.jsx" /* webpackChunkName: "component---src-pages-data-policy-jsx" */),
  "component---src-pages-faqs-jsx": () => import("./../../../src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../../src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kilo-user-manual-jsx": () => import("./../../../src/pages/kilo-user-manual.jsx" /* webpackChunkName: "component---src-pages-kilo-user-manual-jsx" */),
  "component---src-pages-open-source-jsx": () => import("./../../../src/pages/open-source.jsx" /* webpackChunkName: "component---src-pages-open-source-jsx" */),
  "component---src-pages-patents-jsx": () => import("./../../../src/pages/patents.jsx" /* webpackChunkName: "component---src-pages-patents-jsx" */),
  "component---src-pages-pff-follow-user-manual-jsx": () => import("./../../../src/pages/PFF-follow-user-manual.jsx" /* webpackChunkName: "component---src-pages-pff-follow-user-manual-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-promotion-terms-jsx": () => import("./../../../src/pages/promotion-terms.jsx" /* webpackChunkName: "component---src-pages-promotion-terms-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-of-sale-jsx": () => import("./../../../src/pages/terms-of-sale.jsx" /* webpackChunkName: "component---src-pages-terms-of-sale-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-business-product-jsx": () => import("./../../../src/templates/BusinessProduct.jsx" /* webpackChunkName: "component---src-templates-business-product-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

