import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { bool } from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { STATUS_FAILURE, STATUS_INIT, STATUS_SUCCESS } from '../../common/constants/formikStatus.constant';
import { consentChoiceValue } from '../../common/constants/privacyPolicyPopup.constant';
import { btnLgPrimaryMediumBlueStyles, btnSmPrimaryMediumBlueStyles } from '../../styles/buttons';
import { eyebrowMStyles, eyebrowSStyles } from '../../styles/typography';
import { bpWidth } from '../../styles/variables';
import { dataLayerSubscribe } from '../../utils/dataLayerEvents.js';
import { isBrowser } from '../../utils/helper';
import { tiktokSubscribe } from '../../utils/tiktokEvents.js';
import PopUp from '../Modal/PopUp';
import PrivacyPolicyConfirmationPopup from '../PrivacyPolicy/PrivacyPolicyConfirmationPopup';
import EmailForm from './EmailForm.jsx';

const Row = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const BodyRow = styled(Row)`
  margin-bottom: 30px;

  p {
    ${eyebrowMStyles};

    @media (${bpWidth.desktopSm}) {
      ${eyebrowSStyles};
    }
  }
`;

const ButtonRow = styled(Row)`
  button {
    ${btnLgPrimaryMediumBlueStyles};

    @media (${bpWidth.desktopSm}) {
      ${btnSmPrimaryMediumBlueStyles};
    }
  }
`;

const FormikWrapper = (props) => {
  const { navFontColorLight } = props;

  const successMessage = 'Thanks for submitting the form.';
  const failureMessage = 'Message failed to submit. Please try again.';

  const [openPrivacyPolicyPopup, setOpenPrivacyPolicyPopup] = useState(false);
  const [openLastStepPopup, setOpenLastStepPopup] = useState(false);
  const [message, setMessage] = useState('');

  const ttq = isBrowser ? window.ttq || {} : {};

  const formik = useFormik({
    initialStatus: STATUS_INIT,
    initialValues: {
      email: '',
      consent: 0,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: (values) => {
      if (!values.consent) {
        setOpenPrivacyPolicyPopup(true);
        return;
      }

      setOpenPrivacyPolicyPopup(false);
      const data = {
        fields: [
          {
            name: 'email',
            value: values.email,
          },
          {
            name: 'gita_newsletter_subscriber',
            value: true,
          },
          {
            name: 'lifecyclestage',
            value: 'subscriber',
          },
        ],
        context: {
          pageName: document.title ?? '',
          pageUri: window.location?.href ?? '',
        },
      };

      // Add hubspot tracking cookie if available
      const hubspotTrackingCookie = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*=\s*([^;]*).*$)|^.*$/, '$1');
      if (hubspotTrackingCookie !== '') data.context.hutk = hubspotTrackingCookie;

      let url;

      if (
        typeof window !== 'undefined' &&
        typeof document !== 'undefined' &&
        !window.location.href.includes('localhost:') &&
        !window.location.href.includes('staging')
      ) {
        // Production
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/500ac99c-d68b-4428-8e8c-622eafc5325c';
      } else {
        // Staging
        url = 'https://api.hsforms.com/submissions/v3/integration/submit/6286664/5afa3980-4d2d-4ab5-803f-12c3cf18365e';
      }

      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => {
          // response.ok checks if the response.status is in the range 200-299, inclusive
          formik.resetForm();
          formik.setStatus(response.ok ? STATUS_SUCCESS : STATUS_FAILURE);
          setMessage(successMessage);
        })
        .catch(() => {
          formik.setStatus(STATUS_FAILURE);
          setMessage(failureMessage);
        })
        .finally(() => {
          formik.setSubmitting(false);
          setOpenLastStepPopup(true);

          if (_.has(ttq, 'track')) {
            // ensure ttq has been loaded
            tiktokSubscribe(ttq);
          }

          dataLayerSubscribe();
        });
    },
  });

  const onChoosingConsent = (choice) => {
    if (choice === consentChoiceValue.consent) {
      formik.values.consent = choice;
      formik.handleSubmit();
    } else {
      setOpenPrivacyPolicyPopup(false);
      formik.setSubmitting(false);
    }
  };

  const onCloseLastStepPopup = () => {
    setOpenLastStepPopup(false);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <EmailForm navFontColorLight={navFontColorLight} {...formik} />
      </FormikProvider>
      {openPrivacyPolicyPopup && <PrivacyPolicyConfirmationPopup onPopUpClose={onChoosingConsent} />}
      {openLastStepPopup && (
        <PopUp
          onPopUpClose={onCloseLastStepPopup}
          isHeadless
          width={{
            desktopLg: '36.5vw',
            desktopSm: '40vw',
            tablet: '60vw',
            mobile: '90vw',
          }}
          padding={{ mobile: '20px' }}
        >
          <BodyRow>
            <p>{message}</p>
          </BodyRow>
          <ButtonRow>
            <button type="button" onClick={onCloseLastStepPopup}>
              Close
            </button>
          </ButtonRow>
        </PopUp>
      )}
    </>
  );
};

FormikWrapper.propTypes = {
  navFontColorLight: bool,
};
FormikWrapper.defaultProps = {
  navFontColorLight: false,
};

export default FormikWrapper;
