import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bodyXxsRegularBrownStyles } from '../../styles/typography';
import { bpWidth, colors } from '../../styles/variables';

const Caption = styled.div`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.gray400};
`;

const LeftCaption = styled(Caption)`
  margin-left: 12px;
  width: 48%;

  @media (${bpWidth.desktopSm}) {
    margin-left: 10px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 10px;
    margin-bottom: 40px;
    margin-left: 0;
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    width: unset;
  }
`;

const RightCaption = styled(Caption)`
  margin-top: 15px;
  margin-left: 12px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 10px;
    margin-left: 10px;
    width: 48%;
  }

  @media (${bpWidth.tablet}) {
    margin-left: 0;
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    width: unset;
  }
`;

const LeftImage = styled.div`
  margin-right: 12px;

  @media (${bpWidth.desktopSm}) {
    margin-right: 10px;
  }

  @media (${bpWidth.tablet}) {
    margin-right: 0;
  }
`;

const RightImage = styled.div`
  margin-top: 230px;
  margin-left: 12px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 135px;
    margin-left: 10px;
  }

  @media (${bpWidth.tablet}) {
    margin-top: 0;
    margin-left: 0;
  }
`;

const TwoImagesLarge = ({ blok }) => {
  const loadType = 'lazy';
  const { assetLeft, captionLeft, assetRight, captionRight } = blok;
  const options = {
    layout: 'constrained',
    quality: 70,
    width: 1920,
  };
  const [imageDataLeft, setImageDataLeft] = useState(null);
  const [imageDataRight, setImageDataRight] = useState(null);

  useEffect(() => {
    if (assetLeft?.filename) {
      const artDirected = [
        {
          media: `(${bpWidth.mobile})`,
          image: getGatsbyImage(assetLeft.filename, { ...options, width: 414 }),
        },
        {
          media: `(${bpWidth.tablet})`,
          image: getGatsbyImage(assetLeft.filename, { ...options, width: 768 }),
        },
        {
          media: `(${bpWidth.desktopSm})`,
          image: getGatsbyImage(assetLeft.filename, { ...options, width: 1280 }),
        },
      ];
      const imageLeft = withArtDirection(getGatsbyImage(assetLeft.filename, options), artDirected);
      setImageDataLeft(imageLeft);
    }

    if (assetRight?.filename) {
      const artDirected = [
        {
          media: `(${bpWidth.mobile})`,
          image: getGatsbyImage(assetRight.filename, { ...options, width: 414 }),
        },
        {
          media: `(${bpWidth.tablet})`,
          image: getGatsbyImage(assetRight.filename, { ...options, width: 768 }),
        },
        {
          media: `(${bpWidth.desktopSm})`,
          image: getGatsbyImage(assetRight.filename, { ...options, width: 1280 }),
        },
      ];
      const imageRight = withArtDirection(getGatsbyImage(assetRight.filename, options), artDirected);
      setImageDataRight(imageRight);
    }
  }, [blok]);

  const transformKeyword = (content) =>
    content?.length > 0
      ? content.replace('gitamini', 'gita<i>mini</i>').replace('gitaplus', 'gita<i>plus</i>')
      : undefined;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Row>
        {imageDataLeft && (
          <Col xs={8} md={6} lg={8}>
            <LeftImage>
              <GatsbyImage image={imageDataLeft} alt={captionLeft} loading={loadType} />
            </LeftImage>
          </Col>
        )}
        {imageDataRight && (
          <Col xs={8} md={4} lg={4}>
            <LeftCaption dangerouslySetInnerHTML={{ __html: transformKeyword(captionLeft) }} />
            <RightImage>
              <GatsbyImage image={imageDataRight} alt={captionRight} loading={loadType} />
            </RightImage>
            <RightCaption dangerouslySetInnerHTML={{ __html: transformKeyword(captionRight) }} />
          </Col>
        )}
      </Row>
    </Col>
  );
};

TwoImagesLarge.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default TwoImagesLarge;
