/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { graphql, useStaticQuery } from 'gatsby';
import { array, bool, func } from 'prop-types';
import styled from 'styled-components';
import {
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';
import AccessoryItem from './AccessoryItem.jsx';

const AccessoriesContainer = styled(Row)`
  position: absolute;
  width: 64%;
  background-color: ${colors.beige200};
  z-index: 999;
  left: 50%;
  transform: translate(-50%, -${(props) => props.translateYValue}px);
  height: 50vh;
  max-height: 400px;
  overflow-y: ${(props) => (props.accessoriesExpanded ? 'auto' : 'hidden')};
  transition: transform 250ms ease-out;

  @media (${bpWidth.desktopSm}) {
    width: 58%;
  }

  @media (${bpWidth.tablet}) {
    width: 64%;
  }

  @media (${bpWidth.mobile}) {
    width: 66%;
  }

  > ${Col} {
    position: relative;
    z-index: 1;
    height: 100%;
    max-height: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const AccessoriesHeaderRow = styled(Row)`
  user-select: none;
  position: absolute;
  width: 100%;
  background-color: ${colors.beige200};
  z-index: 2;
`;

const AccessoriesBodyRow = styled(Row)`
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  padding-top: ${(props) => props.topPadding}px;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const AccessoriesHeader = styled.div`
  cursor: pointer;
`;

const CompleteText = styled.p`
  ${eyebrowMStyles};
  padding-top: 16px;
  margin-bottom: 0;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
  }
`;

const ToggleText = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray400};
  padding-top: 0px;
  padding-bottom: 13px;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray400};
  }
`;

const Accessories = React.forwardRef((props, ref) => {
  const { accessoriesExpanded, handleAccessoriesClick, mainProductsInCart } = props;

  const { accessoriesContainerEl, accessoriesHeaderRowEl, accessoriesBodyRowEl } = ref;

  const accessoriesData = useStaticQuery(graphql`
    {
      allShopifyProduct(
        filter: {
          handle: {
            regex: "/(battery-charger)|(backpack)|(gita-igloo-cooler)|(open-organizer-bin)|(gitamini-battery-charger)/"
          }
        }
        sort: { handle: ASC }
      ) {
        nodes {
          title
          handle
          description
          productType
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          storefrontId
          id
          variants {
            availableForSale
            storefrontId
            title
            price
            selectedOptions {
              name
              value
            }
            image {
              originalSrc
            }
          }
          options {
            name
            values
          }
          featuredImage {
            src
          }
        }
      }
      contentfulAccessoryRecommendations: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "//markdown-content/accessory-recommendations.md/" }
          frontmatter: { status: { eq: "published" } }
        }
      ) {
        nodes {
          frontmatter {
            gitaRecommendations {
              accessoryHandle
            }
            gitaplusRecommendations {
              accessoryHandle
            }
            gitaminiRecommendations {
              accessoryHandle
            }
          }
        }
      }
    }
  `);

  const recommendedAccessories = accessoriesData.contentfulAccessoryRecommendations.nodes[0].frontmatter;

  // function to return an array of accessory handles to print in the container
  const accessoriesToPrint = () => {
    const accessoriesToPrintArray = [];

    mainProductsInCart.forEach((mainProduct) => {
      if (mainProduct === 'gitaplus') {
        recommendedAccessories.gitaplusRecommendations.forEach((accessory) => {
          accessoriesToPrintArray.push(accessory.accessoryHandle);
        });
      }
      if (mainProduct === 'gitamini') {
        recommendedAccessories.gitaminiRecommendations.forEach((accessory) => {
          accessoriesToPrintArray.push(accessory.accessoryHandle);
        });
      }
    });

    return accessoriesToPrintArray.reduce((a, b) => {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
  };

  const accessoriesToPrintArray = accessoriesToPrint();

  // did an accessory item recently expand/collapse (300ms)
  const [didAnimate, setDidAnimate] = useState(false);
  // is an accessory item currently expanding/collapsing
  const [isAnimating, setIsAnimating] = useState(false);
  // array to store state of which children accessories are currently expanded
  const [itemsExpandedArr, setItemsExpandedArr] = useState([]);
  // are there any accessory items currently expanded
  const [noItemsExpanded, setNoItemsExpanded] = useState(true);
  // did the last expanded accessory item collapse
  const [itemsCollapsed, setItemsCollapsed] = useState(false);
  // what is the translateY value for the AccessoriesContainer
  const [accessoriesContainerTranslateYValue, setAccessoriesContainerTranslateYValue] = useState(0);
  // bottom padding for the last accessory item
  const [lastItemBottomPadding, setLastItemBottomPadding] = useState(120);

  // effect to determine translate value of AccessoriesContainer
  useEffect(() => {
    accessoriesExpanded
      ? accessoriesBodyRowEl.current
        ? window.innerWidth > 768
          ? setAccessoriesContainerTranslateYValue(accessoriesBodyRowEl.current.offsetHeight)
          : setAccessoriesContainerTranslateYValue(accessoriesBodyRowEl.current.offsetHeight - 25)
        : setAccessoriesContainerTranslateYValue(0)
      : accessoriesHeaderRowEl.current
        ? setAccessoriesContainerTranslateYValue(accessoriesHeaderRowEl.current.offsetHeight)
        : setAccessoriesContainerTranslateYValue(0);
  }, [accessoriesExpanded, itemsCollapsed, itemsExpandedArr, didAnimate, isAnimating, noItemsExpanded]);

  // top padding of AccessoriesBodyRow
  // this enables the absolute positioning of AccessoriesHeaderRow
  const accessoriesBodyTopPadding = accessoriesHeaderRowEl.current
    ? accessoriesHeaderRowEl.current.offsetHeight
    : '100px';

  // functions for didAnimate and isAnimating to pass via props to the AccessoryItem children
  const handleAccessoryItemDidAnimate = () => {
    setDidAnimate(!didAnimate);
  };
  const handleAccessoryIsAnimating = (animating) => {
    if (animating) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  };

  // add property to accessory items
  // this will allow us to track their state in this component
  accessoriesData.allShopifyProduct.nodes = accessoriesData.allShopifyProduct.nodes.map((node) => {
    // eslint-disable-next-line no-param-reassign
    node.itemDetailsExpanded = false;
    return node;
  });

  // set initial state for accessory items being expanded
  useEffect(() => {
    if (accessoriesData.allShopifyProduct.nodes.length > 0) {
      const initialState = accessoriesData.allShopifyProduct.nodes.map((item) => item.itemDetailsExpanded);
      setItemsExpandedArr(initialState);
    }
  }, [accessoriesData]);

  // function to handle when an accessory item is expanded
  // this enables tracking state of the array children in parent component
  const handleItemExpand = (i) => {
    const newArr = [...itemsExpandedArr];
    newArr[i] = !newArr[i];
    setItemsExpandedArr(newArr);
  };

  // this is the same as handleItemExpand, except that it forces false on all accessory item children
  // to be used with the the cart of accessory container are collapsed
  const handleItemCollapse = () => {
    const newArr = [...itemsExpandedArr];

    for (let i = 0; i < newArr.length; i++) {
      newArr[i] = false;
    }

    setItemsExpandedArr(newArr);
  };

  // store state of whether there are any accessory items expanded
  useEffect(() => {
    if (itemsExpandedArr.some((item) => item === true)) {
      setNoItemsExpanded(false);
    } else {
      setNoItemsExpanded(true);
    }
  }, [itemsExpandedArr]);

  // noItemsExpanded changed from false to true
  useEffect(() => {
    if (noItemsExpanded === true) {
      setItemsCollapsed(true);
    }
    setTimeout(() => {
      setItemsCollapsed(false);
    }, 600);
  }, [noItemsExpanded]);

  // effect to set the bottom padding of the last accessory item
  useEffect(() => {
    if (noItemsExpanded) {
      setTimeout(() => {
        setLastItemBottomPadding(120);
      }, 200);
    } else {
      setLastItemBottomPadding(40);
    }
  }, [noItemsExpanded, accessoriesExpanded]);

  const accessoriesHeaderRowOnMouseEnter = () => {
    if (accessoriesExpanded) {
      setAccessoriesContainerTranslateYValue(accessoriesBodyRowEl.current.offsetHeight - 20);
    } else {
      setAccessoriesContainerTranslateYValue(accessoriesHeaderRowEl.current.offsetHeight + 20);
    }
  };

  const accessoriesHeaderRowOnMouseLeave = () => {
    if (accessoriesExpanded) {
      setAccessoriesContainerTranslateYValue(accessoriesBodyRowEl.current.offsetHeight);
    } else {
      setAccessoriesContainerTranslateYValue(accessoriesHeaderRowEl.current.offsetHeight);
    }
  };

  return (
    <AccessoriesContainer
      accessoriesExpanded={accessoriesExpanded}
      ref={accessoriesContainerEl}
      translateYValue={accessoriesContainerTranslateYValue}
      isAnimating={isAnimating}
      itemsCollapsed={itemsCollapsed}
    >
      <Col xs={8} sm={8} md={10} lg={12}>
        <AccessoriesHeaderRow
          ref={accessoriesHeaderRowEl}
          onMouseEnter={accessoriesHeaderRowOnMouseEnter}
          onMouseLeave={accessoriesHeaderRowOnMouseLeave}
        >
          <Col xsOffset={1} xs={6} md={8} lg={10}>
            <AccessoriesHeader onClick={handleAccessoriesClick}>
              <CompleteText>Add accessories</CompleteText>
              <ToggleText>{accessoriesExpanded ? 'Click to collapse' : 'Click to expand'}</ToggleText>
            </AccessoriesHeader>
          </Col>
        </AccessoriesHeaderRow>

        <AccessoriesBodyRow ref={accessoriesBodyRowEl} topPadding={accessoriesBodyTopPadding}>
          <Col xs={8} sm={8} md={10} lg={12}>
            {accessoriesData.allShopifyProduct.nodes
              .filter((node) => accessoriesToPrintArray.includes(node.handle))
              .map((accessory, i) => (
                <AccessoryItem
                  key={accessory.id}
                  handleItemExpand={handleItemExpand}
                  index={i}
                  itemArrLength={accessoriesToPrintArray.length}
                  accessoryData={accessory}
                  accessoriesExpanded={accessoriesExpanded}
                  handleAccessoryItemDidAnimate={handleAccessoryItemDidAnimate}
                  isAnimating={isAnimating}
                  handleAccessoryIsAnimating={handleAccessoryIsAnimating}
                  handleItemCollapse={handleItemCollapse}
                  lastItemBottomPadding={lastItemBottomPadding}
                />
              ))}
          </Col>
        </AccessoriesBodyRow>
      </Col>
    </AccessoriesContainer>
  );
});

Accessories.propTypes = {
  accessoriesExpanded: bool,
  handleAccessoriesClick: func,
  mainProductsInCart: array,
};
Accessories.defaultProps = {
  accessoriesExpanded: false,
  handleAccessoriesClick: '',
  mainProductsInCart: [],
};

export default Accessories;
