import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { bodyLItalicUtopiaStyles, bodyXlItalicUtopiaStyles, bodyXxxlItalicUtopiaStyles } from '../../styles/typography';
import { bpWidth, colors } from '../../styles/variables';

const SectionRow = styled(Row)`
  margin-bottom: 130px;

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 120px;
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 100px;
  }
`;

const LinkCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ withBg }) => (withBg ? colors.beige200 : undefined)};
  padding: 55px 0;
  margin-bottom: 70px;

  div {
    ${bodyXxxlItalicUtopiaStyles};
    margin-bottom: 40px;
  }

  @media (${bpWidth.desktopSm}) {
    padding: 72px 0;
    margin-bottom: 40px;

    div {
      ${bodyXlItalicUtopiaStyles};
      margin-bottom: 20px;
    }
  }

  @media (${bpWidth.tablet}) {
    padding: 55px 0;
    margin-bottom: 50px;
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 45px;

    div {
      ${bodyLItalicUtopiaStyles};
    }
  }
`;

const LargeImageWithTitleAndCta = ({ blok }) => (
  <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
    <SectionRow>
      {/* Image */}

      {/* Careers CTA */}
      <LinkCol xs={8} md={10} lg={12} withBg={blok.withBg}>
        <div>{blok.title}</div>
        {blok.cta?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </LinkCol>

      <Col xs={8} md={10} lg={12}>
        {blok.asset?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </Col>
    </SectionRow>
  </Col>
);

LargeImageWithTitleAndCta.propTypes = {
  blok: object.isRequired,
};

export default LargeImageWithTitleAndCta;
