import React, { useEffect, useState } from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const heightVariants = {
  none: '',
  small: '100px',
  normal: '200px',
  large: '300px',
  xlarge: '400px',
  xxlarge: '500px',
};

const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => (padding?.toString()?.length || 0 ? `${padding} 0` : undefined)};
`;
VideoWrapper.propTypes = {
  padding: PropTypes.string,
};

const VideoEl = styled.video`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: ${({ contentHeight }) => heightVariants[contentHeight]};
`;
VideoEl.propTypes = {
  contentHeight: PropTypes.string,
};

const Video = ({ blok }) => {
  const { padding, contentHeight, url, content } = blok;
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    setVideoUrl(url ? url.url : content.filename);
  }, []);

  return (
    <VideoWrapper {...storyblokEditable(blok)} padding={padding}>
      {videoUrl && (
        <VideoEl contentHeight={contentHeight} controls>
          <source src={videoUrl} type="video/mp4" />
        </VideoEl>
      )}
    </VideoWrapper>
  );
};

Video.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Video;
