import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Col, Row } from 'react-styled-flexboxgrid';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chevron from '../../../icons/arrow-DCerulean.svg';
import {
  bodyLinkStyles,
  bodyMBoldBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  bodySRegularUtopiaStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const FAQItemCol = styled(Col)`
  border-bottom: 1px solid ${colors.gray100};
  padding: 40px 0;
  user-select: none;

  svg {
    max-width: 13.31px;
    height: 6.65px;
    path#Path_2 {
      stroke: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      max-width: 9px;
      height: 4.5px;
    }
  }
  @media (${bpWidth.mobile}) {
    padding: 30px 0;
  }
`;

const FAQItemRow = styled(Row)`
  &:first-of-type ${FAQItemCol} {
    border-top: 1px solid ${colors.gray100};
  }
`;

const QuestionRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const Question = styled.h2`
  ${bodyMBoldBrownStyles};

  @media (${bpWidth.mobile}) {
    ${bodySBoldBrownStyles};
  }
`;

const Answer = styled.div`
  ${bodyMRegularUtopiaStyles};
  padding-top: 20px;

  i {
    font-style: italic;
  }

  p {
    padding-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    ${bodyLinkStyles};
  }

  &.nonItalicLink p a {
    font-style: normal;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};

    p {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
`;

const CTAArrowCol = styled(Row)``;

const CTAArrow = (isOpen) => (
  <Chevron
    className={isOpen ? 'ctaExpanded' : 'ctaCollapsed'}
    style={{
      transitionDuration: '.1s',
      transform: isOpen ? 'rotate(180deg)' : 'none',
    }}
  />
);

const Questions = ({ blok, isOpen, onToggleItem }) => {
  const { question, answer } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <FAQItemRow>
        <FAQItemCol xsOffset={1} mdOffset={2} lgOffset={3} xs={6} onClick={onToggleItem}>
          <QuestionRow>
            <Col xs={7} md={9} lg={11}>
              <Question>{question}</Question>
            </Col>
            <CTAArrowCol xs={1} md={1} lg={1}>
              {CTAArrow(isOpen)}
            </CTAArrowCol>
          </QuestionRow>
          <AnimateHeight duration={250} height={isOpen ? 'auto' : 0} animateOpacity>
            <Row>
              <Col xs={7} sm={7} md={7} lg={8}>
                <Answer>{renderRichTextReact(answer)}</Answer>
              </Col>
            </Row>
          </AnimateHeight>
        </FAQItemCol>
      </FAQItemRow>
    </Col>
  );
};

Questions.propTypes = {
  blok: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onToggleItem: PropTypes.func,
};

export default Questions;
