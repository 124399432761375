import React from 'react';
import { bool, string } from 'prop-types';
import styled, { css } from 'styled-components';
import { bpWidth, colors } from '../../styles/variables';

const transformDuration = '150ms';
const topDuration = '100ms';

const buildHamburger = (hitArea, lineWidth) => css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  height: ${hitArea}px;
  position: relative;
  width: ${lineWidth}px;

  &::before,
  &::after {
    background-color: currentColor;
    color: ${colors.beige100};
    content: '';
    display: block;
    height: 1.4px;
    position: absolute;
    transition: transform ${transformDuration} ease-in 0s;
    width: ${lineWidth}px;

    @media (${bpWidth.mobile}) {
      height: 1.2px;
    }
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  @media (${bpWidth.tablet}) {
    display: block;
  }

  ${(props) =>
    props.isX &&
    css`
      height: ${lineWidth}px;
      width: ${lineWidth}px;

      &::before,
      &::after {
        height: 1px;
        top: 50%;
        transition: transform ${transformDuration} ease-out ${topDuration};
      }

      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }

      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
    `}
`;

const Container = styled.button`
  outline: 0;
  ${buildHamburger(13, 33)}
`;

const Hamburger = (props) => {
  const { isX, color, ...rest } = props;
  return <Container isX={isX} aria-label="Navigation menu" fontColor={color} {...rest} />;
};

Hamburger.propTypes = {
  isX: bool,
  color: string,
};
Hamburger.defaultProps = {
  isX: false,
  color: colors.beige100,
};

export default Hamburger;
