import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import {
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  headerSStyles,
  headerXsStyles,
} from '../../styles/typography';
import { bpWidth } from '../../styles/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const CopyRow = styled(Row)`
  margin-bottom: 60px;

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 50px;
  }
`;

const Heading = styled.h3`
  ${headerSStyles};
  margin-bottom: 30px;

  @media (${bpWidth.desktopSm}) {
    ${headerXsStyles};
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  ${bodyLRegularUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodyMRegularUtopiaStyles};
  }
`;

const SingleColumnTitleTextAccordion = ({ blok }) => {
  const { heading, description, accordion } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <CopyRow>
        <Heading>{heading}</Heading>
        <Description>{renderRichTextReact(description)}</Description>
      </CopyRow>
      {accordion?.map((nestedBlok) => (
        <Row key={nestedBlok._uid}>
          <StoryblokComponent blok={nestedBlok} />
        </Row>
      ))}
    </Col>
  );
};

SingleColumnTitleTextAccordion.propTypes = {
  blok: object.isRequired,
};

export default SingleColumnTitleTextAccordion;
