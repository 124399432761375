import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Disqus } from 'gatsby-plugin-disqus';
import { string } from 'prop-types';
import styled from 'styled-components';
import { bpWidth } from '../../../styles/variables.js';

const PostCommentsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  & > *:last-child {
    margin-bottom: 0px;
  }

  // Aligning child elements
  & > * {
    width: 33.3%;
  }
  .mediumWidth {
    width: 50%;
  }
  .fullWidth {
    width: 100%;
  }

  @media (${bpWidth.desktopSm}) {
    & > * {
      width: 40%;
    }
    .mediumWidth {
      width: 60%;
    }
  }
  @media (${bpWidth.tablet}) {
    & > * {
      width: 75%;
    }
    .mediumWidth {
      width: 75%;
    }
  }
`;

function PostComments(props) {
  const { postUrl, postTitle } = props;

  return (
    <Row>
      <PostCommentsCol xs={8} md={10} lg={12}>
        <Disqus
          config={{
            url: `${postUrl}`,
            title: `${postTitle}`,
          }}
        />
      </PostCommentsCol>
    </Row>
  );
}

PostComments.propTypes = {
  postUrl: string,
  postTitle: string,
};
PostComments.defaultProps = {
  postUrl: '',
  postTitle: '',
};

export default PostComments;
