import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoint } from '../../../constants/breakpoint';
import { useViewport } from '../../../context/viewport.context';
import { bodyXxsRegularBrownStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { mapColWidthProperties, mapSpaceClasses } from '../../../utils/helper';
import { renderPlainText, renderRichTextReact } from '../../../utils/storyblokRichText';
import { FadeInOnView } from '../../Animation/FadeInOnView';

const MediaRow = styled(Row)`
  text-align: ${({ align }) => align};
  height: 100%;
`;

const MediaWrapper = styled.div``;

const Caption = styled.div`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.gray400};
  margin-top: 15px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 10px;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`;

const NonFadeInOnView = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
`;

const Media = ({ blok }) => {
  const colWidth = mapColWidthProperties(blok.config);
  const { paddingClasses, marginClasses } = mapSpaceClasses(blok.config);
  const classes = ['media-wrapper', ...paddingClasses, ...marginClasses, ...blok.customClass.split(' ')];
  /** @type {GetGatsbyImageOptions} */
  let options = {
    layout: blok.layout,
    quality: blok.quality,
  };

  const [defaultMediaUrl, setDefaultMediaUrl] = useState('');
  /** @type {IGatsbyImageData} */
  const defaultImages = null;
  const [images, setImages] = useState(defaultImages);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [richTextCaption, setRichTextCaption] = useState(null);
  const [plainCaption, setPlainCaption] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [isFadeInOnView, setIsFadeInOnView] = useState(false);

  const videoRef = useRef(null);
  const { viewWidth } = useViewport();

  const InViewContainer = useMemo(() => (isFadeInOnView ? FadeInOnView : NonFadeInOnView));

  useEffect(() => {
    let blokMediaType = blok.mediaType;
    if (!blokMediaType) {
      blokMediaType = 'image';
    }
    setMediaType(blokMediaType);

    let mediaUrl = blok.assetXs?.filename || blok.assetSm?.filename || blok.assetMd?.filename || blok.assetLg?.filename;
    let mediaWidth = blok.widthXs || blok.widthSm || blok.widthMd || blok.widthLg || '100%';
    let mediaHeight = blok.heightXs || blok.heightSm || blok.heightMd || blok.heightLg || '100%';

    if (blok.assetXs?.filename) {
      mediaUrl = blok.assetXs.filename;
    }
    if (blok.widthXs) {
      mediaWidth = blok.widthXs;
    }
    if (blok.heightXs) {
      mediaHeight = blok.heightXs;
    }

    if (viewWidth >= breakpoint.sm) {
      if (blok.assetSm?.filename) {
        mediaUrl = blok.assetSm.filename;
      }
      if (blok.widthSm) {
        mediaWidth = blok.widthSm;
      }
      if (blok.heightSm) {
        mediaHeight = blok.heightSm;
      }
    }
    if (viewWidth >= breakpoint.md) {
      if (blok.assetMd?.filename) {
        mediaUrl = blok.assetMd.filename;
      }
      if (blok.widthMd) {
        mediaWidth = blok.widthMd;
      }
      if (blok.heightMd) {
        mediaHeight = blok.heightMd;
      }
    }
    if (viewWidth >= breakpoint.lg) {
      if (blok.assetLg?.filename) {
        mediaUrl = blok.assetLg.filename;
      }
      if (blok.widthLg) {
        mediaWidth = blok.widthLg;
      }
      if (blok.heightLg) {
        mediaHeight = blok.heightLg;
      }
    }

    setDefaultMediaUrl(mediaUrl);
    setWidth(mediaWidth);
    setHeight(mediaHeight);
    setRichTextCaption(renderRichTextReact(blok.caption));
    setPlainCaption(renderPlainText(blok.caption));
    setIsFadeInOnView(blok.fadeInOnView);

    if (blokMediaType === 'image' && mediaUrl && !mediaUrl.endsWith('.svg')) {
      const artDirected = [];
      if (blok.assetXs?.filename) {
        options = {
          ...options,
          width: 414,
        };
        artDirected.push({
          media: `(${bpWidth.mobile})`,
          image: getGatsbyImage(blok.assetXs.filename, options),
        });
      }

      if (blok.assetSm?.filename) {
        options = {
          ...options,
          width: 768,
        };
        artDirected.push({
          media: `(${bpWidth.tablet})`,
          image: getGatsbyImage(blok.assetSm.filename, options),
        });
      }

      if (blok.assetMd?.filename) {
        options = {
          ...options,
          width: 1280,
        };
        artDirected.push({
          media: `(${bpWidth.desktopSm})`,
          image: getGatsbyImage(blok.assetMd.filename, options),
        });
      }

      options = {
        ...options,
        width: 1920,
      };

      const imageData = withArtDirection(getGatsbyImage(mediaUrl, options), artDirected);
      setImages(imageData);
    }
  }, [blok, viewWidth]);

  // Determining video height, so it does not have black boarders on smaller screens
  useEffect(() => {
    if (mediaType === 'video' && videoRef.current) {
      // YouTube video width to height ratio 16:9 ~ 1.78
      const youtubeVideoEl = videoRef.current;
      setTimeout(() => {
        const videoOffsetWidth = youtubeVideoEl.offsetWidth;
        setHeight(videoOffsetWidth / (16 / 9));
      }, 1000);
    }
  }, [videoRef, mediaType, viewWidth]);

  if (defaultMediaUrl) {
    if (mediaType === 'video') {
      const video = (
        <iframe
          ref={videoRef}
          width="100%"
          height={height}
          src={defaultMediaUrl}
          title={plainCaption}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
        />
      );

      return (
        <Col {...storyblokEditable(blok)} {...colWidth} className="media-col">
          <MediaRow align={blok.align}>
            <Col xs={8} md={10} lg={12} style={{ height: '100%' }}>
              <MediaWrapper className={classes.join(' ')}>{video}</MediaWrapper>
            </Col>
          </MediaRow>
        </Col>
      );
    }

    if (defaultMediaUrl.endsWith('.svg')) {
      return (
        <Col {...storyblokEditable(blok)} {...colWidth} className="media-col">
          <MediaRow align={blok.align}>
            <Col xs={8} md={10} lg={12} style={{ height: '100%' }}>
              <InViewContainer>
                <MediaWrapper className={classes.join(' ')}>
                  <img src={defaultMediaUrl} alt={blok.altText || plainCaption || ''} width={width} height={height} />
                  {richTextCaption && <Caption>{richTextCaption}</Caption>}
                </MediaWrapper>
              </InViewContainer>
            </Col>
          </MediaRow>
        </Col>
      );
    }

    return (
      images && (
        <Col {...storyblokEditable(blok)} {...colWidth} className="media-col">
          <MediaRow align={blok.align}>
            <Col xs={8} md={10} lg={12} style={{ height: '100%' }}>
              <InViewContainer>
                <MediaWrapper className={classes.join(' ')}>
                  <StyledGatsbyImage
                    image={images}
                    alt={blok.altText || plainCaption || ''}
                    loading={blok.loadType || 'lazy'}
                    objectPosition={blok.objectPosition}
                    width={width}
                    height={height}
                  />
                </MediaWrapper>
              </InViewContainer>
            </Col>
            <Col xsOffset={1} xs={6} smOffset={0} sm={8} md={10} lg={12}>
              <InViewContainer>{richTextCaption && <Caption>{richTextCaption}</Caption>}</InViewContainer>
            </Col>
          </MediaRow>
        </Col>
      )
    );
  }

  return undefined;
};

Media.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Media;
