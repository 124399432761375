import React from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { useStoryblokStory } from '../../../hooks';

const BusinessFooterWrapper = () => {
  const { storyblokStoryData } = useStoryblokStory('business/footer');

  return storyblokStoryData?.content && <StoryblokComponent blok={storyblokStoryData.content} />;
};

export default BusinessFooterWrapper;
