import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../context/viewport.context';
import {
  bodyLRegularBrownStyles,
  bodyLRegularUtopiaStyles,
  bodyLSemiBoldUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularBrownStyles,
  bodySRegularUtopiaStyles,
  bodySSemiBoldUtopiaStyles,
  bodyXlRegularUtopiaStyles,
  headerLFillStyles,
  headerMStyles,
  headerSStyles,
  headerXlFillStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../styles/typography';
import { bpWidth, bpWidthTailwind, colors } from '../../styles/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';
import { FadeInOnView } from '../Animation/FadeInOnView';
import EmbeddedEventTime from './singleElements/EmbeddedEventTime';

const ContentCol = styled(Col)`
  @media (${bpWidthTailwind.tablet}) {
    &.business-solutions {
      position: relative;
      height: 236px;
    }
  }

  @media (${bpWidthTailwind.desktopSm}) {
    &.business-solutions {
      height: 351px;
    }
  }

  @media (${bpWidthTailwind.desktopLg}) {
    &.business-solutions {
      height: 420px;
    }
  }
`;

const ContentRow = styled(Row)`
  text-align: ${({ textAlign }) => textAlign};

  &.how-it-works-top {
    .single-column-title-text-cta-component--header {
      ${headerMStyles};
    }
  }

  &.business-solutions {
    margin: 56px 0 84px;

    .single-column-title-text-cta-component--header {
      ${headerSStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    }
    .single-column-title-text-cta-component--body-col {
      margin: 30px 0 0;
    }
    .single-column-title-text-cta-component--body {
      ${bodyMBoldBrownStyles};
      color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
    }
    .single-column-title-text-cta-component--cta-col {
      margin: 30px 0 0;
    }
    .button-component--container a {
      min-width: 200px;
      min-height: 36px;
      padding: 7.3px 20px;
      border-radius: 18px;
      font-size: 12px;
      line-height: 1.7;
    }
  }

  &.business-get-started {
    .single-column-title-text-cta-component--header {
      ${headerXsStyles};
      color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
    }
    .single-column-title-text-cta-component--cta-col {
      margin: 30px 0 0;
    }
    .button-component--container a {
      min-width: 150px;
      min-height: 36px;
      padding: 7.3px 20px;
      border-radius: 18px;
      font-size: 12px;
      line-height: 1.7;
    }
  }

  &.business-events {
    .single-column-title-text-cta-component--body {
      ${bodySRegularBrownStyles};
      color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};

      .embedded-event-time {
        ${bodySRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
        letter-spacing: -0.19px;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (${bpWidthTailwind.tablet}) {
    &.how-it-works-top {
      .single-column-title-text-cta-component--header {
        ${headerLFillStyles};
      }
    }

    &.business-solutions {
      position: absolute;
      top: ${({ headerHeight }) => (headerHeight ? `${headerHeight * -1}px` : undefined)};
      margin: 0;
      max-width: 23.4rem;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);

      .single-column-title-text-cta-component--header-col {
        margin: 0 0 17px;
      }
      .single-column-title-text-cta-component--header {
        ${headerSStyles};
        color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
        padding: 0 20px;
      }
      .single-column-title-text-cta-component--body-col {
        margin: 28px 0 0;
      }
      .single-column-title-text-cta-component--body {
        ${headerXxsStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .single-column-title-text-cta-component--cta-col {
        margin: 30px 0 0;
      }
      .button-component--container a {
        min-width: 202px;
        padding: 7.3px 20px;
        border-radius: 18px;
        font-size: 12px;
        line-height: 1.7;
      }
    }

    &.business-get-started {
      .single-column-title-text-cta-component--cta-col {
        margin: 40px 0 0;
      }
      .button-component--container a {
        min-width: 202px;
        padding: 7.3px 20px;
        border-radius: 18px;
        font-size: 12px;
        line-height: 1.7;
      }
    }

    &.business-events {
      .single-column-title-text-cta-component--body {
        ${bodyMRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};

        p {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (${bpWidthTailwind.desktopSm}) {
    &.how-it-works-top {
      .single-column-title-text-cta-component--header {
        ${headerXlFillStyles};
        max-width: 40rem;
      }
    }

    &.business-solutions {
      max-width: 43.2rem;

      .single-column-title-text-cta-component--header-col {
        margin: 0 0 23px;
      }
      .single-column-title-text-cta-component--header {
        ${headerLFillStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
        padding: 0 20px;
      }
      .single-column-title-text-cta-component--body-col {
        margin: 34px 0 0;
      }
      .single-column-title-text-cta-component--body {
        ${headerSStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .single-column-title-text-cta-component--cta-col {
        margin: 40px 0 0;
      }
      .button-component--container a {
        min-width: 300px;
        min-height: 44px;
        padding: 8.7px 42.5px;
        border-radius: 20px;
        font-size: 16px;
        line-height: 1.6;
      }
    }

    &.business-get-started {
      .single-column-title-text-cta-component--header {
        ${headerSStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .button-component--container a {
        min-width: 202px;
        padding: 7.3px 20px;
        border-radius: 18px;
        font-size: 12px;
        line-height: 1.7;
      }
    }
  }

  @media (${bpWidthTailwind.desktopLg}) {
    &.how-it-works-top {
      .single-column-title-text-cta-component--header {
        ${headerXlFillStyles};
        max-width: 39rem;
      }
    }

    &.business-solutions {
      max-width: 48.7rem;

      .single-column-title-text-cta-component--header-col {
        margin: 0 0 80px;
      }
      .single-column-title-text-cta-component--header {
        ${headerXlFillStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .single-column-title-text-cta-component--body-col {
        margin: 43px 0 0;
      }
      .single-column-title-text-cta-component--body {
        ${headerMStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .single-column-title-text-cta-component--cta-col {
        margin: 60px 0 0;
      }
    }

    &.business-get-started {
      .single-column-title-text-cta-component--header {
        ${headerMStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};
      }
      .single-column-title-text-cta-component--cta-col {
        margin: 50px 0 0;
      }
      .button-component--container a {
        min-width: 300px;
        min-height: 40px;
        padding: 7.7px 20px;
        border-radius: 20px;
        font-size: 16px;
        line-height: 1.6;
      }
    }

    &.business-events {
      .single-column-title-text-cta-component--body {
        ${bodyLRegularBrownStyles};
        color: ${({ darkMode }) => (darkMode ? colors.white : undefined)};

        p {
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const Heading = styled.div`
  ${({ darkMode }) => (darkMode ? headerLFillStyles : headerXlFillStyles)};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  p {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (${bpWidth.desktopSm}) {
    ${headerLFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidth.tablet}) {
    ${headerMStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    &.how-it-works-do-for-you {
      font-size: 45px;
    }
  }

  @media (${bpWidth.mobile}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    &.how-it-works-do-for-you {
      font-size: 22px;
    }

    &.how-it-works-video {
      font-size: 20px;
    }
  }
`;

const BodyCol = styled(Col)`
  margin: 30px 0 0;

  @media (${bpWidth.tablet}) {
    margin: 20px 0 0;
  }
`;

const Body = styled.div`
  ${({ darkMode }) => (darkMode ? bodyLSemiBoldUtopiaStyles : bodyLRegularUtopiaStyles)};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  .how-it-works-one-touch {
    ${bodyXlRegularUtopiaStyles};
  }

  p {
    margin-bottom: 25px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.how-it-works-do-for-you {
    font-size: 23px;
  }

  @media (${bpWidth.desktopSm}) {
    ${({ darkMode }) => (darkMode ? undefined : bodyMRegularUtopiaStyles)};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    .how-it-works-one-touch {
      ${bodyXlRegularUtopiaStyles};
    }

    &.how-it-works-do-for-you {
      font-size: 23px;
    }
  }

  @media (${bpWidth.tablet}) {
    ${({ darkMode }) => (darkMode ? bodySSemiBoldUtopiaStyles : undefined)};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    .how-it-works-one-touch {
      ${bodyXlRegularUtopiaStyles};
    }

    ul {
      column-count: 1;
    }

    &.how-it-works-do-for-you {
      font-size: 23px;
    }
  }

  @media (${bpWidth.mobile}) {
    ${({ darkMode }) => (darkMode ? undefined : bodySRegularUtopiaStyles)};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    &.how-it-works-do-for-you {
      font-size: inherit;
    }
  }
`;

const SingleColumnTitleTextCta = ({ blok }) => {
  const { viewWidth } = useViewport();
  const { heading, body, cta, darkMode = false, textAlign, cssClass } = blok;
  const [headingRichText, setHeadingRichText] = useState(null);
  const [bodyRichText, setBodyRichText] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerColRef = useRef(null);

  const handleEmbeddedVideo = (embeddedEventTime) => <EmbeddedEventTime blok={embeddedEventTime} />;

  /** @type {RenderOptions} */
  const renderOptions = {
    blokResolvers: {
      embeddedEventTime: handleEmbeddedVideo,
    },
  };

  useEffect(() => {
    setHeadingRichText(renderRichTextReact(heading, renderOptions));
  }, [heading]);

  useEffect(() => {
    setBodyRichText(renderRichTextReact(body, renderOptions));
  }, [body]);

  useEffect(() => {
    setTimeout(() => {
      const headerCol = headerColRef.current;
      if (headerCol) {
        let { height } = headerCol.getBoundingClientRect();
        const styles = getComputedStyle(headerCol);
        height = ['top', 'bottom']
          .map((side) => parseInt(styles['margin-' + side], 10))
          .reduce((total, side) => total + side, height);
        setHeaderHeight(height);
      }
    }, 100);
  }, [headerColRef, headingRichText, viewWidth]);

  return (
    <ContentCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={cssClass}>
      <ContentRow textAlign={textAlign} darkMode={darkMode} headerHeight={headerHeight} className={cssClass}>
        {headingRichText && (
          <Col ref={headerColRef} xs={8} md={10} lg={12} className="single-column-title-text-cta-component--header-col">
            <FadeInOnView>
              <Heading darkMode={darkMode} className="single-column-title-text-cta-component--header">
                {headingRichText}
              </Heading>
            </FadeInOnView>
          </Col>
        )}
        {bodyRichText && (
          <BodyCol xs={8} md={10} lg={12} className="single-column-title-text-cta-component--body-col">
            <FadeInOnView>
              <Body darkMode={darkMode} className="single-column-title-text-cta-component--body">
                {bodyRichText}
              </Body>
            </FadeInOnView>
          </BodyCol>
        )}
        {cta.length > 0 && (
          <Col xs={8} md={10} lg={12} className="single-column-title-text-cta-component--cta-col">
            <FadeInOnView>
              {cta?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </FadeInOnView>
          </Col>
        )}
      </ContentRow>
    </ContentCol>
  );
};

SingleColumnTitleTextCta.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default SingleColumnTitleTextCta;
