import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { array, bool, func } from 'prop-types';
import styled from 'styled-components';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import {
  bodyLRegularBrownStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxlRegularBrownStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import Button from '../../Button/Button';
import MenuFooter from './BusinessMenuFooter';

const MenuContainerWrapperRow = styled(Row)`
  background-color: ${colors.navy600};
  height: 100%;
  overflow: scroll;
`;

const MenuContainerCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px;

  a {
    color: ${colors.beige100};
    text-decoration: none;
    width: fit-content;

    &:hover {
      color: ${colors.gray400};
    }
  }
`;

const PrimaryDiv = styled.div`
  ${bodyXxlRegularBrownStyles};
  color: ${colors.beige100};
  display: flex;
  flex-direction: column;

  > div {
    padding-bottom: 20px;
  }

  a {
    ${bodyXxlRegularBrownStyles};
    color: ${colors.beige100};
  }

  @media (${bpWidth.mobile}) {
    > div {
      padding-bottom: 15px;
    }

    a {
      ${bodyLRegularBrownStyles};
      color: ${colors.beige100};
    }
  }
`;

const SecondaryDiv = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding-bottom: 15px;
  }

  a {
    ${bodySRegularBrownStyles};
    color: ${colors.beige100};
  }

  @media (${bpWidth.mobile}) {
    > div {
      padding-bottom: 10px;
    }

    a {
      ${bodyXsRegularBrownStyles};
      color: ${colors.beige100};
    }
  }
`;

const CtaDiv = styled.div`
  margin: 25px 0;
`;

const Menu = (props) => {
  const { isOpen, onHamburgerClick, onContactUsClick, includeContactUsCta, primaryLinks } = props;
  const { footerBlok } = useBusinessAppState();
  const { navigationLinks: secondaryLinks } = footerBlok;

  return (
    <MenuContainerWrapperRow isOpen={isOpen}>
      <MenuContainerCol xs={8}>
        <Row>
          <Col xsOffset={1} xs={6}>
            <PrimaryDiv>
              {primaryLinks.map((nestedBlok) => (
                <div role="presentation" key={nestedBlok._uid} onClick={isOpen ? onHamburgerClick : undefined}>
                  <StoryblokComponent blok={nestedBlok} />
                </div>
              ))}
            </PrimaryDiv>

            <SecondaryDiv>
              {secondaryLinks.map((nestedBlok) => (
                <div role="presentation" key={nestedBlok._uid} onClick={isOpen ? onHamburgerClick : undefined}>
                  <StoryblokComponent blok={nestedBlok} />
                </div>
              ))}
            </SecondaryDiv>

            <CtaDiv>
              {includeContactUsCta && (
                <Button text="CONTACT US" className="menuContactUsCta" onClick={onContactUsClick} />
              )}
            </CtaDiv>
          </Col>
        </Row>
        <MenuFooter />
      </MenuContainerCol>
    </MenuContainerWrapperRow>
  );
};

Menu.propTypes = {
  isOpen: bool,
  onHamburgerClick: func,
  onContactUsClick: func,
  includeContactUsCta: bool,
  primaryLinks: array,
};
Menu.defaultProps = {
  isOpen: false,
  onHamburgerClick: '',
  onContactUsClick: '',
  includeContactUsCta: false,
  primaryLinks: [],
};

export default Menu;
