/* eslint-disable no-param-reassign */
import { isObject } from 'lodash';

export const isSSR = typeof window === 'undefined';
export const isBrowser = !isSSR;

function calculateScrollOffset(elem, additionalOffset, alignment) {
  const { body, documentElement: html } = document;

  const elemRect = elem.getBoundingClientRect();
  const { clientHeight } = html;
  const documentHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );

  additionalOffset = additionalOffset || 0;

  let scrollPosition;
  if (alignment === 'bottom') {
    scrollPosition = elemRect.bottom - clientHeight;
  } else if (alignment === 'middle') {
    scrollPosition = elemRect.bottom - clientHeight / 2 - elemRect.height / 2;
  } else {
    // top and default
    scrollPosition = elemRect.top;
  }

  const maxScrollPosition = documentHeight - clientHeight;
  return Math.min(scrollPosition + additionalOffset + window.scrollY, maxScrollPosition);
}

function scrollTo(x, y, options) {
  window.scrollTo({
    behavior: 'smooth',
    ...options,
    left: x,
    top: y,
  });
}

export function scrollToElement(elem, options = { offset: 0 }) {
  options = options || {};
  if (typeof elem === 'string') {
    elem = document.querySelector(elem);
  }

  if (elem) {
    scrollTo(0, calculateScrollOffset(elem, options.offset, options.align), options);
  }
}

export function scroller(target, offset = 0) {
  scrollToElement(target, {
    offset,
  });
}

export function checkHash(location, offset) {
  const { hash } = location;
  const selector = hash ? hash.substr(1) : null;
  const validElement = selector ? document.getElementById(selector) : null;
  if (hash && validElement) {
    scroller(hash, offset);
  }
}

export function on(obj, ...args) {
  obj.addEventListener(...args);
}

export function off(obj, ...args) {
  obj.removeEventListener(...args);
}

/**
 * @param {any} value
 * @return {boolean}
 */
export function isEmpty(value) {
  return value == null || (typeof value === 'string' && value.trim().length === 0);
}

/**
 * @param {any} obj
 * @return {boolean}
 */
function isObjEmpty(obj) {
  return !obj || Object.keys(obj).length === 0;
}

/**
 * @param {any} value
 * @return {number | any}
 */
export function tryConvertToNumber(value) {
  const newValue = Number(value);
  if (typeof newValue === 'number' && !Number.isNaN(newValue)) {
    return newValue;
  }

  return value;
}

/**
 * @param {any} value
 * @return {boolean | any}
 */
export function tryConvertToBoolean(value) {
  if (typeof value === 'string') {
    if (value?.trim()?.toLowerCase() === 'true') {
      return true;
    }
    if (value?.trim()?.toLowerCase() === 'false') {
      return false;
    }
  }

  return value;
}

/**
 * @typedef ColWidth
 * @property {number|boolean} [xsOffset]
 * @property {number|boolean} [xs]
 * @property {number|boolean} [smOffset]
 * @property {number|boolean} [sm]
 * @property {number|boolean} [mdOffset]
 * @property {number|boolean} [md]
 * @property {number|boolean} [lgOffset]
 * @property {number|boolean} [lg]
 */

/**
 * @param {any[]} [config=[]]
 * @return {ColWidth}
 */
export function mapColWidthProperties(config = []) {
  /** @type {ColWidth} */
  const colWidthBlok = config?.find((configBlok) => configBlok.component === 'colWidth');

  /** @type {ColWidth} */
  let colWidth = {};

  if (colWidthBlok) {
    const { lg, lgOffset, md, mdOffset, sm, smOffset, xs, xsOffset } = colWidthBlok;

    if (!isEmpty(xsOffset)) {
      let newValue = tryConvertToNumber(xsOffset);
      newValue = tryConvertToBoolean(newValue);
      colWidth.xsOffset = newValue;
    }
    if (!isEmpty(xs)) {
      let newValue = tryConvertToNumber(xs);
      newValue = tryConvertToBoolean(newValue);
      colWidth.xs = newValue;
    }
    if (!isEmpty(smOffset)) {
      let newValue = tryConvertToNumber(smOffset);
      newValue = tryConvertToBoolean(newValue);
      colWidth.smOffset = newValue;
    }
    if (!isEmpty(sm)) {
      let newValue = tryConvertToNumber(sm);
      newValue = tryConvertToBoolean(newValue);
      colWidth.sm = newValue;
    }
    if (!isEmpty(mdOffset)) {
      let newValue = tryConvertToNumber(mdOffset);
      newValue = tryConvertToBoolean(newValue);
      colWidth.mdOffset = newValue;
    }
    if (!isEmpty(md)) {
      let newValue = tryConvertToNumber(md);
      newValue = tryConvertToBoolean(newValue);
      colWidth.md = newValue;
    }
    if (!isEmpty(lgOffset)) {
      let newValue = tryConvertToNumber(lgOffset);
      newValue = tryConvertToBoolean(newValue);
      colWidth.lgOffset = newValue;
    }
    if (!isEmpty(lg)) {
      let newValue = tryConvertToNumber(lg);
      newValue = tryConvertToBoolean(newValue);
      colWidth.lg = newValue;
    }
  }

  // fallback as fullwidth
  if (isObjEmpty(colWidth)) {
    colWidth = {
      lg: 12,
      md: 10,
      xs: 8,
    };
  }

  return colWidth;
}

const spaceTypes = {
  padding: 'p',
  margin: 'm',
};

/**
 * @param {string} spaceType
 * @param {any} spaceBlok
 * @return {any[]}
 */
function handleMapSpaceClasses(spaceType, spaceBlok) {
  const classes = [];

  let defaultTop;
  if (spaceBlok.top || spaceBlok.top === 0) {
    defaultTop = spaceBlok.top;
  } else if (spaceBlok.smallDesktopTop || spaceBlok.smallDesktopTop === 0) {
    defaultTop = spaceBlok.smallDesktopTop;
  } else if (spaceBlok.tabletTop || spaceBlok.tabletTop === 0) {
    defaultTop = spaceBlok.tabletTop;
  } else if (spaceBlok.mobileTop || spaceBlok.mobileTop === 0) {
    defaultTop = spaceBlok.mobileTop;
  }
  if (defaultTop || defaultTop === 0) {
    classes.push(`${spaceType}t-[${defaultTop}px]`);
  }

  if (spaceBlok.top) {
    classes.push(`lg:${spaceType}t-[${spaceBlok.top}px]`);
  }
  if (spaceBlok.smallDesktopTop) {
    classes.push(`md:${spaceType}t-[${spaceBlok.smallDesktopTop}px]`);
  }
  if (spaceBlok.tabletTop) {
    classes.push(`sm:${spaceType}t-[${spaceBlok.tabletTop}px]`);
  }
  if (spaceBlok.mobileTop) {
    classes.push(`[@media(max-width:414px)]:${spaceType}t-[${spaceBlok.mobileTop}px]`);
  }

  let defaultRight;
  if (spaceBlok.right || spaceBlok.right === 0) {
    defaultRight = spaceBlok.right;
  } else if (spaceBlok.smallDesktopRight || spaceBlok.smallDesktopRight === 0) {
    defaultRight = spaceBlok.smallDesktopRight;
  } else if (spaceBlok.tabletRight || spaceBlok.tabletRight === 0) {
    defaultRight = spaceBlok.tabletRight;
  } else if (spaceBlok.mobileRight || spaceBlok.mobileRight === 0) {
    defaultRight = spaceBlok.mobileRight;
  }
  if (defaultRight || defaultRight === 0) {
    classes.push(`${spaceType}r-[${defaultRight}px]`);
  }

  if (spaceBlok.right) {
    classes.push(`lg:${spaceType}r-[${spaceBlok.right}px]`);
  }
  if (spaceBlok.smallDesktopRight) {
    classes.push(`md:${spaceType}r-[${spaceBlok.smallDesktopRight}px]`);
  }
  if (spaceBlok.tabletRight) {
    classes.push(`sm:${spaceType}r-[${spaceBlok.tabletRight}px]`);
  }
  if (spaceBlok.mobileRight) {
    classes.push(`[@media(max-width:414px)]:${spaceType}r-[${spaceBlok.mobileRight}px]`);
  }

  let defaultBottom;
  if (spaceBlok.bottom || spaceBlok.bottom === 0) {
    defaultBottom = spaceBlok.bottom;
  } else if (spaceBlok.smallDesktopBottom || spaceBlok.smallDesktopBottom === 0) {
    defaultBottom = spaceBlok.smallDesktopBottom;
  } else if (spaceBlok.tabletBottom || spaceBlok.tabletBottom === 0) {
    defaultBottom = spaceBlok.tabletBottom;
  } else if (spaceBlok.mobileBottom || spaceBlok.mobileBottom === 0) {
    defaultBottom = spaceBlok.mobileBottom;
  }
  if (defaultBottom || defaultBottom === 0) {
    classes.push(`${spaceType}b-[${defaultBottom}px]`);
  }

  if (spaceBlok.bottom) {
    classes.push(`lg:${spaceType}b-[${spaceBlok.bottom}px]`);
  }
  if (spaceBlok.smallDesktopBottom) {
    classes.push(`md:${spaceType}b-[${spaceBlok.smallDesktopBottom}px]`);
  }
  if (spaceBlok.tabletBottom) {
    classes.push(`sm:${spaceType}b-[${spaceBlok.tabletBottom}px]`);
  }
  if (spaceBlok.mobileBottom) {
    classes.push(`[@media(max-width:414px)]:${spaceType}b-[${spaceBlok.mobileBottom}px]`);
  }

  let defaultLeft;
  if (spaceBlok.left || spaceBlok.left === 0) {
    defaultLeft = spaceBlok.left;
  } else if (spaceBlok.smallDesktopLeft || spaceBlok.smallDesktopLeft === 0) {
    defaultLeft = spaceBlok.smallDesktopLeft;
  } else if (spaceBlok.tabletLeft || spaceBlok.tabletLeft === 0) {
    defaultLeft = spaceBlok.tabletLeft;
  } else if (spaceBlok.mobileLeft || spaceBlok.mobileLeft === 0) {
    defaultLeft = spaceBlok.mobileLeft;
  }
  if (defaultLeft || defaultLeft === 0) {
    classes.push(`${spaceType}l-[${defaultLeft}px]`);
  }

  if (spaceBlok.left) {
    classes.push(`lg:${spaceType}l-[${spaceBlok.left}px]`);
  }
  if (spaceBlok.smallDesktopLeft) {
    classes.push(`md:${spaceType}l-[${spaceBlok.smallDesktopLeft}px]`);
  }
  if (spaceBlok.tabletLeft) {
    classes.push(`sm:${spaceType}l-[${spaceBlok.tabletLeft}px]`);
  }
  if (spaceBlok.mobileLeft) {
    classes.push(`[@media(max-width:414px)]:${spaceType}l-[${spaceBlok.mobileLeft}px]`);
  }

  return classes;
}

/**
 * @param {any[]} [config=[]]
 * @return {{marginClasses: any[], paddingClasses: any[]}}
 */
export function mapSpaceClasses(config = []) {
  const paddingBlok = config?.find((configBlok) => configBlok.component === 'padding');
  let paddingClasses = [];
  if (paddingBlok) {
    paddingClasses = handleMapSpaceClasses(spaceTypes.padding, paddingBlok);
  }

  const marginBlok = config?.find((configBlok) => configBlok.component === 'margin');
  let marginClasses = [];
  if (marginBlok) {
    marginClasses = handleMapSpaceClasses(spaceTypes.margin, marginBlok);
  }

  return {
    paddingClasses,
    marginClasses,
  };
}

/**
 * Update the media url for svg for using webkit-mask-image with known-issue.
 * To get around that issue if you still need to use JavaScript to set your image
 * you can work around it using `https://s3.amazonaws.com/a.storyblok.com` instead of `https://a.storyblok.com`.
 * @see https://www.storyblok.com/faq/xmlhttprequest-cannot-load-no-access-control-allow-origin-header
 * @param {string} url
 */
export function updateStoryblokMediaUrl(url) {
  const [, svgUrl] = url.split('https://');
  return ['https://s3.amazonaws.com/', svgUrl].join('');
}

/**
 * @param {*} object
 * @param {string} keyToMatch
 * @param {string} valueToMatch
 * @return {*|null}
 */
export function findNestedObject(object = {}, keyToMatch = '', valueToMatch = '') {
  if (isObject(object)) {
    const entries = Object.entries(object);

    for (let i = 0; i < entries.length; i += 1) {
      const [objectKey, objectValue] = entries[i];

      if (objectKey === keyToMatch && objectValue === valueToMatch) {
        return object;
      }

      if (isObject(objectValue)) {
        const child = findNestedObject(objectValue, keyToMatch, valueToMatch);

        if (child !== null) {
          return child;
        }
      }
    }
  }

  return null;
}

export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export function isObjectLike(value) {
  // eslint-disable-next-line eqeqeq
  return value != null && typeof value == 'object';
}

export function isBoolean(value) {
  return value === true || value === false || (isObjectLike(value) && toString.call(value) === '[object Boolean]');
}
