import _ from 'lodash';

// transform cart.lines into a legacy Shopify lineItem shape
export const legacyLineItems = (lines) => {
  if (_.isEmpty(lines.edges)) {
    return [];
  }

  // transform cart lines to legacy cart shape
  // see WEBDEV-915
  return lines?.edges?.map((edge) => {
    const { node } = edge;

    return {
      quantity: node?.quantity,
      title: node?.merchandise?.product?.title,
      id: node?.id, // Shopify cart line ID
      variant: {
        id: node?.merchandise?.id,
        image: {
          src: node?.merchandise?.image?.url,
        },
        priceV2: {
          amount: node?.merchandise?.price?.amount,
          currencyCode: node?.merchandise?.price?.currencyCode,
        },
        title: node?.merchandise?.title,
      },
    };
  });
};
