import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import { eyebrowSStyles, eyebrowXsStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';

const SearchBarCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: 40px;
  // margin-bottom: 33px;

  p {
    ${eyebrowSStyles};
    color: ${colors.gray700};
    text-transform: uppercase;
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 30px;
    // margin-bottom: 20px;
    p {
      ${eyebrowXsStyles};
      color: ${colors.gray700};
      text-transform: uppercase;
    }
  }
`;

function SearchStatus(props) {
  // Effects
  const { searchQuery, searchedPostsLength } = props;

  return (
    <Row>
      {/* Results copy */}
      <SearchBarCol xsOffset={1} xs={6} md={8} lg={10}>
        <Section>
          <p>
            {' '}
            {searchedPostsLength === 0 ? <span>NO</span> : <span>{searchedPostsLength}</span>} RESULTS FOR “
            {searchQuery}”
          </p>
        </Section>
      </SearchBarCol>
    </Row>
  );
}

SearchStatus.propTypes = {
  searchQuery: string,
  searchedPostsLength: number,
};
SearchStatus.defaultProps = {
  searchQuery: '',
  searchedPostsLength: 0,
};

export default SearchStatus;
