import React from 'react';
import Select from 'react-select';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { btnLgSecondaryThinBlueStyles, btnSmSecondaryThinBlueStyles } from '../../styles/buttons.js';
import {
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  bodyXxxsRegularBrownStyles,
} from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';
import RadioButton from './RadioButton.jsx';

// Topic dropdown selection options & styling
const selectOptions = [
  { value: 'General Inquiry', label: 'General inquiry' },
  { value: 'Press Inquiry', label: 'Press inquiry' },
  { value: 'Business Inquiry', label: 'Business inquiry' },
];

const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: '0',
  }),
  control: () => ({
    width: '100%',
    cursor: 'default',
    display: 'flex',
    borderBottom: '1px solid #CCCCCC',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.gray400,
    fontFamily: '"Brown", Helvetica, san-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0px',
    lineHeight: '1',
    margin: '0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    color: colors.gray400,
    transform: state.menuIsOpen ? 'rotate(180deg)' : 'none',
  }),
  menu: () => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
  }),
  option: () => ({
    color: colors.navy600,
    marginBottom: '20px',
    fontFamily: '"Brown", Helvetica, san-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    letterSpacing: '0px',
    lineHeight: '1',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.navy600,
    fontFamily: '"Brown", Helvetica, san-serif',
    fontWeight: 'normal',
  }),
};

const Form = styled.form`
  margin: 60px 0 80px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px ${colors.beige100} inset;
  }

  .react-select__control {
    cursor: pointer;

    svg {
      height: 15px;
      width: 12px;
    }

    margin-top: 60px;
  }

  .react-select__placeholder {
    ${bodyXsRegularBrownStyles};
    color: ${colors.gray400};
    padding-bottom: 5px;
  }

  .react-select__single-value {
    ${bodyXsRegularBrownStyles};
    color: ${colors.navy600};
  }

  .react-select__option,
  .react-select__option--is-focused,
  .react-select__option--is-selected {
    ${bodyXxsRegularBrownStyles};
    margin-bottom: 10px;
  }

  @media (${bpWidth.desktopSm}) {
    margin: 40px 0 50px;
  }

  @media (${bpWidth.tablet}) {
    margin-bottom: 60px;
  }

  @media (${bpWidth.mobile}) {
    margin-bottom: 50px;

    .react-select__control {
      margin-top: 40px;
    }
    .react-select__placeholder {
      ${bodyXxsRegularBrownStyles};
      color: ${colors.gray400};
    }
    .react-select__single-value {
      ${bodyXxsRegularBrownStyles};
      color: ${colors.navy600};
    }
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  div:first-child {
    padding-right: 12px;
    width: 50%;
  }

  .nameFields:last-child {
    padding-left: 12px;
    width: 50%;
  }

  @media (${bpWidth.desktopSm}) {
    div:first-child {
      padding-right: 10px;
    }

    .nameFields:last-child {
      padding-left: 10px;
    }
  }
  @media (${bpWidth.tablet}) {
    div:first-child {
      padding-right: 8px;
    }

    .nameFields:last-child {
      padding-left: 8px;
    }
  }
  @media (${bpWidth.mobile}) {
    div:first-child {
      padding-right: 5px;
    }

    .nameFields:last-child {
      padding-left: 5px;
    }
  }
`;

const TextField = styled(Field).attrs({
  type: 'text',
})`
  ${bodyXsRegularBrownStyles};
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${colors.gray100};
  border-radius: 0;
  color: ${colors.navy600};
  padding-bottom: 5px;
  width: 100%;

  &::placeholder {
    color: ${colors.gray400};
  }

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.navy600};

    &::placeholder {
      color: ${colors.gray400};
    }
  }
`;

const Email = styled(TextField)`
  margin-top: 30px;
  width: 100%;

  @media (${bpWidth.mobile}) {
    margin-top: 20px;
  }
`;

const MessageLabel = styled.div`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray400};
  margin-top: 30px;
  padding-bottom: 5px;

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray400};
    margin-top: 20px;
  }
`;

const Message = styled(Field)`
  ${bodyXsRegularBrownStyles};
  background-color: transparent;
  border: 1px solid ${colors.gray100};
  min-height: 100px;
  width: 100%;

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
    min-height: 70px;
  }
`;

const Submit = styled.button.attrs({
  type: 'submit',
})`
  ${btnLgSecondaryThinBlueStyles};
  cursor: pointer;
  margin-top: 30px;

  @media (${bpWidth.desktopSm}) {
    ${btnSmSecondaryThinBlueStyles};
  }
`;

const ErrorStyles = styled.div`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.orange700};
  margin-top: 5px;

  &:last-child {
    padding: 0;
  }

  @media (${bpWidth.mobile}) {
    ${bodyXxxsRegularBrownStyles};
    color: ${colors.orange700};
  }
`;

const CheckboxGroup = styled.div`
  label {
    ${bodyXxsRegularBrownStyles};
    display: inline-flex;
    align-items: center;
    color: ${colors.navy600};
  }

  @media (${bpWidth.mobile}) {
    label {
      ${bodyXxxsRegularBrownStyles};
      color: ${colors.navy600};
    }
  }
`;

function SelectInput({ setFieldValue }) {
  return (
    <Select
      options={selectOptions}
      styles={selectStyles}
      placeholder="Topic*"
      onChange={(selectedValue) => {
        setFieldValue('topic', selectedValue.value);
      }}
      classNamePrefix="react-select"
    />
  );
}

SelectInput.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
};

function SupportForm(props) {
  const { handleSubmit, isSubmitting, resetForm } = props;

  React.useEffect(() => {
    resetForm();
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <NameWrapper>
          <div className="nameFields">
            <TextField name="firstname" placeholder="First name*" />
            <ErrorStyles>
              <ErrorMessage name="firstname" />
            </ErrorStyles>
          </div>

          <div className="nameFields">
            <TextField name="lastname" placeholder="Last name*" />
            <ErrorStyles>
              <ErrorMessage name="lastname" />
            </ErrorStyles>
          </div>
        </NameWrapper>

        <Email name="email" placeholder="Email address*" />
        <ErrorStyles>
          <ErrorMessage name="email" />
        </ErrorStyles>

        <CheckboxGroup>
          <Field type="checkbox" name="newsletterSubscribe">
            {({ field }) => <RadioButton {...field}>Subscribe to the newsletter</RadioButton>}
          </Field>
        </CheckboxGroup>

        <Field name="topic">{({ form }) => <SelectInput {...form} />}</Field>
        <ErrorStyles>
          <ErrorMessage name="topic" />
        </ErrorStyles>

        <MessageLabel>Message</MessageLabel>
        <Message name="message" component="textarea" />
        <ErrorStyles>
          <ErrorMessage name="message" />
        </ErrorStyles>

        <Submit disabled={isSubmitting}>Submit</Submit>
      </div>
    </Form>
  );
}

SupportForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
};

export default SupportForm;
