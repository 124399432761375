import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bodyLItalicUtopiaStyles, bodyXlItalicUtopiaStyles } from '../../../styles/typography';
import { bpWidth } from '../../../styles/variables';

const NewspaperCol = styled(Col)`
  width: 22%;
  margin-bottom: ${({ count }) => (count > 4 ? '30px' : undefined)};

  @media (${bpWidth.tablet}) {
    width: 48%;
    margin-bottom: ${({ count }) => (count > 2 ? '30px' : undefined)};
  }

  @media (${bpWidth.mobile}) {
    width: 100%;
    margin-bottom: ${({ count }) => (count > 1 ? '30px' : undefined)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Logo = styled.div`
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  ${bodyXlItalicUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodyLItalicUtopiaStyles}
  }
`;

const Newspaper = ({ blok, count }) => {
  const { logo, text, link } = blok;
  const linkUrl = link?.cached_url || link?.url;

  return (
    <NewspaperCol {...storyblokEditable(blok)} count={count}>
      <Wrapper>
        {logo?.length > 0 && (
          <Logo>
            {logo.map((nestedBlok) => (
              <a
                href={linkUrl}
                target={link?.target}
                rel={link?.target ? 'noreferrer' : undefined}
                key={nestedBlok._uid}
              >
                <StoryblokComponent blok={nestedBlok} />
              </a>
            ))}
          </Logo>
        )}
        {text && <Text>{text}</Text>}
      </Wrapper>
    </NewspaperCol>
  );
};

Newspaper.propTypes = {
  blok: PropTypes.object.isRequired,
  count: PropTypes.number,
};

export default Newspaper;
