import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import {
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodyXlRegularUtopiaStyles,
} from '../../../styles/typography';
import { bpWidth } from '../../../styles/variables';

const ItemCol = styled(Col)`
  display: flex;
  align-items: center;

  .media-col {
    flex-basis: auto;
  }

  img {
    width: 69px;
    height: 69px;
  }

  p {
    ${bodyXlRegularUtopiaStyles};
    margin-left: 24px;
  }

  @media (${bpWidth.desktopSm}) {
    img {
      width: 55px;
      height: 55px;
    }

    p {
      ${bodyLRegularUtopiaStyles};
      margin-left: 15px;
    }
  }

  @media (${bpWidth.tablet}) {
    img {
      width: 45px;
      height: 45px;
    }

    p {
      ${bodyMRegularUtopiaStyles};
      margin-left: 7px;
    }
  }

  @media (${bpWidth.mobile}) {
    p {
      margin-left: 8px;
    }
  }
`;

const IconTextListItem = ({ blok }) => (
  <ItemCol {...storyblokEditable(blok)}>
    {blok.icon?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
    <p>{blok.heading}</p>
  </ItemCol>
);

IconTextListItem.propTypes = {
  blok: object.isRequired,
};

export default IconTextListItem;
