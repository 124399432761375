import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Col } from 'react-styled-flexboxgrid';
import { motion, useAnimation, useReducedMotion } from 'framer-motion';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoint } from '../../../constants/breakpoint';
import { useViewport } from '../../../context/viewport.context';
import {
  bodyLRegularBrownStyles,
  bodyMRegularBrownStyles,
  bodySRegularBrownStyles,
  eyebrowLStyles,
  eyebrowMStyles,
  headerLFillStyles,
  headerMStyles,
  headerSStyles,
  headerXlFillStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../../styles/typography';
import { bpWidthTailwind, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const TileCol = styled(Col)``;

const Title = styled.div`
  ${headerXxsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  }
`;

const Content = styled.div`
  ${bodySRegularBrownStyles};
  color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyMRegularBrownStyles};
    color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyLRegularBrownStyles};
    color: ${({ darkMode }) => (darkMode ? colors.orange200 : undefined)};
  }
`;

const StatisticsTitle = styled.div`
  ${eyebrowMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${eyebrowLStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${eyebrowLStyles};
    font-size: 23px;
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }
`;
const StatisticsData = styled.div`
  ${headerMStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerLFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerXlFillStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean100 : undefined)};
  }
`;
const StatisticsDescription = styled.div`
  ${headerXxsStyles};
  color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
  font-size: 16px;
  flex: 1;

  @media (${bpWidthTailwind.desktopSm}) {
    ${headerXxsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerXsStyles};
    color: ${({ darkMode }) => (darkMode ? colors.cerulean400 : undefined)};
  }
`;

const TileVariant = {
  style1: 'style1',
  style2: 'style2',
  style3: 'style3',
  style4: 'style4',
};

const TileItem = ({ blok, darkMode = false }) => {
  const { variant, title, image, content, statisticsTitle, statisticsData, statisticsDescription } = blok;
  const { viewWidth } = useViewport();

  const titleRichText = useMemo(() => renderRichTextReact(title), [blok.title]);
  const contentRichText = useMemo(() => renderRichTextReact(content), [blok.content]);
  const statisticsTitleRichText = useMemo(() => renderRichTextReact(statisticsTitle), [blok.statisticsTitle]);
  const statisticsDataRichText = useMemo(() => renderRichTextReact(statisticsData), [blok.statisticsData]);
  const statisticsDescriptionRichText = useMemo(
    () => renderRichTextReact(statisticsDescription),
    [blok.statisticsDescription],
  );

  const shouldReduceMotion = useReducedMotion();
  const threshold = 0.6;
  const duration = 3;
  const controls = useAnimation();
  const [tileRef, tileInView] = useInView({ threshold });

  useEffect(() => {
    if (!shouldReduceMotion && viewWidth >= breakpoint.md) {
      if (tileInView) {
        controls.start('to');
      }
    } else {
      controls.start('from');
    }
  }, [controls, tileInView, shouldReduceMotion, viewWidth]);

  const line1Variants = useMemo(() => {
    if (variant === TileVariant.style1) {
      return {
        from: () => {
          let width = '19rem';
          if (viewWidth >= breakpoint.lg) {
            width = '42rem';
          } else if (viewWidth >= breakpoint.md) {
            width = '31.25rem';
          }

          return {
            y: 0,
            rotate: 90,
            width,
          };
        },
        to: () => {
          let y;
          if (viewWidth >= breakpoint.lg) {
            y = '18.75rem';
          } else if (viewWidth >= breakpoint.md) {
            y = '0.75rem';
          }

          let width;
          if (viewWidth >= breakpoint.md) {
            width = '42rem';
          }

          return {
            y,
            rotate: 90,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style2) {
      return {
        from: () => {
          let width = '22rem';
          if (viewWidth >= breakpoint.lg) {
            width = '33rem';
          } else if (viewWidth >= breakpoint.md) {
            width = '31.25rem';
          }

          return {
            y: 0,
            rotate: 90,
            width,
          };
        },
        to: () => {
          let y;
          if (viewWidth >= breakpoint.lg) {
            y = '14rem';
          } else if (viewWidth >= breakpoint.md) {
            y = '8rem';
          }

          let width;
          if (viewWidth >= breakpoint.md) {
            width = '42.5rem';
          }

          return {
            y,
            rotate: 90,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style3) {
      return {
        from: () => {
          let width = '150%';
          if (viewWidth >= breakpoint.lg) {
            width = '104%';
          } else if (viewWidth >= breakpoint.md) {
            width = '110%';
          }

          return {
            x: 0,
            width,
          };
        },
        to: () => {
          let x = 0;
          if (viewWidth >= breakpoint.lg) {
            x = '70%';
          } else if (viewWidth >= breakpoint.md) {
            x = '63%';
          }

          let width;
          if (viewWidth >= breakpoint.lg) {
            width = '63%';
          } else if (viewWidth >= breakpoint.md) {
            width = '69%';
          }

          return {
            x,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style4) {
      return {
        from: { y: 0, rotate: -90 },
        to: () => {
          let y;
          if (viewWidth >= breakpoint.lg) {
            y = '16rem';
          }

          let width;
          if (viewWidth >= breakpoint.md) {
            width = '42.5rem';
          }

          return {
            y,
            width,
            rotate: -90,
          };
        },
      };
    }

    return null;
  }, [viewWidth, variant]);

  const line2Variants = useMemo(() => {
    if (variant === TileVariant.style1) {
      return {
        from: () => {
          let width = '200%';
          if (viewWidth >= breakpoint.lg) {
            width = '113%';
          } else if (viewWidth >= breakpoint.md) {
            width = '137%';
          }

          return {
            x: 0,
            width,
          };
        },
        to: () => {
          let x;
          if (viewWidth >= breakpoint.lg) {
            x = '7%';
          } else if (viewWidth >= breakpoint.md) {
            x = 0;
          }

          let width;
          if (viewWidth >= breakpoint.lg) {
            width = '125%';
          } else if (viewWidth >= breakpoint.md) {
            width = '195%';
          }

          return {
            x,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style2) {
      return {
        from: () => {
          let width = '244%';
          if (viewWidth >= breakpoint.lg) {
            width = '143%';
          } else if (viewWidth >= breakpoint.md) {
            width = '187%';
          }

          return {
            width,
          };
        },
        to: () => {
          let width;
          if (viewWidth >= breakpoint.lg) {
            width = '200%';
          } else if (viewWidth >= breakpoint.md) {
            width = '235%';
          }

          let left;
          if (viewWidth >= breakpoint.lg) {
            left = undefined;
          } else if (viewWidth >= breakpoint.md) {
            left = 0;
          }

          return {
            left,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style3) {
      return {
        from: { y: 0, rotate: 90 },
        to: () => {
          let y = 0;
          if (viewWidth >= breakpoint.lg) {
            y = '12rem';
          } else if (viewWidth >= breakpoint.md) {
            y = '16rem';
          }

          return {
            y,
            rotate: 90,
          };
        },
      };
    }

    if (variant === TileVariant.style4) {
      return {
        from: { y: 0, rotate: -90 },
        to: () => {
          let y = 0;
          if (viewWidth >= breakpoint.lg) {
            y = '6rem';
          } else if (viewWidth >= breakpoint.md) {
            y = '11rem';
          }

          return {
            y,
            rotate: -90,
          };
        },
      };
    }

    return null;
  }, [viewWidth, variant]);

  const line3Variants = useMemo(() => {
    if (variant === TileVariant.style1) {
      return {
        from: () => {
          let width = '6.5rem';
          if (viewWidth >= breakpoint.lg) {
            width = '17rem';
          } else if (viewWidth >= breakpoint.md) {
            width = '9.5rem';
          }

          return {
            y: 0,
            rotate: 90,
            width,
          };
        },
        to: () => {
          let y;
          if (viewWidth >= breakpoint.lg) {
            y = '10.5rem';
          } else if (viewWidth >= breakpoint.md) {
            y = '2.5rem';
          }

          let width;
          if (viewWidth >= breakpoint.md) {
            width = '18rem';
          }

          return {
            y,
            rotate: 90,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style2) {
      return {
        from: { y: 0, rotate: -90 },
        to: () => {
          let y = 0;
          if (viewWidth >= breakpoint.lg) {
            y = '3.75rem';
          } else if (viewWidth >= breakpoint.md) {
            y = 0;
          }

          let width;
          if (viewWidth >= breakpoint.lg) {
            width = undefined;
          } else if (viewWidth >= breakpoint.md) {
            width = '18rem';
          }

          return {
            y,
            rotate: -90,
            width,
          };
        },
      };
    }

    if (variant === TileVariant.style3) {
      return {
        from: () => {
          let left;
          if (viewWidth >= breakpoint.lg) {
            left = '16%';
          } else if (viewWidth >= breakpoint.md) {
            left = 0;
          }

          return {
            left,
            right: 'auto',
          };
        },
        to: () => {
          let right;
          if (viewWidth >= breakpoint.lg) {
            right = 0;
          } else if (viewWidth >= breakpoint.md) {
            right = '25%';
          }

          return {
            left: 'auto',
            right,
          };
        },
      };
    }

    if (variant === TileVariant.style4) {
      return {
        from: () => {
          let width = '153%';
          if (viewWidth >= breakpoint.lg) {
            width = '120%';
          }

          return {
            width,
          };
        },
        to: () => {
          let width;
          if (viewWidth >= breakpoint.lg) {
            width = '150%';
          } else if (viewWidth >= breakpoint.md) {
            width = '129%';
          }

          let x;
          if (viewWidth >= breakpoint.lg) {
            x = undefined;
          } else if (viewWidth >= breakpoint.md) {
            x = '15%';
          }

          return {
            width,
            x,
          };
        },
      };
    }

    return null;
  }, [viewWidth, variant]);

  return (
    <TileCol {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {variant === TileVariant.style1 && (
        <div ref={tileRef} className="flex flex-wrap">
          <div className="w-6/12 mt-24 lg:mt-40 pr-6 md:pr-[3.33333vw] mb-9 md:mb-12 lg:mb-14">
            {image?.map((nestedBlok) => (
              <div className="lg:max-w-[31.62vw] mr-0 ml-auto" key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
          </div>
          <div
            className="w-6/12 mb-9 md:mb-20 lg:mb-28
            pl-11 sm:pl-[7.8%] md:pl-[5.5%] lg:pl-[3.33333vw] relative"
          >
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line1Variants}
              className="hidden sm:block absolute top-28 md:top-32 left-0
              w-[19rem] md:w-[31.25rem] lg:w-[42rem] h-px bg-orange-100/30 rotate-90 origin-top-left"
            ></motion.div>
            <div className="flex flex-col h-full gap-5 justify-end lg:w-[28.7rem]">
              {titleRichText && <Title darkMode={darkMode}>{titleRichText}</Title>}
              {contentRichText && <Content darkMode={darkMode}>{contentRichText}</Content>}
            </div>
          </div>
          <div className="w-6/12 relative mt-8 md:mt-0 mb-32 md:mb-0">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line2Variants}
              className="hidden sm:block absolute -top-8 md:top-0 left-0 lg:left-[14%]
              w-[200%] md:w-[137%] lg:w-[113%] h-[0.8px] bg-orange-200"
            ></motion.div>
            <div className="flex md:hidden flex-col gap-y-2 md:gap-y-3">
              <div className="flex">
                {statisticsTitleRichText && (
                  <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                )}
              </div>
              <div className="flex gap-x-3.5 md:gap-x-7 items-center">
                {statisticsDataRichText && (
                  <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                )}
                {statisticsDescriptionRichText && (
                  <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                )}
              </div>
            </div>
          </div>
          <div className="w-6/12 mt-14 lg:mt-16 md:mb-80 lg:mb-40 pl-[12.5%] lg:pl-[10vw] relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line3Variants}
              className="hidden sm:block absolute -top-16 md:-top-24 lg:-top-32 left-[27%] md:left-[13%] lg:left-[15%]
              w-[6.5rem] md:w-[9.5rem] lg:w-[17rem] h-px bg-orange-400 rotate-90 origin-top-left"
            ></motion.div>
            <div className="hidden md:flex flex-col gap-y-3 h-full md:w-[17rem] lg:w-[21.8rem]">
              <div className="flex">
                {statisticsTitleRichText && (
                  <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                )}
              </div>
              <div className="flex gap-x-7 items-center">
                {statisticsDataRichText && (
                  <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                )}
                {statisticsDescriptionRichText && (
                  <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {variant === TileVariant.style2 && (
        <div ref={tileRef} className="flex flex-wrap">
          <div className="w-5/12 [@media(min-width:1680px)]:w-4/12 pr-[4vw] mb-40 lg:mb-28 relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line1Variants}
              className="hidden sm:block absolute -bottom-[22rem] md:-bottom-[24rem] lg:-bottom-[17rem] right-0
              w-[22rem] md:w-[31.25rem] lg:w-[33rem] h-px bg-orange-100/30 rotate-90 origin-top-right"
            ></motion.div>
            <div
              className="hidden md:flex flex-col gap-y-2 md:gap-y-3
              md:w-[16.7rem] lg:w-[20.5rem] h-full justify-end mr-0 ml-auto"
            >
              <div className="flex">
                {statisticsTitleRichText && (
                  <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                )}
              </div>
              <div className="flex gap-x-3.5 md:gap-x-[1.5625rem] items-center">
                {statisticsDataRichText && (
                  <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                )}
                {statisticsDescriptionRichText && (
                  <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                )}
              </div>
            </div>
          </div>
          <div className="w-7/12 [@media(min-width:1680px)]:w-8/12 mb-11 md:mb-20 pl-[11.5%] md:pl-[10%] lg:pl-[6%]">
            {image?.map((nestedBlok) => (
              <div className="lg:max-w-[31.25vw]" key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
          </div>
          <div className="w-5/12 [@media(min-width:1680px)]:w-4/12 mt-16 md:mt-0 relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line2Variants}
              className="hidden sm:block absolute -top-16 md:top-0 left-0 md:left-[22%] lg:left-[14%]
              w-[244%] md:w-[187%] lg:w-[143%] h-[0.8px] bg-orange-200"
            ></motion.div>
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line3Variants}
              className="hidden sm:block absolute -top-16 md:top-0 lg:-top-[3.75rem] right-8 md:right-24 lg:right-32
              w-[3.5rem] md:w-[9.5rem] lg:w-[17.5rem] h-px bg-orange-400 -rotate-90 origin-top-right"
            ></motion.div>
            <div className="flex md:hidden flex-col gap-y-2 md:gap-y-3">
              <div className="flex">
                {statisticsTitleRichText && (
                  <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                )}
              </div>
              <div className="flex gap-x-3.5 md:gap-x-7 items-center pr-[5%] md:pr-0">
                {statisticsDataRichText && (
                  <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                )}
                {statisticsDescriptionRichText && (
                  <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                )}
              </div>
            </div>
          </div>
          <div
            className="w-7/12 [@media(min-width:1680px)]:w-8/12 mt-16 md:mt-20 lg:mt-16
            mb-24 md:mb-[8.5rem] pl-[8%] md:pl-[10%] lg:pl-[6%]"
          >
            <div className="flex flex-col md:w-[23.1rem] lg:w-[29rem] h-full gap-5 justify-end">
              {titleRichText && <Title darkMode={darkMode}>{titleRichText}</Title>}
              {contentRichText && <Content darkMode={darkMode}>{contentRichText}</Content>}
            </div>
          </div>
        </div>
      )}
      {variant === TileVariant.style3 && (
        <div ref={tileRef} className="flex flex-wrap">
          <div
            className="w-7/12 md:w-6/12 mt-20 md:mt-24 lg:mt-48 pt-[4.5rem] md:pt-24 pr-[10%] lg:pr-[3.4%]
            mb-11 md:mb-20 lg:mb-24"
          >
            {image?.map((nestedBlok) => (
              <div className="lg:max-w-[32.084vw] mr-0 ml-auto" key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
          </div>
          <div
            className="w-5/12 md:w-6/12 mt-20 md:mt-24 lg:mt-48 pt-24 md:pt-32 lg:pt-40
            pl-9 md:pl-24 lg:pl-[9%] relative"
          >
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line1Variants}
              className="hidden sm:block absolute top-0 -left-[40%] md:-left-[25%]
              w-[150%] md:w-[110%] lg:w-[104%] h-px bg-orange-100/30"
            ></motion.div>
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line2Variants}
              className="hidden sm:block absolute -top-24 lg:-top-48 left-0
              w-[39rem] md:w-[40.5rem] lg:w-[60rem] h-[0.8px] bg-orange-200 rotate-90 origin-top-left"
            ></motion.div>
            <div
              className="flex flex-col gap-y-2 md:gap-y-3
              max-w-[10.2rem] w-full md:max-w-none md:w-[16.9rem] lg:w-[30rem] h-full"
            >
              <div className="flex">
                {statisticsTitleRichText && (
                  <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                )}
              </div>
              <div className="flex flex-col lg:flex-row gap-x-3.5 md:gap-x-7 gap-y-3 lg:items-center">
                {statisticsDataRichText && (
                  <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                )}
                {statisticsDescriptionRichText && (
                  <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                )}
              </div>
            </div>
          </div>
          <div className="w-7/12 md:w-6/12 mt-14 lg:mt-24 mb-36 md:mb-40 pl-0 md:pl-[8%] relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line3Variants}
              className="hidden sm:block absolute -top-14 lg:-top-24 left-[33%] md:left-0 lg:left-[16%]
              w-[89%] md:w-[53%] lg:w-[50%] h-px bg-orange-400"
            ></motion.div>
            <div className="flex flex-col h-full gap-5 md:w-[23.3rem] lg:w-[28.2rem]">
              {titleRichText && <Title darkMode={darkMode}>{titleRichText}</Title>}
              {contentRichText && <Content darkMode={darkMode}>{contentRichText}</Content>}
            </div>
          </div>
          <div className="w-5/12 md:w-6/12"></div>
        </div>
      )}
      {variant === TileVariant.style4 && (
        <div ref={tileRef} className="flex flex-wrap">
          <div className="w-[45.45%] md:w-5/12 pr-0 md:pr-[8%] lg:pr-[3.5%] mb-16 md:mb-28 lg:mb-32 relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line1Variants}
              className="hidden sm:block absolute top-[9.5rem] md:top-6 lg:top-0 right-0
              w-[17.5rem] md:w-[25rem] lg:w-[42.5rem] h-px bg-orange-100/30
              -rotate-90 origin-bottom-right"
            ></motion.div>
            <div className="flex flex-col w-full h-full justify-end lg:w-[23.7rem] mr-0 ml-auto">
              <div className="flex flex-col mr-0 ml-auto gap-y-2 md:gap-y-3">
                <div className="flex md:pr-[20%]">
                  {statisticsTitleRichText && (
                    <StatisticsTitle darkMode={darkMode}>{statisticsTitleRichText}</StatisticsTitle>
                  )}
                </div>
                <div className="flex gap-x-3 md:gap-x-7 items-center">
                  {statisticsDataRichText && (
                    <StatisticsData darkMode={darkMode}>{statisticsDataRichText}</StatisticsData>
                  )}
                  {statisticsDescriptionRichText && (
                    <StatisticsDescription darkMode={darkMode}>{statisticsDescriptionRichText}</StatisticsDescription>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative w-[54.55%] md:w-7/12
            mt-0 md:mt-24 lg:mt-12 md:pr-8 lg:pr-0 mb-8 md:mb-12 lg:mb-24 pl-16 md:pl-32 lg:pl-60"
          >
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line2Variants}
              className="hidden sm:block absolute -bottom-8 md:bottom-[6.5rem] lg:bottom-0 left-9 md:left-16 lg:left-36
              w-[6.25rem] md:w-[18rem] h-px bg-orange-400
              -rotate-90 origin-bottom-left"
            ></motion.div>
            {image?.map((nestedBlok) => (
              <div className="lg:max-w-[32.605vw]" key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} />
              </div>
            ))}
          </div>
          <div className="w-[45.45%] md:w-5/12 relative">
            <motion.div
              animate={controls}
              initial="from"
              transition={{ duration }}
              variants={line3Variants}
              className="hidden sm:block absolute w-[153%] lg:w-[120%] h-[0.8px] bg-orange-200"
            ></motion.div>
          </div>
          <div className="w-[54.55%] md:w-7/12 mt-14 lg:mt-20 mb-0 md:mb-24 pl-9 md:pl-16 lg:pl-24">
            <div className="flex flex-col h-full gap-5 md:w-[26.3rem] lg:w-[29.2rem]">
              {titleRichText && <Title darkMode={darkMode}>{titleRichText}</Title>}
              {contentRichText && <Content darkMode={darkMode}>{contentRichText}</Content>}
            </div>
          </div>
        </div>
      )}
    </TileCol>
  );
};

TileItem.propTypes = {
  blok: PropTypes.object.isRequired,
  enabledAnimation: PropTypes.bool,
  darkMode: PropTypes.bool,
};

export default TileItem;
