import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { format } from 'date-fns';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { useIsClient, useIsomorphicLayoutEffect } from '../../../hooks';
import {
  bodyLRegularUtopiaStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularUtopiaStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
  headerMStyles,
  headerSStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';

const Page = styled(Row)`
  ${bodyMRegularUtopiaStyles};

  @media (${bpWidth.desktopSm}) {
    ${bodySRegularUtopiaStyles};
  }
`;

const PolicyTitleCol = styled(Col)`
  margin-top: 245px;
  margin-bottom: 50px;
  padding-right: 1rem;

  @media (${bpWidth.desktopSm}) {
    margin-top: 140px;
  }

  ol {
    position: sticky;
    top: ${({ stickyTopPosition }) => stickyTopPosition}px;
    list-style: ${({ hideOrderedListNumber }) => (hideOrderedListNumber ? 'none' : 'decimal')};
    margin-left: ${({ hideOrderedListNumber }) => (hideOrderedListNumber ? 0 : '20px')};
  }

  li {
    span {
      cursor: pointer;
    }

    ${eyebrowSStyles};
    margin-bottom: 30px;

    @media (${bpWidth.desktopSm}) {
      ${eyebrowXsStyles};
      margin-bottom: 25px;
    }
  }
`;

const PolicyContentCol = styled(Col)`
  margin-top: 90px;

  @media (${bpWidth.desktopSm}) {
    margin-top: 50px;
  }

  .section {
    margin-bottom: 80px;
  }

  h1.page-heading {
    ${headerMStyles};
    margin-bottom: 20px;

    @media (${bpWidth.desktopSm}) {
      ${headerSStyles};
      margin-bottom: 10px;
    }
  }

  h5 {
    ${eyebrowSStyles};
    font-size: 16px;
    margin-bottom: 10px;
  }

  h6,
  .description-section {
    ${bodyLRegularUtopiaStyles};

    @media (${bpWidth.desktopSm}) {
      ${bodyMRegularUtopiaStyles};
    }
  }

  a {
    text-decoration: none;
    color: ${colors.cerulean400};
  }
`;

const scrollToContent = (policyItem, e) => {
  if (e) {
    e.preventDefault();
  }

  if (typeof policyItem === 'string') {
    // eslint-disable-next-line no-param-reassign
    policyItem = document.getElementById(policyItem);
  }

  if (policyItem) {
    window.scrollTo({
      behavior: 'smooth',
      top: policyItem.offsetTop - 150,
    });
  }
};

const Policy = ({ blok }) => {
  const {
    heading,
    lastUpdatedLabel,
    lastUpdateValue,
    content = [],
    alwaysHideNavigator = false,
    hideOrderedListNumber = false,
    items = [],
  } = blok;
  const isClient = useIsClient();

  const [policyTitleColWidth, setPolicyTitleColWidth] = useState({
    xs: false,
    sm: false,
    md: false,
    lg: false,
  });
  const [policyContentColWidth, setPolicyContentColWidth] = useState({
    xsOffset: 1,
    xs: 6,
    mdOffset: 2,
    md: 8,
    lg: 10,
  });
  const [stickyTopPosition, setStickyTopPosition] = useState(61);
  const { viewWidth } = useViewport();

  const calculateStickyTopPosition = () => {
    const headerContainerRow = document.getElementById('headerContainerRow');
    if (headerContainerRow) {
      setStickyTopPosition(headerContainerRow.getBoundingClientRect().height);
    }
  };

  useIsomorphicLayoutEffect(() => {
    setTimeout(calculateStickyTopPosition, 1000);
  }, [viewWidth]);

  useEffect(() => {
    if (items?.length > 0) {
      setPolicyTitleColWidth({
        xs: false,
        sm: false,
        mdOffset: alwaysHideNavigator ? false : 1,
        md: alwaysHideNavigator ? false : 3,
        lg: alwaysHideNavigator ? false : 3,
      });
      setPolicyContentColWidth({
        xsOffset: 1,
        xs: 6,
        mdOffset: alwaysHideNavigator ? 2 : 0,
        md: alwaysHideNavigator ? 6 : 5,
        lg: alwaysHideNavigator ? 8 : 6,
      });
    } else {
      setPolicyTitleColWidth({
        xs: false,
        sm: false,
        md: false,
        lg: false,
      });
      setPolicyContentColWidth({
        xsOffset: 1,
        xs: 6,
        mdOffset: 2,
        md: 6,
        lg: 8,
      });
    }
  }, [items, alwaysHideNavigator]);

  useEffect(() => {
    const handleEvent = (element, anchorPoint) => {
      if (anchorPoint) {
        element.addEventListener('click', (e) => scrollToContent(anchorPoint, e), false);
      }
    };

    if (isClient) {
      document.querySelectorAll('a.richTextAnchorLink').forEach((current) => {
        if (!current.hash) return;
        if (current.origin + current.pathname !== window.location.href) return;
        handleEvent(current, document.querySelector(current.hash));
      });
    }

    return () => {
      if (isClient) {
        document.querySelectorAll('a.richTextAnchorLink').forEach((current) => {
          if (!current.hash) return;
          if (current.origin + current.pathname !== window.location.href) return;
          handleEvent(current, document.querySelector(current.hash));
        });
      }
    };
  }, [isClient]);

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      <Page>
        <PolicyTitleCol
          {...policyTitleColWidth}
          hideOrderedListNumber={hideOrderedListNumber}
          stickyTopPosition={stickyTopPosition}
        >
          <ol>
            {items.map((policy) => (
              <li key={`heading-${policy._uid}`}>
                <span role="presentation" onClick={(e) => scrollToContent(policy._uid, e)}>
                  {policy.heading}
                </span>
              </li>
            ))}
          </ol>
        </PolicyTitleCol>
        <PolicyContentCol {...policyContentColWidth}>
          <div className="section">
            <h1 className="page-heading">{heading}</h1>
            {(lastUpdatedLabel || lastUpdateValue) && (
              <h6>
                {lastUpdatedLabel} {lastUpdateValue ? format(new Date(lastUpdateValue), 'MMMM dd, yyyy') : undefined}
              </h6>
            )}
          </div>
          <div className="section description-section">
            {content.map((nestedBlok, i) => (
              <Row key={nestedBlok._uid}>
                <StoryblokComponent blok={nestedBlok} idx={i} />
              </Row>
            ))}
          </div>
          {items.map((nestedBlok, i) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent
                blok={nestedBlok}
                idx={i}
                hideOrderedListNumber={hideOrderedListNumber}
                key={nestedBlok._uid}
              />
            </Row>
          ))}
        </PolicyContentCol>
      </Page>
    </Col>
  );
};

Policy.propTypes = {
  blok: object.isRequired,
};

export default Policy;
