import React from 'react';
import { Link } from 'gatsby';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import { btnLgPrimaryWideBlueStyles, btnSmPrimaryThinBlueStyles } from '../../styles/buttons';
import { bpWidth, colors } from '../../styles/variables';

const defaultOnClick = () => {};

const NormalItem = styled.a`
  border-radius: 50px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  transition: all 250ms;
  width: auto;
  max-width: 300px;
  padding: 12px 45px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2.88px;
  display: inline-block;

  color: ${(props) => props.color};
  background: ${colors.cerulean400};

  // prevent 2nd line
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${colors.beige100};
    background: ${colors.cerulean700};
  }

  @media (${bpWidth.desktopSm}) {
    padding: 10px 38px;
    font-size: 14px;
  }

  @media (${bpWidth.tablet}) {
    padding: 12px 40px;
  }

  @media (${bpWidth.mobile}) {
    padding: 10px 25px;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 2.16px;
  }
`;

const PrimaryLinkItem = styled(Link)`
  text-decoration: none;

  button {
    ${btnLgPrimaryWideBlueStyles};
    cursor: pointer;
    width: 220px;

    :hover {
      border-color: ${colors.cerulean700};
    }
  }

  @media (${bpWidth.desktopSm}) {
    button {
      ${btnSmPrimaryThinBlueStyles};
      cursor: pointer;
      width: 202px;

      :hover {
        border-color: ${colors.cerulean700};
      }
    }
  }

  @media (${bpWidth.mobile}) {
    button {
      width: 149px;
    }
  }
`;

const SecondaryLinkItem = styled(PrimaryLinkItem)`
  background: transparent;
  border: 1px solid ${(props) => props.color};
  color: ${colors.cerulean400};

  &:hover {
    background: ${colors.cerulean700};
    border-color: ${colors.cerulean700};
    color: ${colors.beige100};
  }
`;

const Button = (props) => {
  const { text, href, target, className, onClick } = props;

  const buttonLinkType = () => {
    if (className.includes('secondary')) {
      return (
        <SecondaryLinkItem to={href} target={target} className={className} onClick={onClick}>
          {text}
        </SecondaryLinkItem>
      );
    }

    if (href.includes('https://') || href.includes('http://')) {
      return (
        <NormalItem href={href} target={target} className={className} onClick={onClick}>
          {text}
        </NormalItem>
      );
    }

    return (
      <PrimaryLinkItem to={href} target={target} onClick={onClick}>
        <button type="button" className={className}>
          {text}
        </button>
      </PrimaryLinkItem>
    );
  };

  return buttonLinkType();
};

Button.propTypes = {
  text: string,
  href: string,
  onClick: func,
  className: string,
};
Button.defaultProps = {
  text: 'CONTACT US',
  href: '',
  onClick: defaultOnClick,
  className: '',
};

export default Button;
