import React from 'react';
import { bool, node } from 'prop-types';
import styled from 'styled-components';
import { bodyXxsRegularBrownStyles, bodyXxxsRegularBrownStyles } from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';

const CustomCheckbox = styled.span`
  border: 0.75px solid ${colors.navy600};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11px;
  margin-right: 7px;
  width: 11px;

  /* checkmark */
  &::after {
    background: ${colors.navy600};
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 7px;
    opacity: 0;
    transition: all 150ms;
    width: 7px;
  }

  @media (${bpWidth.mobile}) {
    height: 9px;
    width: 9px;

    &::after {
      height: 5px;
      width: 5px;
    }
  }
`;

const Label = styled.label`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.navy600};
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  /* only show the hover state if it's not disabled or checked */
  &:hover {
    input:not(:disabled):not(:checked) ~ ${CustomCheckbox}::after {
      opacity: 0.2;
    }
  }

  input {
    /* take the input out of the flow */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; /* NOT visible, so it will still work with keyboard navigation */

    /* checked state */
    &:checked ~ ${CustomCheckbox}::after {
      opacity: 1;
    }
  }

  @media (${bpWidth.mobile}) {
    ${bodyXxxsRegularBrownStyles};
  }
`;

function RadioButton(props) {
  const { children, checked, ...rest } = props;

  return (
    <Label>
      <input type="checkbox" defaultChecked={checked} {...rest} />
      <CustomCheckbox /> {children}
    </Label>
  );
}

RadioButton.propTypes = {
  children: node,
  checked: bool,
};

export default RadioButton;
