import { sizes } from '../styles/variables';

export const THEME_DESKTOP_SMALL = {
  flexboxgrid: {
    gridSize: sizes.gridCount.desktopSm, // columns
    gutterWidth: 0, // rem
    ourterMargin: 0, // rem
    breakpoints: {
      // breakpoints use ems and use min-widths
      xs: sizes.breakpoint.mobile,
      sm: sizes.breakpoint.tablet,
      md: sizes.breakpoint.desktopSm,
      lg: sizes.breakpoint.desktopLg,
    },
  },
};
export const THEME_DESKTOP_LARGE = {
  flexboxgrid: {
    gridSize: sizes.gridCount.desktopLg, // columns
    gutterWidth: 0, // rem
    ourterMargin: 0, // rem
    breakpoints: {
      // breakpoints use ems and use min-widths
      xs: sizes.breakpoint.mobile,
      sm: sizes.breakpoint.tablet,
      md: sizes.breakpoint.desktopSm,
      lg: sizes.breakpoint.desktopLg,
    },
  },
};

export const THEME_MOBILE = {
  flexboxgrid: {
    gridSize: sizes.gridCount.mobile, // columns
    gutterWidth: 0, // rem
    ourterMargin: 0, // rem
    breakpoints: {
      // breakpoints use ems and use min-widths
      xs: sizes.breakpoint.mobile,
      sm: sizes.breakpoint.tablet,
      md: sizes.breakpoint.desktopSm,
      lg: sizes.breakpoint.desktopLg,
    },
  },
};

export const THEME_TABLET = {
  flexboxgrid: {
    gridSize: sizes.gridCount.tablet, // columns
    gutterWidth: 0, // rem
    ourterMargin: 0, // rem
    breakpoints: {
      // breakpoints use ems and use min-widths
      xs: sizes.breakpoint.mobile,
      sm: sizes.breakpoint.tablet,
      md: sizes.breakpoint.desktopSm,
      lg: sizes.breakpoint.desktopLg,
    },
  },
};
