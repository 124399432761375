import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';

const Slider2Item = ({ blok }) => {
  const { content } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {content?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Col>
  );
};

Slider2Item.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider2Item;
