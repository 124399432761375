import { REGEX } from '../constants';

const { SHOPIFY_ID_REGEX } = REGEX;

/**
 * @typedef {Object} Product Shopify product object
 * @param {object} priceRangeV2 product's variant price range
 * @param {string} shopifyId gid string for product ID
 * @param {string} title product name
 
 * @typedef {Object} Variant Shopify variant object
 * @param  {number} price variant price
 * @param  {string} productTitle name of variant's product
 * @param  {string} storefrontId gid string for variant ID
 * @param  {string} title variant name
 
 * @typedef {object} LineItem Shopify cart legacy line item
 * @property {number} quantity number of items in line item
 * @property {string} title cart line item product title
 * @property {Variant} variant cart line item Variant
 */

/**
 * @param {Product} product
 */
export const dataLayerViewItem = (product) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          item_name: product.title,
          item_id: product.shopifyId.substring(SHOPIFY_ID_REGEX.exec(product.shopifyId).index),
          price: product.priceRangeV2?.maxVariantPrice?.amount,
          item_brand: 'PIAGGIO',
          quantity: '1',
        },
      ],
    },
  });
};

/**
 * @param {Variant} variant
 */
export const dataLayerAddToCart = (variant) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          item_name: variant.productTitle,
          item_id: variant.storefrontId.substring(SHOPIFY_ID_REGEX.exec(variant.storefrontId).index),
          price: variant.price,
          item_brand: 'PIAGGIO',
          item_variant: variant.title,
          quantity: '1',
        },
      ],
    },
  });
};

/**
 * @param {LineItem[]} lineItems
 */
export const dataLayerCheckout = (lineItems) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: lineItems.map(({ title, quantity, variant }) => ({
        item_name: title,
        item_id: variant.id.substring(SHOPIFY_ID_REGEX.exec(variant.id).index),
        price: variant?.priceV2?.amount,
        item_brand: 'PIAGGIO',
        item_variant: variant.title,
        quantity,
      })),
    },
  });
};

export const dataLayerSubscribe = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'newsletter-signup',
  });
};

export const dataLayerConsumerContactPage = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'contact_form',
  });
};

export const dataLayerBusinessContactPage = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'business_contact_form',
  });
};
