import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../context/viewport.context';
import {
  buttonLStyles,
  buttonSStyles,
  eyebrowLStyles,
  eyebrowSStyles,
  headerSStyles,
  headerXsStyles,
  headerXxsStyles,
} from '../../styles/typography';
import { bpWidth, bpWidthTailwind, colors } from '../../styles/variables';
import { renderRichTextReact } from '../../utils/storyblokRichText';

const VideoWithTitleAndCtaCol = styled(Col)`
  &.business-product-overview-video {
    display: flex;
    justify-content: center;

    video {
      height: ${({ videoHeight }) => (videoHeight >= 0 ? `${videoHeight}px` : undefined)};
      background: #000;
    }

    @media (${bpWidthTailwind.tablet}) {
      transform: translateY(-75%);

      video {
        width: 553px;
      }
    }

    @media (${bpWidthTailwind.desktopSm}) {
      video {
        width: 845px;
      }
    }

    @media (${bpWidthTailwind.desktopLg}) {
      video {
        width: 881px;
      }
    }
  }
`;

const CopyCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  &.business-case-study {
    margin-top: 1.125rem;
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
  }

  @media (${bpWidth.desktopSm}) {
    margin-top: 30px;
  }

  @media (${bpWidth.mobile}) {
    margin-top: 30px;

    &.business-case-study {
      margin-top: 1.625rem;
    }
  }
`;

const Title = styled.h2`
  ${eyebrowLStyles};
  color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
  text-align: center;

  &.business-case-study {
    ${headerSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    text-transform: unset;
    text-align: left;
  }

  @media (${bpWidth.desktopSm}) {
    ${eyebrowSStyles};
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};

    &.business-case-study {
      ${headerXsStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
      text-transform: unset;
    }
  }

  @media (${bpWidth.desktopSm}) {
    &.business-case-study {
      ${headerXxsStyles};
      color: ${({ darkMode }) => (darkMode ? colors.beige100 : undefined)};
    }
  }
`;

const LinkCol = styled(Col)`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  &.business-case-study {
    margin-top: 1.125rem;
    flex-basis: 100%;
    max-width: 100%;
    margin-left: 0;
    justify-content: unset;
  }

  a {
    display: flex;
    align-items: center;

    ${buttonLStyles};
    color: ${colors.cerulean400};
    line-height: 1;
    text-decoration: none;

    svg {
      height: fit-content;
      max-height: 9.28px;
      margin-left: 15px;
      margin-top: 2px;
      width: 5.44px;
    }

    &:hover {
      color: ${colors.cerulean700};

      svg path#Side {
        stroke: ${colors.cerulean700};
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    margin-top: 30px;

    a {
      ${buttonSStyles};
      color: ${colors.cerulean400};
      line-height: 1;

      svg {
        margin-left: 10px;
        width: 4.44px;
      }
    }
  }

  @media (${bpWidth.tablet}) {
    margin-top: 20px;
  }
`;

const VideoWithTitleAndCta = ({ blok }) => {
  const { viewWidth } = useViewport();
  const videoRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const [richTextTitle, setRichTextTitle] = useState(null);
  const cssClass = useMemo(
    () => ['video-with-title-and-cta-component--container', blok.customCssClass].filter(Boolean).join(' '),
    [blok.customCssClass],
  );

  // Determining video height, so it does not have black boarders on smaller screens
  useEffect(() => {
    // YouTube video width to height ratio 16:9 ~ 1.78
    const youtubeVideoEl = videoRef.current;
    if (youtubeVideoEl) {
      setTimeout(() => {
        const videoOffsetWidth = youtubeVideoEl.offsetWidth;
        setVideoHeight(videoOffsetWidth / (16 / 9));
      }, 1000);
    }
  }, [viewWidth, videoRef.current, blok]);

  useEffect(() => {
    setRichTextTitle(renderRichTextReact(blok.title));
  }, [blok.title]);

  return (
    <VideoWithTitleAndCtaCol
      {...storyblokEditable(blok)}
      xs={8}
      md={10}
      lg={12}
      darkMode={blok.darkMode}
      className={cssClass}
      videoHeight={videoHeight}
    >
      <Row>
        {/* YouTube video */}
        {(blok.video?.filename || blok.videoMobile?.filename) && blok.video?.is_external_url ? (
          <Col xs={8} md={10} lg={12}>
            <iframe
              ref={videoRef}
              width="100%"
              height={videoHeight}
              src={blok.video?.filename || blok.videoMobile?.filename}
              title={blok.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            />
          </Col>
        ) : (
          <Col xs={8} md={10} lg={12}>
            <video
              ref={videoRef}
              width="100%"
              height={videoHeight}
              src={blok.video?.filename || blok.videoMobile?.filename}
              autoPlay={blok.autoPlay}
              loop={blok.loop}
              muted={blok.muted}
              playsInline={blok.playsInline}
              controls={blok.controls}
              poster={blok.poster?.filename}
              className="brightness-75 w-full"
            />
          </Col>
        )}

        {richTextTitle && (
          <CopyCol
            xsOffset={1}
            xs={6}
            smOffset={2}
            sm={4}
            mdOffset={2}
            md={6}
            lgOffset={3}
            lg={6}
            className={blok.customCssClass}
          >
            <Title darkMode={blok.darkMode} className={blok.customCssClass}>
              {richTextTitle}
            </Title>
          </CopyCol>
        )}

        {/* Meet our team CTA */}
        {blok.cta?.length > 0 && (
          <LinkCol xsOffset={1} xs={6} smOffset={2} sm={4} mdOffset={3} lgOffset={4} className={blok.customCssClass}>
            {blok.cta.map((nestedBlok) => (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            ))}
          </LinkCol>
        )}
      </Row>
    </VideoWithTitleAndCtaCol>
  );
};

VideoWithTitleAndCta.propTypes = {
  blok: object.isRequired,
};

export default VideoWithTitleAndCta;
