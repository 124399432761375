import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import { bodyXsBoldBrownStyles, bodyXxxsBoldBrownStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';

const LeTag = styled.div`
  ${bodyXsBoldBrownStyles};
  background-color: ${colors.black};
  border-radius: 5px;
  color: ${colors.beige100};
  margin-left: 15px;
  padding: 8px 9px;
  text-align: center;
  text-transform: lowercase;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxxsBoldBrownStyles};
    color: ${colors.beige100};
    margin-left: 10px;
    padding: 6px 7px;
  }
`;

const LimitedEditionTag = (props) => {
  const { customClass } = props;

  return <LeTag className={customClass}>limited edition</LeTag>;
};

LimitedEditionTag.propTypes = {
  customClass: string,
};

export default LimitedEditionTag;
