import React, { useState } from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';
import ArrowLCerulean from '../../../icons/arrow-LCerulean.svg';
import ArrowLNavy from '../../../icons/arrow-LNavy.svg';
import { btnLgTertiaryBlueStyles, btnSmTertiaryBlueStyles } from '../../../styles/buttons.js';
import { bpWidth } from '../../../styles/variables.js';

const CollapseButton = styled.button`
  ${btnLgTertiaryBlueStyles};
  display: flex;
  align-items: center;
  margin: 40px 0 70px;
  cursor: pointer;

  &:hover {
    svg.cerulean400 {
      filter: invert(53%) sepia(96%) saturate(6434%) hue-rotate(170deg) brightness(92%) contrast(101%);
    }
  }

  &.expand svg {
    transform: rotate(90deg);
    transition-duration: 0.4s;
  }

  &.collapse svg {
    transform: rotate(270deg);
    transition-duration: 0.4s;
  }

  .arrow {
    margin-right: 5px;

    svg {
      width: 5px;
    }
  }

  // bugfix for WEBDEV-896
  &.collapse {
    visibility: visible;
  }

  @media (${bpWidth.tablet}) {
    ${btnSmTertiaryBlueStyles};
    padding: 54px 0 30px;

    svg {
      width: 3.295px;
      height: 5.53px;
    }
  }

  @media (${bpWidth.mobile}) {
    padding: 54px 0 20px;
    text-align: left;
  }
`;

// Markup
const CollapseArrow = (props) => {
  const { buttonCopy, color, pageType, onExpandChange } = props;

  const [buttonClass, setButtonClass] = useState('expand');
  const [currentButtonCopy, setCurrentButtonCopy] = useState(buttonCopy);

  const onExpandButtonClick = () => {
    const expandSpecs = 'expand specifications';
    const collapseSpecs = 'collapse specifications';

    if (pageType === 'products') {
      if (currentButtonCopy === expandSpecs) {
        setCurrentButtonCopy(collapseSpecs);
        setButtonClass('collapse');
        onExpandChange(true);
      } else {
        setCurrentButtonCopy(expandSpecs);
        setButtonClass('expand');
        onExpandChange(false);
      }
    } else if (pageType === 'productTemplate') {
      onExpandChange(true);
      if (buttonClass === 'collapse') {
        setButtonClass('expand');
      } else {
        setButtonClass('collapse');
      }
    }
  };

  return (
    <CollapseButton className={buttonClass} onClick={onExpandButtonClick}>
      {currentButtonCopy}
      <div className="arrow">
        {color === 'navy600' ? <ArrowLNavy alt="arrow icon" /> : <ArrowLCerulean alt="arrow icon" className={color} />}
      </div>
    </CollapseButton>
  );
};

CollapseArrow.propTypes = {
  buttonCopy: string,
  color: string,
  pageType: string,
  onExpandChange: func,
};
CollapseArrow.defaultProps = {
  buttonCopy: 'expand specifications',
  color: 'cerulean400',
  pageType: '',
};

export default CollapseArrow;
