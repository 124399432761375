import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { bodyLRegularUtopiaStyles, bodyMRegularUtopiaStyles } from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { renderRichTextReact } from '../../../utils/storyblokRichText';

const ProductDescription = styled.div`
  ${bodyLRegularUtopiaStyles};
  color: ${colors.navy600};
  padding-bottom: 40px;

  i {
    font-style: italic;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodyMRegularUtopiaStyles};
    color: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 30px;
  }
`;

const ProductVariantDescription = (props) => {
  const { currentVariant = null } = props;

  const [variantDescription, setVariantDescription] = useState(null);

  useEffect(() => {
    setVariantDescription(renderRichTextReact(currentVariant?.description));
  }, [currentVariant]);

  return variantDescription && <ProductDescription>{variantDescription}</ProductDescription>;
};

ProductVariantDescription.propTypes = {
  currentVariant: object,
};

export default ProductVariantDescription;
