import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EnterIcon from '../../img/PFF-brand-icons-thin-enter.svg';
import { eyebrowSStyles, eyebrowXsStyles } from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';

const FormBodyWrapper = styled.div`
  &.hide {
    display: none;
  }
`;

const Input = styled(Field).attrs({
  type: 'email',
})`
  ${eyebrowSStyles};
  background: transparent;
  border: none;

  width: 100%;

  &::placeholder {
    color: ${colors.navy600};
  }
  &::selection {
    background: pink;
  }

  @media (${bpWidth.desktopSm}) {
    ${eyebrowXsStyles};
  }
`;

const Submit = styled.button.attrs({
  type: 'submit',
})`
  background: none;
  border: none;

  padding: 0;

  svg {
    height: 21px;
  }

  svg,
  svg * {
    color: ${colors.navy600};
    fill: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      height: 19px;
    }
  }
`;

const InputSubmit = styled.div`
  border-bottom: 1px solid ${({ navFontColorLight }) => (navFontColorLight ? colors.beige100 : colors.navy600)};
  cursor: pointer;
  display: flex;

  ${Input} {
    &::placeholder {
      color: ${({ navFontColorLight }) => (navFontColorLight ? colors.beige100 : colors.navy600)};
    }
  }

  ${Submit} {
    svg,
    svg * {
      color: ${({ navFontColorLight }) => (navFontColorLight ? colors.beige100 : colors.navy600)};
      fill: ${({ navFontColorLight }) => (navFontColorLight ? colors.beige100 : colors.navy600)};
    }
  }
`;

function EmailForm(props) {
  const { handleSubmit, navFontColorLight } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormBodyWrapper>
        <InputSubmit navFontColorLight={navFontColorLight}>
          <Input name="email" placeholder="NEWSLETTER SIGN UP" />

          <Submit navFontColorLight>
            <EnterIcon alt="Form enter icon" />
          </Submit>
        </InputSubmit>
      </FormBodyWrapper>
    </form>
  );
}

EmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  navFontColorLight: PropTypes.bool,
};
EmailForm.defaultProps = {
  navFontColorLight: false,
};

export default EmailForm;
