import { isBrowser, scroller } from './src/utils/helper';
import './src/styles/global.css';

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const shouldUpdateScroll = () => false;

export const onRouteUpdate = ({ location }) => {
  let windowHash = location.hash;
  let offset = 0;
  let duration = 1000;

  if (isBrowser) {
    if (window.gatsby_scroll_offset) {
      offset = window.gatsby_scroll_offset;
    }
    if (window.gatsby_scroll_duration) {
      duration = window.gatsby_scroll_duration;
    }
    if (window.gatsby_scroll_hash) {
      windowHash = window.gatsby_scroll_hash;
    }

    if (windowHash) {
      setTimeout(() => scroller(windowHash, offset), duration);
    }

    window.gatsby_scroll_offset = undefined;
    window.gatsby_scroll_duration = undefined;
    window.gatsby_scroll_hash = undefined;
  }
};
