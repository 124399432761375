import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { bodyLRegularBrownStyles, bodySRegularBrownStyles } from '../../styles/typography';
import { bpWidthTailwind } from '../../styles/variables';

const ThreeColumnsBlokCol = styled(Col)`
  ${bodySRegularBrownStyles};

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodyLRegularBrownStyles};
  }

  p {
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a.link-component {
    margin-top: 0.75rem;
    display: inline-flex;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const ThreeColumnsBlok = ({ blok }) => {
  const { firstBloks, secondBloks, thirdBloks, customCssClass } = blok;

  return (
    <ThreeColumnsBlokCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={customCssClass}>
      <div className="flex flex-wrap flex-col sm:flex-row justify-between three-columns-blok-container">
        <div className="w-full sm:w-[30%] first-col">
          {firstBloks?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
        <div className="w-full sm:w-[30%] second-col">
          {secondBloks?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
        <div className="w-full sm:w-[30%] third-col">
          {thirdBloks?.map((nestedBlok) => (
            <Row key={nestedBlok._uid}>
              <StoryblokComponent blok={nestedBlok} />
            </Row>
          ))}
        </div>
      </div>
    </ThreeColumnsBlokCol>
  );
};

ThreeColumnsBlok.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default ThreeColumnsBlok;
