import React from 'react';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';

const EmbeddedVideoContainer = styled.div`
  margin-bottom: 32px;
`;

const EmbeddedVideo = ({ blok }) => {
  const { source } = blok;

  return (
    <EmbeddedVideoContainer {...storyblokEditable(blok)} className="embedded-video">
      {source.filename && (
        <video controls width="100%">
          <source src={source.filename} />
          Sorry, your browser does not support embedded videos.
        </video>
      )}
    </EmbeddedVideoContainer>
  );
};

EmbeddedVideo.propTypes = {
  blok: object.isRequired,
};

export default EmbeddedVideo;
