import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Link, navigate } from 'gatsby';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SHOP_URL } from '../../../constants';
import { useAppState } from '../../../context/app-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../../styles/buttons';
import {
  bodyLinkStyles,
  bodyMRegularUtopiaStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  bodyXxxsRegularBrownStyles,
} from '../../../styles/typography';
import { bpWidth, colors } from '../../../styles/variables';
import { isBrowser } from '../../../utils/helper';
import { tiktokClickButton } from '../../../utils/tiktokEvents';
import FormikWrapper from './FormikWrapper';
import Social from './Social';

const FooterContainerRow = styled(Row)`
  background: ${colors.beige100};
  border-top: 1px solid ${colors.gray100};
  position: relative;
  z-index: 20;

  height: fit-content;
`;

const FooterCol = styled(Col)`
  padding: 50px 0 60px 0;

  @media (${bpWidth.desktopSm}) {
    padding: 40px 0 50px;
  }

  @media (${bpWidth.tablet}) {
    padding: 40px 0;
  }
`;

const FooterRow = styled(Row)`
  background: ${colors.beige100};

  @media (${bpWidth.tablet}) {
    display: flex;
    justify-content: space-between;
  }

  @media (${bpWidth.mobile}) {
    flex-direction: column;
  }

  > ${Col}:first-child {
    @media (${bpWidth.tablet}) {
      padding-right: 40px;
    }
  }
`;

const PrimarySecondaryRow = styled(Row)`
  justify-content: space-between;
`;

const PrimaryCol = styled(Col)`
  display: none;

  @media (${bpWidth.tablet}) {
    display: flex;
    flex-direction: column;
    margin-top: -4px;
    padding: 0 10px 10px 0;

    a {
      ${bodyMRegularUtopiaStyles};
      ${bodyLinkStyles};
      color: ${colors.navy600};
      text-transform: none;

      &:hover {
        color: inherit;
      }
    }
  }
`;

const SecondaryCol = styled(Col)`
  display: flex;
  flex-direction: column;

  a {
    ${bodySRegularBrownStyles};
    ${bodyLinkStyles};
    color: ${colors.navy600};
    text-transform: none;

    &:first-child {
      padding-bottom: 30px;
    }

    &:hover {
      color: inherit;
    }
  }

  @media (${bpWidth.desktopSm}) {
    a {
      ${bodyXxsRegularBrownStyles};
      ${bodyLinkStyles};
      color: ${colors.navy600};

      &:first-child {
        padding-bottom: 20px;
      }

      &:hover {
        color: inherit;
      }
    }
  }

  @media (${bpWidth.tablet}) {
    padding: 0;
  }

  @media (${bpWidth.mobile}) {
    padding-right: 0;
  }
`;

const FooterMiddleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (${bpWidth.desktopSm}) {
    padding-left: 35px;
  }

  @media (${bpWidth.tablet}) {
    padding-left: 0;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 40px;
  }
`;

const FooterRightCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (${bpWidth.tablet}) {
    flex-flow: row wrap;

    > div:nth-child(1) {
      flex-basis: 100%;
    }
  }
`;

const ShopButtonLink = styled.a`
  text-decoration: none;
  align-self: flex-end;

  button {
    ${btnLgPrimaryThinOrangeStyles};
    cursor: pointer;
    width: auto;
    padding: 0 20px;

    :hover {
      border-color: ${colors.orange700};
    }
  }

  @media (${bpWidth.desktopSm}) {
    button {
      ${btnSmPrimaryThinOrangeStyles};
      cursor: pointer;
      width: auto;
      padding: 0 20px;

      :hover {
        border-color: ${colors.orange700};
      }
    }
  }

  @media (${bpWidth.tablet}) {
    display: none;
  }
`;

const LogoPrivacyDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (${bpWidth.tablet}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 0;
    padding-top: 40px;
    flex-basis: 50%;
  }

  @media (max-width: 660px) {
    flex-direction: column-reverse;
    flex-basis: 100%;
    padding-top: 0;
  }
`;

const CookieCopy = styled.p`
  ${bodyXxsRegularBrownStyles}

  @media (${bpWidth.desktopSm}) {
    ${bodyXxxsRegularBrownStyles}
  }

  @media (${bpWidth.tablet}) {
    padding-top: 40px;
    width: 85%;
  }

  a {
    ${bodyXxsRegularBrownStyles}
    ${bodyLinkStyles}
    color: ${colors.cerulean400};
    text-transform: none;
    cursor: pointer;

    &:hover {
      color: ${colors.cerulean700};
    }

    @media (${bpWidth.desktopSm}) {
      ${bodyXxxsRegularBrownStyles}
      ${bodyLinkStyles}
      color: ${colors.cerulean400};
      text-transform: none;

      &:hover {
        color: ${colors.cerulean700};
      }
    }
  }
`;

const PGLogoCopyrightContainer = styled.div`
  ${bodyXxsRegularBrownStyles};
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  img {
    width: 69px;
    height: 30px;
  }

  p {
    padding-top: 20px;
  }

  @media (${bpWidth.desktopSm}) {
    ${bodyXxxsRegularBrownStyles};
    padding-right: 35px;
  }

  @media (${bpWidth.tablet}) {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
    align-items: center;

    img {
      width: 53px;
      height: 23px;
    }

    p {
      padding: 0 20px 0 0;
    }
  }

  @media (${bpWidth.mobile}) {
    padding-top: 20px;

    p {
      padding: 0 20px 0 0;
    }
  }
`;

const PrivacyLinksContainer = styled(PGLogoCopyrightContainer)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;
  padding-top: 20px;

  div {
    text-align: right;
    width: fit-content;
    padding-right: 20px;

    a {
      ${bodyXsRegularBrownStyles};
      ${bodyLinkStyles};
      color: ${colors.navy600};
      text-transform: none;

      &:hover {
        color: inherit;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    div a {
      ${bodyXxsRegularBrownStyles};
      ${bodyLinkStyles};
      font-size: 10px !important;
      color: ${colors.navy600};
      text-transform: none;

      &:hover {
        color: inherit;
      }
    }
  }

  @media (${bpWidth.tablet}) {
    display: none;
  }

  @media (${bpWidth.mobile}) {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    div {
      padding-right: 10px;
    }

    div:last-child {
      padding-right: 0;
    }
  }
`;

const PrivacyLinksContainerTablet = styled(PrivacyLinksContainer)`
  display: none;

  @media (${bpWidth.tablet}) {
    display: flex;
    padding-top: 40px;
  }

  @media (${bpWidth.mobile}) {
    display: none;
  }
`;

function Footer({ blok }) {
  const { logo, privacyPolicyText, socialLinks, navigationLinks, legalLinks, rightsText } = blok;

  const copyrightDate = new Date().getFullYear();
  const { primaryNavLinks = [] } = useAppState();
  const { setFooterBlok } = useAppState();
  const ttq = isBrowser ? window.ttq || {} : {};

  useIsomorphicLayoutEffect(() => {
    setFooterBlok(blok);
  }, [blok]);

  const shopButtonClick = (e) => {
    e.preventDefault();

    if (_.has(ttq, 'track')) {
      // ensure ttq has been loaded
      tiktokClickButton(ttq);
    }

    return navigate('/shop');
  };

  // see WEBDEV-898
  const renderCookieBanner = () => {
    const consentBanner = document.querySelector('#consent-banner');
    if (consentBanner) {
      consentBanner.style.display = 'block';
    }
    if (window?.truste?.bn) {
      window.truste.bn.openBanner();
    }
  };

  return (
    <FooterContainerRow>
      <FooterCol xsOffset={1} mdOffset={0} xs={6} sm={6} md={10} lg={12}>
        <FooterRow>
          <Col mdOffset={1} xs={8} sm={4} md={1} lg={2}>
            <PrimarySecondaryRow>
              {/* Primary links */}
              <PrimaryCol md={false} lg={false}>
                {primaryNavLinks.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </PrimaryCol>

              {/* Secondary links */}
              <SecondaryCol>
                {navigationLinks.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </SecondaryCol>
            </PrimarySecondaryRow>
          </Col>

          <FooterMiddleCol xs={7} sm={4} md={3} lg={3}>
            {/* Subscription form */}
            <Row>
              <Col xs={8} sm={8} md={10} lg={12}>
                <FormikWrapper privacyPolicyText={privacyPolicyText} />
              </Col>
            </Row>

            {/* Social media links */}
            <Row>
              <Social links={socialLinks} />
              <PrivacyLinksContainer>
                {legalLinks.map((nestedBlok) => (
                  <div key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} />
                  </div>
                ))}
              </PrivacyLinksContainer>
            </Row>
          </FooterMiddleCol>

          {/* PG logo & policy/third links */}
          <FooterRightCol mdOffset={1} xs={8} sm={8} md={3} lg={4}>
            {/* cookies copy */}
            <Row>
              <Col xs={8} sm={8} md={10} lg={12}>
                <CookieCopy>
                  To adhere with individual state privacy laws, you can choose to opt out of optional cookies on our
                  site or customize your settings via{' '}
                  <a onClick={renderCookieBanner} onKeyDown={renderCookieBanner} role="button" tabIndex={0}>
                    Cookie Settings
                  </a>
                  . For more detail, please read our <Link to="/privacy-policy/">Privacy Policy</Link>.
                </CookieCopy>
              </Col>
            </Row>

            <PrivacyLinksContainerTablet>
              {legalLinks.map((nestedBlok) => (
                <div key={nestedBlok._uid}>
                  <StoryblokComponent blok={nestedBlok} />
                </div>
              ))}
            </PrivacyLinksContainerTablet>
            <LogoPrivacyDiv>
              <PGLogoCopyrightContainer>
                {/* PG Logo */}
                {logo?.filename && (
                  <a href="https://www.piaggiogroup.com/en" target="_blank" rel="noreferrer">
                    <img src={logo.filename} alt="Piaggio Group Logo" />
                  </a>
                )}
                {/* Copyright Date */}
                <p>
                  © {copyrightDate} {rightsText}
                </p>
              </PGLogoCopyrightContainer>

              <ShopButtonLink href={SHOP_URL} target="_blank" rel="noreferrer" onClick={shopButtonClick}>
                <button type="button">Shop</button>
              </ShopButtonLink>
            </LogoPrivacyDiv>
          </FooterRightCol>
        </FooterRow>
      </FooterCol>
    </FooterContainerRow>
  );
}

Footer.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Footer;
