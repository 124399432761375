/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { StoreContext } from '../../context/store-context';
import Chevron from '../../img/arrow-LNavy.svg';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../styles/buttons.js';
import {
  bodyMBoldBrownStyles,
  bodySBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
} from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';
import { currencyFormatter } from '../../utils/currencyFormatter.js';
import ProductVariantSelector from '../ProductDetail/ProductVariantSelector.jsx';

const AccessoryItemContainerRow = styled(Row)`
  opacity: ${(props) => (props.accessoriesExpanded ? '1' : '0')};
  transition: opacity 250ms ease;
`;

const AccessoryItemContainerCol = styled(Col)`
  border-top: ${(props) => (props.index === 0 ? '0' : '1px')} solid ${colors.gray100};
  padding: 20px 0;
  ${(props) =>
    props.index === props.itemArrLength - 1
      ? `
      padding-bottom: ${props.lastItemBottomPadding}px;
    `
      : ''};
`;

const AccessoryItemRowOne = styled(Row)`
  cursor: pointer;
  user-select: none;
`;
const AccessoryItemRowTwo = styled(Row)`
  margin-top: 10px;
`;
const AccessoryItemRowThree = styled(Row)`
  margin-top: 20px;
`;
const AccessoryItemRowFour = styled(Row)`
  margin-top: 20px;
`;
const AccessoryItemRowFive = styled(Row)`
  margin-top: 20px;
`;

const ProductVariantSelectorCol = styled(Col)`
  .selectedVariant {
    height: 16px !important;
    width: 16px !important;
  }

  div.boardwalkBeige,
  div.red,
  div.signalRed,
  div.sparkCitron,
  div.shadowBlack,
  div.twilightBlue,
  div.thunderGray,
  div.gray,
  div.black,
  div.rapidBlue,
  div.espressoBrown {
    height: 12px;
    width: 12px;
  }
`;

const ProductName = styled.p`
  ${eyebrowSStyles};
  text-align: left;

  @media (${bpWidth.desktopSm}) {
    ${eyebrowXsStyles}
  }
`;

const ProductDetailsToggleCol = styled(Col)`
  text-align: right;

  svg {
    vertical-align: middle;
    transform: ${(props) => (props.accessoryIsExpanded ? 'rotate(270deg)' : 'rotate(90deg)')};
    transition: 0.1s transform;
    margin-right: 3px;
  }
`;

const ProductPriceTotal = styled.p`
  ${bodyMBoldBrownStyles};
  text-align: right;
  letter-spacing: -0.16px;

  @media (${bpWidth.desktopSm}) {
    ${bodySBoldBrownStyles};
  }
`;

const ProductVariantName = styled.p`
  ${bodyXsRegularBrownStyles};
  text-align: left;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
  }

  @media (${bpWidth.tablet}) {
    ${bodyXsRegularBrownStyles};
  }

  @media (${bpWidth.mobile}) {
    ${bodyXxsRegularBrownStyles};
  }
`;

const ProductionDescription = styled.p`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.gray300};
  text-align: left;
  padding-right: 25px;

  @media (${bpWidth.mobile}) {
    color: ${colors.gray400};
  }
`;

const VariantImage = styled.img`
  width: 100%;
  max-width: 100%;
`;

const AddToCartButton = styled.button`
  ${btnLgPrimaryThinOrangeStyles};
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${btnSmPrimaryThinOrangeStyles};
  }
`;

const AccessoryItem = ({
  index,
  itemArrLength,
  accessoryData,
  accessoriesExpanded,
  handleAccessoryItemDidAnimate,
  handleAccessoryIsAnimating,
  handleItemExpand,
  handleItemCollapse,
  lastItemBottomPadding,
}) => {
  const { variants, title, description, featuredImage } = accessoryData;
  const [initialVariant] = variants;

  const { addVariantToCart, didJustAddToCart, isOpen: cartIsOpen } = useContext(StoreContext);

  const [variant, setVariant] = useState({ ...initialVariant });
  const hasVariants = variants.length > 1;
  const available = variant.availableForSale;

  const [clickedAddToCart, setClickedAddToCart] = useState(false);

  const handleVariantSelection = (_variant) => {
    setVariant(_variant);
  };

  const handleAddToCart = (_variant) => {
    if (available) {
      addVariantToCart(_variant, 1);
      setClickedAddToCart(true);
    }
  };

  const [accessoryIsExpanded, setAccessoryIsExpanded] = useState(false);
  const handleAccessoryExpandToggle = () => {
    setAccessoryIsExpanded(!accessoryIsExpanded);
    handleItemExpand(index);
  };

  // reset clickedAddToCart
  useEffect(() => {
    if (!didJustAddToCart) {
      setClickedAddToCart(false);
    }
  }, [didJustAddToCart]);

  // reset accessoryIsExpanded when cart is collapsed
  useEffect(() => {
    if (!cartIsOpen || !accessoriesExpanded) {
      setAccessoryIsExpanded(false);
      handleItemCollapse();
    }
  }, [cartIsOpen, accessoriesExpanded]);

  return (
    <AccessoryItemContainerRow accessoriesExpanded={accessoriesExpanded}>
      <Col xs={8} sm={8} md={10} lg={12}>
        <Row>
          <AccessoryItemContainerCol
            xsOffset={1}
            xs={6}
            md={8}
            lg={10}
            index={index}
            itemArrLength={itemArrLength}
            accessoriesExpanded={accessoriesExpanded}
            lastItemBottomPadding={lastItemBottomPadding}
          >
            {/* product name and down/up caret */}
            <AccessoryItemRowOne between="xs" onClick={handleAccessoryExpandToggle} index={index}>
              <Col xs={5} md={6} lg={7}>
                <ProductName>{title}</ProductName>
              </Col>
              <ProductDetailsToggleCol xs={3} md={4} lg={5} accessoryIsExpanded={accessoryIsExpanded}>
                <Chevron />
              </ProductDetailsToggleCol>
            </AccessoryItemRowOne>

            <AnimateHeight
              duration={250}
              height={accessoryIsExpanded ? 'auto' : 0}
              animateOpacity
              onAnimationStart={() => {
                handleAccessoryIsAnimating(true);
              }}
              onAnimationEnd={() => {
                setTimeout(() => {
                  handleAccessoryIsAnimating(false);
                  handleAccessoryItemDidAnimate();
                }, 300);
              }}
            >
              {/* product variant name, if the product has variants */}
              {hasVariants && (
                <AccessoryItemRowTwo>
                  <Col xs={8} md={10} lg={12}>
                    <ProductVariantName>{variant.title}</ProductVariantName>
                  </Col>
                </AccessoryItemRowTwo>
              )}

              {/* product description and image */}
              <AccessoryItemRowThree>
                <Col xs={8} sm={5} md={6} lg={7}>
                  <ProductionDescription>{description}</ProductionDescription>
                </Col>
                <Col xs={8} sm={3} md={4} lg={5}>
                  <VariantImage src={hasVariants ? variant.image.originalSrc : featuredImage.src} alt={variant.title} />
                </Col>
              </AccessoryItemRowThree>

              {/* product price */}
              <AccessoryItemRowFour>
                <ProductVariantSelectorCol xs={5} md={6} lg={7}>
                  {hasVariants && (
                    <ProductVariantSelector
                      variants={variants}
                      activeVariant={variant}
                      handleVariantSelection={handleVariantSelection}
                    />
                  )}
                </ProductVariantSelectorCol>
                <Col xs={3} md={4} lg={5}>
                  <ProductPriceTotal>{currencyFormatter(variant.price, 0)}</ProductPriceTotal>
                </Col>
              </AccessoryItemRowFour>

              {/* add to cart button */}
              <AccessoryItemRowFive>
                <Col xs={8} md={10} lg={12}>
                  <AddToCartButton
                    onClick={() => {
                      handleAddToCart(variant);
                    }}
                  >
                    {didJustAddToCart && clickedAddToCart ? 'Added!' : 'Add'}
                  </AddToCartButton>
                </Col>
              </AccessoryItemRowFive>
            </AnimateHeight>
          </AccessoryItemContainerCol>
        </Row>
      </Col>
    </AccessoryItemContainerRow>
  );
};

AccessoryItem.propTypes = {
  accessoriesExpanded: bool,
  handleAccessoryItemDidAnimate: func,
  handleAccessoryIsAnimating: func,
};
AccessoryItem.defaultProps = {
  accessoriesExpanded: false,
};

export default AccessoryItem;
