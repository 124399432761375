import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { any, string } from 'prop-types';
import styled from 'styled-components';
import { bodySBoldBrownStyles, bodyXsRegularBrownStyles, bodyXxsRegularBrownStyles } from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';
import { formatPublishDate } from '../../utils';

const Header = styled.div`
  margin-bottom: 0;
  margin-top: 10px;
  width: 100%;
  overflow-wrap: break-word;
  ${bodySBoldBrownStyles};

  @media (${bpWidth.desktopSm}) {
    width: calc((80vw - 2vw * 2) / 3);
  }

  @media (${bpWidth.tablet}) {
    width: calc((100vw - 2vw * 2) / 3);
  }

  @media (${bpWidth.mobile}) {
    width: 100%;
  }
`;

const PostData = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray300};

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray300};
  }
`;

const PressPostHeader = (props) => {
  const { header, publishDate, publisher } = props;

  return (
    <Col xs={8} md={12}>
      <PostData>
        {formatPublishDate(publishDate)}
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        {publisher}
      </PostData>
      <Row>{header && <Header>{header}</Header>}</Row>
    </Col>
  );
};

PressPostHeader.propTypes = {
  publishDate: string,
  publisher: string,
  header: any,
};
PressPostHeader.defaultProps = {
  publishDate: '',
  publisher: '',
};

export default PressPostHeader;
