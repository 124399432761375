import React from 'react';
import _ from 'lodash';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useRootState } from '../../../context/root-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { bodyLinkStyles, bodySRegularBrownStyles, bodyXxsRegularBrownStyles } from '../../../styles/typography';
import { bpWidthTailwind } from '../../../styles/variables';

const AffirmCopy = styled.p`
  ${bodyXxsRegularBrownStyles};
  padding-bottom: 10px;

  a.affirm-modal-trigger {
    ${bodyLinkStyles};
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${bodySRegularBrownStyles};
  }
`;

const ProductVariantAffirm = (props) => {
  const { currentVariant = null } = props;
  const { shopifyProduct, productAffirms } = useRootState();

  useIsomorphicLayoutEffect(() => {
    if (shopifyProduct && currentVariant) {
      const slug = _.kebabCase(shopifyProduct.handle);
      const variantTitle = _.kebabCase(currentVariant.title);
      const affirmVariantComponent = productAffirms[`product-affirm-${slug}-${variantTitle}`];

      if (affirmVariantComponent) {
        setTimeout(() => affirmVariantComponent.render('.variantAffirm'));
      }
    }
  }, [currentVariant, shopifyProduct, productAffirms]);

  return <AffirmCopy className="variantAffirm" />;
};

ProductVariantAffirm.propTypes = {
  currentVariant: object,
};

export default React.memo(ProductVariantAffirm);
