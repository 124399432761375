import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createStorefrontApiClient } from '@shopify/storefront-api-client';
import _ from 'lodash';
import { any } from 'prop-types';
import { PRODUCT_IDS } from '../constants';
import { useSsr } from '../hooks';
import useLocation from '../hooks/useLocation';
import {
  mutationCartLinesAdd,
  mutationCartLinesRemove,
  mutationCartLinesUpdate,
  mutationCreateCart,
  queryCartById,
  queryProductById,
} from '../utils';
import { dataLayerAddToCart } from '../utils/dataLayerEvents.js';
import { metaAddToCartGitamini, metaAddToCartGitaplus, metaAddToCartGitas } from '../utils/metaEvents.js';
import { redditAddToCartGitamini, redditAddToCartGitaplus, redditAddToCartGitas } from '../utils/redditEvents.js';
import { tiktokAddToCartGitamini, tiktokAddToCartGitaplus, tiktokAddToCartGitas } from '../utils/tiktokEvents.js';

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: '2024-04',
  publicAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_PUBLIC_ACCESS_TOKEN,
});

const defaultValues = {
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  cart: {
    lines: [],
    totalQuantity: 0,
  },
  didJustAddToCart: false,
  shopifyErrors: undefined,
  fetchProductOrVariant: () => {},
  initializeCart: () => {},
};

export const StoreContext = React.createContext(defaultValues);

export const useShopifyStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useShopifyStore must be used within the StoreProvider');
  }
  return context;
};

const localStorageKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
  const { pathname } = useLocation();

  const [cart, setCart] = useState(defaultValues.cart);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { isBrowser } = useSsr();
  const [shopifyErrors, setShopifyErrors] = useState(undefined);

  const ttq = isBrowser ? window.ttq || {} : {};
  const rdt = isBrowser ? window.rdt || {} : {};
  const fbq = isBrowser ? window.fbq || {} : {};

  // 1) set the cart ID in local storage
  // 2) set cart data into cart state var
  const setCartToLocalstorage = useCallback(
    // eslint-disable-next-line consistent-return
    (cartToSet) => {
      if (isBrowser) {
        if (cartToSet === null) {
          localStorage.setItem(localStorageKey, '');
          return undefined;
        }

        localStorage.setItem(localStorageKey, cartToSet.id);
      }

      setCart(cartToSet);
      setLoading(false);
    },
    [isBrowser],
  );

  const createNewCart = useCallback(async () => {
    setLoading(true);

    const { data: { cartCreate: { cart: fetchedCart } } = {}, errors } = await client.request(mutationCreateCart, {
      variables: {
        $cartInput: {
          lines: [],
        },
      },
    });

    if (!_.isEmpty(errors)) {
      setShopifyErrors(errors);
    }

    setCartToLocalstorage(fetchedCart);
  }, []);

  const fetchCartByID = useCallback(
    async (cartID) => {
      setLoading(true);

      const { data: { cart: fetchedCart } = {}, errors } = await client.request(queryCartById, {
        variables: {
          cartId: cartID,
        },
      });

      if (!_.isEmpty(errors)) {
        setShopifyErrors(errors);
      }

      // if a checkout is completed, the fetchedCart will be null
      if (!fetchedCart) {
        setCartToLocalstorage(null);
        throw new Error(`Could not fetch cartId ${cartID}`);
      }

      setCartToLocalstorage(fetchedCart);
    },
    [createNewCart],
  );

  const addCartLines = useCallback(async (cartID, lines) => {
    const {
      data: { cartLinesAdd: { cart: fetchedCart } = {} },
      errors,
    } = await client.request(mutationCartLinesAdd, {
      variables: {
        cartId: cartID,
        lines,
      },
    });

    if (!_.isEmpty(errors)) {
      setShopifyErrors(errors);
    }

    setCart(fetchedCart);
    setLoading(false);
    setDidJustAddToCart(true);
    setTimeout(() => setDidJustAddToCart(false), 2000);
  }, []);

  const removeCartLines = useCallback(async (cartID, lineIds) => {
    const {
      data: { cartLinesRemove: { cart: fetchedCart } = {} },
      errors,
    } = await client.request(mutationCartLinesRemove, {
      variables: {
        cartId: cartID,
        lineIds,
      },
    });

    if (!_.isEmpty(errors)) {
      setShopifyErrors(errors);
    }

    setCart(fetchedCart);
    setLoading(false);
  }, []);

  const updateCartLines = useCallback(async (cartID, lines) => {
    const {
      data: { cartLinesUpdate: { cart: fetchedCart } = {} },
      errors,
    } = await client.request(mutationCartLinesUpdate, {
      variables: {
        cartId: cartID,
        lines,
      },
    });

    if (!_.isEmpty(errors)) {
      setShopifyErrors(errors);
    }

    setCart(fetchedCart);
    setLoading(false);
  }, []);

  // fetches product by ID, along with variants.
  // optionally returns a variant if its title is passed in.
  const fetchProductOrVariant = useCallback(async (productID, variantID = null) => {
    const { data, errors } = await client.request(queryProductById, {
      variables: {
        id: productID,
      },
    });

    if (!_.isEmpty(errors)) {
      return undefined;
    }

    // filter variants by VariantID and return its node
    if (variantID) {
      const { node } = data.product.variants.edges.find((edge) => edge.node.id === variantID);

      return node;
    }

    return data;
  }, []);

  const initializeCart = useCallback(() => {
    setLoading(true);

    // get existing cart ID from local storage if it exists
    const existingCartID = isBrowser ? localStorage.getItem(localStorageKey) : null;

    // if it exists, fetch the cart's data from Shopify
    if (existingCartID && existingCartID !== 'null') {
      fetchCartByID(existingCartID).catch(() => {
        setCartToLocalstorage(null);

        // if we couldn't fetch an existing cart for that ID, create a new one
        createNewCart();
      });
    } else {
      // if no store cart ID, create a new cart
      createNewCart();
    }
  }, []);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = (force = true) => {
    if (force || pathname.startsWith('/shop')) {
      setIsOpen(false);
    } else {
      window.location.href = '/shop';
    }
  };

  const onToggle = (forceClose = true) => {
    if (isOpen) {
      onClose(forceClose);
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.documentElement.classList.toggle('is-locked', isOpen);
    }, 300);
  }, [isOpen]);

  const handleOutsideCartClick = ($event) => {
    if (isOpen && $event.target.id === 'outerCartEl') {
      onClose(true);
    }
  };

  // close cart when clicking outside of it
  useEffect(() => {
    document.addEventListener('click', handleOutsideCartClick);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleOutsideCartClick);
    };
  }, [isOpen]);

  // this function takes a variant ID and the quantity to be added, and adds them to the cart object,
  // which in turns gets set into the cart state var
  const addVariantToCart = async (variant, quantity) => {
    const { storefrontId: variantId } = variant;

    setLoading(true);
    if (!isOpen) {
      onOpen();
    }

    const lineItemsToUpdate = [
      {
        merchandiseId: variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    // tiktok events
    if (_.has(ttq, 'track')) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        tiktokAddToCartGitamini(ttq);
        tiktokAddToCartGitas(ttq);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        tiktokAddToCartGitaplus(ttq);
        tiktokAddToCartGitas(ttq);
      }
    }

    // reddit events
    if (_.isFunction(rdt)) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        redditAddToCartGitamini(rdt);
        redditAddToCartGitas(rdt);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        redditAddToCartGitaplus(rdt);
        redditAddToCartGitas(rdt);
      }
    }

    // meta events
    if (_.isFunction(fbq)) {
      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_BOARDWALK_BEIGE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SHADOW_BLACK) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAMINI_SPARK_CITRON)
      ) {
        metaAddToCartGitamini(fbq);
        metaAddToCartGitas(fbq);
      }

      if (
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_RAPID_BLUE) ||
        _.includes(variantId, PRODUCT_IDS.SHOPIFY_VARIANT_IDS?.GITAPLUS_ESPRESSO_BROWN)
      ) {
        metaAddToCartGitaplus(fbq);
        metaAddToCartGitas(fbq);
      }
    }

    // dataLayer event
    const transformedVariant = {
      price: variant.price,
      productTitle: variant.productTitle,
      storefrontId: variant.storefrontId,
      title: variant.title,
    };
    dataLayerAddToCart(transformedVariant);

    return addCartLines(cart.id, lineItemsToUpdate);
  };

  // this function takes a cart line ID and removes it, regardless of quantity
  const removeLineItem = (lineIds) => {
    setLoading(true);

    return removeCartLines(cart.id, lineIds);
  };

  // update the quantity of a cart line
  const updateLineItem = (id, quantity) => {
    setLoading(true);

    const lines = [
      {
        id,
        quantity,
      },
    ];

    return updateCartLines(cart.id, lines);
  };

  return (
    <StoreContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        cart,
        loading,
        didJustAddToCart,
        isOpen,
        onToggle,
        onOpen,
        onClose,
        shopifyErrors,
        fetchProductOrVariant,
        initializeCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: any,
};
