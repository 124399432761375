import React, { useContext, useMemo } from 'react';
import _ from 'lodash';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { StoreContext } from '../../context/store-context';
import EmptyCartIcon from '../../img/PFF-brand-icons-thin-mktg-cart-empty.svg';
import FullCartIcon from '../../img/PFF-brand-icons-thin-mktg-cart-full.svg';
import { bodyXxsBoldBrownStyles, bodyXxxsRegularBrownStyles } from '../../styles/typography';
import { bpWidth, colors } from '../../styles/variables';

const EmptyCartContainer = styled.div`
  * {
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
    fill: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
  }
`;

const cartIconStyled = css`
  cursor: pointer;
  width: 24px;
  max-width: 100%;

  .st0 {
    display: inherit;
  }

  @media (${bpWidth.mobile}) {
    width: 18px;
  }
`;

const EmptyCartIconStyled = styled(EmptyCartIcon)`
  ${cartIconStyled};
`;

const FullCartContainer = styled.div`
  * {
    color: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
    fill: ${({ darkMode }) => (darkMode ? colors.beige100 : colors.navy600)} !important;
  }

  position: relative;
`;

const FullCartIconStyled = styled(FullCartIcon)`
  ${cartIconStyled};
`;

const LineItemNumber = styled.div`
  ${bodyXxsBoldBrownStyles};

  line-height: 1;
  position: absolute;
  top: 1px;
  left: 16px;
  pointer-events: none;

  @media (${bpWidth.mobile}) {
    ${bodyXxxsRegularBrownStyles};
    line-height: 1;
    left: 12px;
  }
`;

const CartIcon = (props) => {
  const { darkMode = false } = props;
  const { cart, onToggle: handleCartToggle } = useContext(StoreContext);

  const cartItemsQuantity = useMemo(() => {
    if (_.isEmpty(cart.lines?.edges)) {
      return 0;
    }

    return cart.lines?.edges
      ?.map((edge) => edge?.node?.quantity)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
  }, [cart]);

  if (cart.totalQuantity === 0) {
    return (
      <EmptyCartContainer darkMode={darkMode}>
        <EmptyCartIconStyled alt="empty cart icon" onClick={handleCartToggle} />
      </EmptyCartContainer>
    );
  }

  return (
    <FullCartContainer darkMode={darkMode}>
      <FullCartIconStyled alt="full cart icon" onClick={handleCartToggle} />
      <LineItemNumber>{cartItemsQuantity}</LineItemNumber>
    </FullCartContainer>
  );
};

CartIcon.propTypes = {
  darkMode: bool,
};
CartIcon.defaultProps = {
  darkMode: false,
};

export default CartIcon;
