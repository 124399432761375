import React from 'react';
import _ from 'lodash';
import { array, func, object } from 'prop-types';
import styled from 'styled-components';
import { bpWidth, colors } from '../../styles/variables';

const SelectedVariant = styled.div`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 10px;
  height: 26px;
  width: 26px;
  box-sizing: border-box;

  &.active {
    border: 1px solid ${colors.gray200};
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 10px;
    margin-bottom: 0;
    height: 21px;
    width: 21px;
  }
`;

const VariantSelector = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  div.boardwalkBeige,
  div.red,
  div.signalRed,
  div.sparkCitron,
  div.shadowBlack,
  div.twilightBlue,
  div.thunderGray,
  div.gray,
  div.black,
  div.rapidBlue,
  div.espressoBrown {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  div.boardwalkBeige {
    background-color: ${colors.boardwalkBeige};
  }
  div.red {
    background-color: ${colors.red};
  }
  div.signalRed {
    background-color: ${colors.signalRed};
  }
  div.sparkCitron {
    background-color: ${colors.sparkCitron};
  }
  div.shadowBlack {
    background-color: ${colors.black};
  }
  div.twilightBlue {
    background-color: ${colors.twilightBlue};
  }
  div.thunderGray,
  div.gray {
    background-color: ${colors.thunderGray};
  }
  div.black {
    background-color: ${colors.black};
  }
  div.rapidBlue {
    background-color: ${colors.rapidBlue};
  }
  div.espressoBrown {
    background-color: ${colors.espressoBrown};
  }

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 15px;

    div.boardwalkBeige,
    div.red,
    div.signalRed,
    div.sparkCitron,
    div.shadowBlack,
    div.twilightBlue,
    div.thunderGray,
    div.rapidBlue,
    div.espressoBrown {
      height: 15px;
      width: 15px;
    }
  }
`;

const VariantOption = styled.div``;

const ProductVariantSelector = (props) => {
  const { variants, activeVariant, handleVariantSelection } = props;

  return (
    <VariantSelector>
      {variants.map((variant) => (
        <SelectedVariant
          key={_.camelCase(variant.title)}
          className={activeVariant.storefrontId === variant.storefrontId ? 'selectedVariant active' : 'selectedVariant'}
        >
          <VariantOption
            id={activeVariant.storefrontId === variant.storefrontId ? 'active' : ''}
            className={_.camelCase(variant.title)}
            onClick={() => {
              handleVariantSelection(variant);
            }}
          />
        </SelectedVariant>
      ))}
    </VariantSelector>
  );
};

ProductVariantSelector.propTypes = {
  variants: array,
  activeVariant: object,
  handleVariantSelection: func,
};
ProductVariantSelector.defaultProps = {
  variants: [],
  activeVariant: {},
  handleVariantSelection: () => {},
};

export default ProductVariantSelector;
