import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import throttle from 'lodash/throttle';
import { array, string } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { bodyXlItalicUtopiaStyles, bodyXxxlItalicUtopiaStyles } from '../../../styles/typography';
import { bpWidth, sizes } from '../../../styles/variables';

const BottomCarouselRow = styled(Row)`
  h3 {
    ${bodyXxxlItalicUtopiaStyles};
    margin-bottom: 40px;
    margin-top: 100px;
  }

  @media (${bpWidth.desktopSm}) {
    h3 {
      ${bodyXlItalicUtopiaStyles};
      font-weight: normal !important;
      margin-bottom: 30px;
      margin-top: 80px;
    }
  }

  @media (${bpWidth.tablet}) {
    h3 {
      font-weight: normal !important;
      margin-bottom: 20px;
      margin-top: 60px;
    }
  }
`;

const SlidesCol = styled(Col)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: all 0.2s;

  &.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
`;

const Slides = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 500px;
  padding-bottom: 40px;
  user-select: none;

  img {
    pointer-events: none;
  }

  @media (${bpWidth.desktopSm}) {
    max-height: 400px;
    padding-bottom: 30px;
  }

  @media (${bpWidth.mobile}) {
    max-height: 300px;
    padding-bottom: 20px;
  }
`;

const Slide = styled.div`
  margin-right: 24px;
  min-width: 462px;

  &:first-child {
    margin-left: 8.333333333333334vw;
  }

  &:last-child {
    margin-right: 8.333333333333334vw;
  }

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained.slideImage {
    height: 100%;
    width: 100%;
  }

  @media (${bpWidth.desktopSm}) {
    margin-right: 20px;
    min-width: 370px;

    &:first-child {
      margin-left: 10vw;
    }

    &:last-child {
      margin-right: 10vw;
    }
  }
  @media (${bpWidth.tablet}) {
    margin-right: 16px;
    min-width: 376px;

    &:first-child {
      margin-left: 12.5vw;
    }

    &:last-child {
      margin-right: 12.5vw;
    }
  }
  @media (${bpWidth.mobile}) {
    margin-right: 10px;
    min-width: 308px;
  }
`;

const BottomCarousel = (props) => {
  const { activeVariant, bottomCarouselData } = props;
  const { viewWidth } = useViewport();

  const [bottomCarousel, setBottomCarousel] = useState(null);

  useEffect(() => {
    setBottomCarousel(bottomCarouselData);
  }, [activeVariant, bottomCarouselData]);

  // Drag & scroll with mouse
  const slidesRef = useRef(null);
  let clickedDown = false;
  let startX;
  let scrollLeft;

  useEffect(() => {
    const slidesEl = slidesRef.current;

    const mouseDownHandler = (e) => {
      clickedDown = true;
      ({ scrollLeft } = slidesEl);
      slidesEl.classList.add('active');
      startX = e.pageX - slidesEl.offsetLeft;
    };

    const mouseLeaveHandler = () => {
      clickedDown = false;
      slidesEl.classList.remove('active');
    };

    const mouseMoveHandler = (e) => {
      if (clickedDown) {
        e.preventDefault();
        const currentX = e.pageX - slidesEl.offsetLeft;
        const scroll = currentX - startX;
        slidesEl.scrollLeft = scrollLeft - scroll;
      }
    };

    // Set up event listeners
    const throttledMouseMoveHandler = throttle(mouseMoveHandler, 10);

    if (slidesEl) {
      slidesEl.addEventListener('mousedown', mouseDownHandler);
      slidesEl.addEventListener('mouseleave', mouseLeaveHandler);
      slidesEl.addEventListener('mouseup', mouseLeaveHandler);
      slidesEl.addEventListener('mousemove', throttledMouseMoveHandler);
    }

    // Remove event listeners
    return () => {
      if (slidesEl) {
        slidesEl.removeEventListener('mousedown', mouseDownHandler);
        slidesEl.removeEventListener('mouseleave', mouseLeaveHandler);
        slidesEl.removeEventListener('mouseup', mouseLeaveHandler);
        slidesEl.removeEventListener('mousemove', throttledMouseMoveHandler);
      }
    };
  });

  return (
    <BottomCarouselRow id="bottomCarousel">
      {bottomCarousel?.length > 0 && (
        <Col xs={8} sm={8} md={10} lg={12}>
          <Row>
            <Col xsOffset={1} xs={7} sm={7} md={9} lg={11}>
              <h3>Out in the real world</h3>
            </Col>
          </Row>
          <Row>
            <SlidesCol xs={8} sm={8} md={10} lg={12} ref={slidesRef}>
              <Slides>
                {bottomCarousel.map((slide) => {
                  if (!slide.assetLg?.filename) {
                    return undefined;
                  }

                  const loadType = 'lazy';

                  let imageUrl =
                    slide.assetLg?.filename ||
                    slide.assetMd?.filename ||
                    slide.assetSm?.filename ||
                    slide.assetXs?.filename;

                  if (viewWidth < sizes.containerMinWidth.tablet) {
                    if (slide.assetXs?.filename) {
                      imageUrl = slide.assetXs.filename;
                    }
                  } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
                    if (slide.assetSm?.filename) {
                      imageUrl = slide.assetSm.filename;
                    }
                  } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
                    if (slide.assetMd?.filename) {
                      imageUrl = slide.assetMd.filename;
                    }
                  }

                  /** @type { GetGatsbyImageOptions } */
                  const options = {
                    layout: slide.layout,
                    quality: slide.quality,
                  };

                  const artDirected = [];
                  if (slide.assetXs?.filename) {
                    artDirected.push({
                      media: `(${bpWidth.mobile})`,
                      image: getGatsbyImage(slide.assetXs.filename, options),
                    });
                  }

                  if (slide.assetSm?.filename) {
                    artDirected.push({
                      media: `(${bpWidth.tablet})`,
                      image: getGatsbyImage(slide.assetSm.filename, options),
                    });
                  }

                  if (slide.assetMd?.filename) {
                    artDirected.push({
                      media: `(${bpWidth.desktopSm})`,
                      image: getGatsbyImage(slide.assetMd.filename, options),
                    });
                  }

                  const imageData = withArtDirection(getGatsbyImage(imageUrl, options), artDirected);
                  const media = <GatsbyImage image={imageData} loading={loadType} alt="" className="slideImage" />;

                  return <Slide key={slide._uid}>{media}</Slide>;
                })}
              </Slides>
            </SlidesCol>
          </Row>
        </Col>
      )}
    </BottomCarouselRow>
  );
};

BottomCarousel.propTypes = {
  activeVariant: string,
  bottomCarouselData: array,
};
BottomCarousel.defaultProps = {
  bottomCarouselData: [],
};

export default React.memo(BottomCarousel);
