export const mutationCreateCart = `mutation createCart($cartInput: CartInput) {
  cartCreate(input: $cartInput) {
    cart {
      checkoutUrl
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      discountAllocations {
        ... on CartAutomaticDiscountAllocation {
          title
        }
        discountedAmount {
          amount
        }
      }
      discountCodes {
        code
      }
      id
      lines(first: 10) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
                title
              }
            }
            quantity
          }
        }
      }
      totalQuantity
    }
    userErrors {
      code
      message
      field
    }
  }
}`;

export const queryCartById = `query($cartId: ID!) {
  cart(id: $cartId) {
    checkoutUrl
    cost {
      totalAmount {
        amount
        currencyCode
      }
    }
    discountAllocations {
      ... on CartAutomaticDiscountAllocation {
        title
      }
      discountedAmount {
        amount
      }
    }
    discountCodes {
      code
    }
    id
    lines(first: 10) {
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              image {
                url
              }
              price {
                amount
                currencyCode
              }
              product {
                title
              }
              title
            }
          }
          quantity
        }
      }
    }
    totalQuantity
  }
}`;

export const mutationCartLinesAdd = `mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      checkoutUrl
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      discountAllocations {
        ... on CartAutomaticDiscountAllocation {
          title
        }
        discountedAmount {
          amount
        }
      }
      discountCodes {
        code
      }
      id
      lines(first: 10) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
                title
              }
            }
            quantity
          }
        }
      }
      totalQuantity
    }
    userErrors {
      code
      message
      field
    }
  }
}`;

export const mutationCartLinesRemove = `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      checkoutUrl
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      discountAllocations {
        ... on CartAutomaticDiscountAllocation {
          title
        }
        discountedAmount {
          amount
        }
      }
      discountCodes {
        code
      }
      id
      lines(first: 10) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
                title
              }
            }
            quantity
          }
        }
      }
      totalQuantity
    }
    userErrors {
      code
      message
      field
    }
  }
}`;

export const mutationCartLinesUpdate = `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
  cartLinesUpdate(cartId: $cartId, lines: $lines) {
    cart {
      checkoutUrl
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      discountAllocations {
        ... on CartAutomaticDiscountAllocation {
          title
        }
        discountedAmount {
          amount
        }
      }
      discountCodes {
        code
      }
      id
      lines(first: 10) {
        edges {
          node {
            id
            merchandise {
              ... on ProductVariant {
                id
                image {
                  url
                }
                price {
                  amount
                  currencyCode
                }
                product {
                  title
                }
                title
              }
            }
            quantity
          }
        }
      }
      totalQuantity
    }
    userErrors {
      code
      message
      field
    }
  }
}`;

export const queryProductById = `query getProductById($id: ID!) {
  product(id: $id) {
    title
    id
    variants(first: 10) {
      edges {
        node {
          id
          availableForSale
          quantityAvailable
          title
        }
      }
    }
  }
}`;
