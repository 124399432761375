import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { array, string } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { bpWidth, colors, sizes } from '../../../styles/variables';
import GridCarousel from './GridCarousel.jsx';
import SwipeCarousel from './SwipeCarousel.jsx';

const LargeCarouselCol = styled(Col)`
  .slick-list {
    width: 100%;
    padding-top: 0 !important;
  }

  .slick-slide {
    height: 700px;

    img {
      height: 700px;
    }

    video {
      height: 700px;
    }
  }

  .slick-slide.slick-active.slick-center.slick-current {
    margin-right: 0;
  }

  button.slick-arrow::before {
    display: none !important;
  }

  button.slick-arrow {
    width: 50%;
    height: 100%;
    z-index: 1;

    &.slick-next {
      right: 0;
    }

    &.slick-prev {
      left: 0;
    }
  }

  .slick-dots li button:before {
    color: ${colors.gray100};
    font-size: 7px;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: ${colors.gray400};
    font-size: 7px;
    opacity: 1;
  }

  @media (${bpWidth.desktopSm}) {
    .slick-slide {
      height: 500px;

      img {
        height: 500px;
      }

      video {
        height: 500px;
      }
    }
  }

  @media (${bpWidth.tablet}) {
    .slick-list {
      padding-left: 0 !important;
    }

    .slick-slide.slick-active.slick-current {
      margin-left: 0;
      margin-right: 1rem;
    }

    .slick-slide {
      height: 450px;

      img {
        width: 87.5vw;
        height: 450px;
      }

      video {
        width: 87.5vw;
        height: 450px;
        object-fit: cover;
      }
    }

    .slick-dots.slick-thumb {
      bottom: -35px;
      display: flex;
      flex-shrink: 0;
    }
  }

  @media (${bpWidth.mobile}) {
    .slick-slide {
      height: 350px;

      img {
        width: 88vw;
        height: 350px;
      }

      video {
        width: 88vw;
        height: 350px;
      }
    }
  }
`;

const ProductVariantCarousels = (props) => {
  const { activeVariant, topCarousels } = props;

  const [variantSlides, setVariantSlides] = useState([]);
  const [carousel, setCarousel] = useState(null);

  // Show different carousel types for desktopLg/desktopSm vs tablet/mobile
  const { viewWidth } = useViewport();

  // Updating carousels based on color variant click
  useEffect(() => {
    setVariantSlides(topCarousels || []);
  }, [activeVariant, topCarousels]);

  useEffect(() => {
    if (viewWidth > 0) {
      if (viewWidth > sizes.containerMaxWidth.tablet) {
        setCarousel(<GridCarousel variantSlides={variantSlides} />);
      } else if (viewWidth <= sizes.containerMaxWidth.tablet) {
        setCarousel(<SwipeCarousel variantSlides={variantSlides} />);
      }
    } else {
      setCarousel(null);
    }
  }, [viewWidth, variantSlides]);

  return (
    <Row>
      <LargeCarouselCol id="largeCarousel" xs={8} sm={8} md={9} lg={11}>
        {carousel}
      </LargeCarouselCol>
    </Row>
  );
};

ProductVariantCarousels.propTypes = {
  activeVariant: string,
  topCarousels: array,
};
ProductVariantCarousels.defaultProps = {
  topCarousels: [],
};

export default ProductVariantCarousels;
