import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { AnimatePresence, motion } from 'framer-motion';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { breakpoint } from '../../constants/breakpoint';
import { useViewport } from '../../context/viewport.context';
import {
  bodyMBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  headerLFillStyles,
  headerSStyles,
  headerXlFillStyles,
  headerXxsStyles,
} from '../../styles/typography';
import { bpWidthTailwind, colors } from '../../styles/variables';

const BusinessHeroCol = styled(Col)`
  ${bodyXxsRegularBrownStyles};
  color: ${colors.gray300};

  &:after {
    content: 'SCROLL';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (${bpWidthTailwind.tablet}) {
    ${bodyXsRegularBrownStyles};
    color: ${colors.gray300};

    &:after {
      bottom: 22px;
    }
  }

  @media (${bpWidthTailwind.desktopSm}) {
    ${bodyMBoldBrownStyles};
    color: ${colors.gray300};

    &:after {
      bottom: 29px;
    }
  }

  @media (${bpWidthTailwind.desktopLg}) {
    ${headerXxsStyles};
    color: ${colors.gray300};

    &:after {
      bottom: 42px;
    }
  }

  .business-hero-component--content {
    ${headerSStyles};
    color: ${colors.beige100};

    .rictext-component--content {
      img {
        position: relative;
        transform: translateY(15%);
        max-width: none;
        height: 23px;
      }
    }

    @media (${bpWidthTailwind.tablet}) {
      ${headerLFillStyles};
      color: ${colors.beige100};

      .rictext-component--content {
        img {
          height: 32px;
        }
      }
    }

    @media (${bpWidthTailwind.desktopSm}) {
      ${headerXlFillStyles};
      color: ${colors.beige100};

      .rictext-component--content {
        img {
          height: 45px;
        }
      }
    }
  }
`;

const BusinessHero = ({ blok }) => {
  const animationVariants = {
    enter: {
      opacity: 0,
    },
    center: {
      zIndex: 1,
      opacity: 1,
    },
    exit: {
      zIndex: 0,
      opacity: 0,
    },
  };

  const { viewWidth } = useViewport();
  const videoRef = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoHeight, setVideoHeight] = useState(0);
  const textRef = useRef(null);
  const [text, setText] = useState(null);
  const [textWidth, setTextWidth] = useState(0);
  const [textIndex, setTextIndex] = useState(0);

  const cssClass = useMemo(
    () => ['business-hero-component--container relative', blok.customCssClass].filter(Boolean).join(' '),
    [blok.customCssClass],
  );

  const shuffleHeroText = useCallback(() => {
    if (blok.contents?.length > 0) {
      if (blok.contents.length === 1) {
        setText(blok.contents[0]);
      } else {
        setText(blok.contents[textIndex]);
      }

      setTimeout(() => {
        const textEl = textRef.current;
        if (textEl) {
          setTextWidth(textEl.clientWidth);
        }
      }, 500);

      let next = textIndex + 1;
      if (next >= blok.contents.length) {
        next = 0;
      }
      setTextIndex(next);
    }
  }, [blok.contents, textIndex, textRef.current]);

  useEffect(() => {
    const timer = setInterval(shuffleHeroText, 3000);
    return () => clearInterval(timer);
  }, [shuffleHeroText]);

  useEffect(() => {
    let videoSource = blok.videoMobile;
    if (!videoSource?.filename || (viewWidth >= breakpoint.md && blok.video?.filename)) {
      videoSource = blok.video;
    }

    setVideoSrc(videoSource);
  }, [viewWidth, blok]);

  // Determining video height, so it does not have black boarders on smaller screens
  useEffect(() => {
    // YouTube video width to height ratio 16:9 ~ 1.78
    const youtubeVideoEl = videoRef.current;
    if (youtubeVideoEl) {
      setTimeout(() => {
        const videoOffsetWidth = youtubeVideoEl.offsetWidth;
        setVideoHeight(videoOffsetWidth / (16 / 9));
      }, 1000);
    }
  }, [viewWidth, videoRef.current, blok]);

  return (
    <BusinessHeroCol {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={cssClass}>
      <Row>
        {/* YouTube video */}
        {(blok.video?.filename || blok.videoMobile?.filename) && blok.video?.is_external_url ? (
          <Col xs={8} md={10} lg={12} className="business-hero-component--video-col">
            <iframe
              ref={videoRef}
              width="100%"
              height={videoHeight}
              src={videoSrc?.filename}
              title={videoSrc?.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            />
          </Col>
        ) : (
          <Col xs={8} md={10} lg={12} className="business-hero-component--video-col">
            <div className="relative w-full">
              <video
                src={videoSrc?.filename}
                title={videoSrc?.title}
                autoPlay={blok.autoPlay}
                loop={blok.loop}
                muted={blok.muted}
                playsInline
                className="w-full relative z-0 object-cover"
              />
            </div>
            <div
              className={`absolute z-[1] top-0 w-full h-full opacity-20
              bg-[radial-gradient(88.75%_88.75%_at_50%_50%,_#0D5A88_0%,_#001219_100%)]`}
            ></div>
          </Col>
        )}
      </Row>
      <div className="business-hero-component--content flex absolute z-[2] w-full h-full top-0">
        <AnimatePresence>
          <motion.div
            ref={textRef}
            className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]"
            key={text?._uid}
            variants={animationVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              opacity: { duration: 1 },
            }}
          >
            {text && <StoryblokComponent blok={text} />}
          </motion.div>
        </AnimatePresence>
        <div
          className="absolute border-b-[2px] border-solid border-b-navy-200/80
            top-[50%] translate-y-[20px] sm:translate-y-[32px] md:translate-y-[43px]
            left-[50%] -translate-x-[50%]"
          style={{ width: textWidth }}
        ></div>
      </div>
    </BusinessHeroCol>
  );
};

BusinessHero.propTypes = {
  blok: object.isRequired,
};

export default BusinessHero;
