/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import PropTypes, { node } from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '../../icons/close-icon.svg';
import {
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  eyebrowMStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
} from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';

const PopUpContainer = styled.div`
  color: ${colors.navy600};

  h3 {
    ${eyebrowMStyles};
    line-height: 1;
  }

  h4 {
    ${eyebrowSStyles};
    padding-bottom: 20px;
  }

  p {
    ${bodySRegularBrownStyles};
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  sup {
    font-size: smaller;
    vertical-align: super;
  }

  @media (${bpWidth.desktopSm}) {
    h3 {
      ${eyebrowSStyles};
      line-height: 1;
    }

    h4 {
      ${eyebrowXsStyles};
      padding-bottom: 10px;
    }

    p {
      ${bodyXsRegularBrownStyles};
    }
  }

  @media (${bpWidth.mobile}) {
    h3 {
      overflow-wrap: break-word;
    }
  }
`;

const PopUpBackgroundRow = styled(Row)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 23;
`;

const PopUpCol = styled(Col)`
  background: ${colors.beige100};
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: ${(props) => props.padding && (props.padding.desktopLg || '50px')};
  width: ${(props) => props.width && (props.width.desktopLg || '40vw')};

  @media (${bpWidth.desktopSm}) {
    padding: ${(props) => props.padding && (props.padding.desktopSm || '40px')};
    width: ${(props) => props.width && (props.width.desktopSm || props.width.desktopLg || '50vw')};
  }
  @media (${bpWidth.tablet}) {
    padding: ${(props) => props.padding && (props.padding.tablet || undefined)};
    width: ${(props) =>
      props.width && (props.width.tablet || props.width.desktopSm || props.width.desktopLg || '60vw')};
  }
  @media (${bpWidth.mobile}) {
    padding: ${(props) => props.padding && (props.padding.mobile || undefined)};
    width: ${(props) =>
      props.width &&
      (props.width.mobile || props.width.tablet || props.width.desktopSm || props.width.desktopLg || '70vw')};
  }
`;

const HeadingCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
`;

const PopUpCopyRow = styled(Row)`
  overflow-x: hidden;
  overflow-y: scroll;

  &.fadedText {
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  }
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    background: ${colors.beige100};
    width: 12px;
    z-index: 1;

    polygon.st0 {
      fill: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 9px;
    }
  }
`;

function PopUp(props) {
  // State
  const {
    children,
    onPopUpClose,
    lockScrolling,
    closeOutsideX,
    isHeadless,
    isHideCloseIcon,
    popUpClass,
    heading,
    width,
    padding,
  } = props;

  const defaultClassName = 'mygita-popup';
  let classes = [defaultClassName];
  if (Array.isArray(popUpClass)) {
    classes = classes.concat(popUpClass);
  } else {
    classes.push(popUpClass);
  }
  classes = classes.join(' ');

  const [show, setShow] = useState(false);

  const [popUpBlur, setModalBlur] = useState('');
  const popUpStyle = {
    backgroundColor: popUpBlur,
  };
  const [popUpCopyContainer, setPopUpCopyContainer] = useState(null);
  const [popUpCopy, setPopUpCopy] = useState(null);

  // Freeze scrolling when popUp opens
  const popUpFreezeScroll = () => {
    if (show && lockScrolling) {
      document.body.classList.add('popUpFreezeScroll');
    }
  };

  useEffect(() => {
    // Show popUp if there are popUp entries in Contentful
    setShow(true);

    popUpFreezeScroll();

    // Setting popUp values
    if (typeof window !== 'undefined' && window.location.href.includes('/')) {
      setModalBlur('rgba(0, 18, 25, .6)');
    } else {
      setModalBlur('unset');
    }
  });

  // Close popUp functions
  const closeOnX = () => {
    setShow(false);
    document.body.classList.remove('popUpFreezeScroll');

    if (onPopUpClose) {
      onPopUpClose();
    }
  };

  const closeOnAnyClick = () => {
    if (closeOutsideX) {
      closeOnX();
    }
  };

  // Fade popup text if there's an overflow
  const fadeClassChange = (changeType) => {
    if (changeType !== 'addFade' && popUpCopyContainer?.classList.contains('fadedText')) {
      popUpCopyContainer?.classList.remove('fadedText');
    } else if (changeType === 'addFade' && !popUpCopyContainer?.classList.contains('fadedText')) {
      popUpCopyContainer?.classList.add('fadedText');
    }
  };

  const onScrollFadeClassChange = () => {
    if (popUpCopyContainer?.scrollTop > 0) {
      fadeClassChange('removeFade');
    } else if (popUpCopyContainer?.scrollTop === 0) {
      fadeClassChange('addFade');
    }
  };

  useEffect(() => {
    const popUpCopyContainerEl = document.getElementById('popUpCopyContainer');
    const popUpCopyEl = document.getElementById('popUpCopy');
    setPopUpCopyContainer(popUpCopyContainerEl);
    setPopUpCopy(popUpCopyEl);

    if (show) {
      if (popUpCopyContainer?.offsetHeight < popUpCopy?.offsetHeight) {
        // On initial popup open
        fadeClassChange('addFade');

        // Remove fade once user scrolls down
        popUpCopyContainer?.addEventListener('scroll', onScrollFadeClassChange);
      } else {
        fadeClassChange('removeFade');
      }
    }

    // Clean up listening events
    return () => {
      popUpCopyContainer?.removeEventListener('scroll', onScrollFadeClassChange);
    };
  }, [show]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // If window width is resized
      const handleWidthResize = () => {
        if (popUpCopyContainer?.offsetHeight < popUpCopy?.offsetHeight) {
          fadeClassChange('addFade');
        } else {
          fadeClassChange('removeFade');
        }
      };
      window.addEventListener('resize', handleWidthResize);

      // Clean up listening events
      return () => {
        window.removeEventListener('resize', handleWidthResize);
      };
    }
  }, []);

  return (
    <PopUpContainer>
      {show && (
        <PopUpBackgroundRow style={popUpStyle} onClick={closeOnAnyClick}>
          <PopUpCol width={width} padding={padding} className={classes}>
            {!isHeadless && (
              <Row>
                <HeadingCol xs={8} md={10} lg={12}>
                  <h3 dangerouslySetInnerHTML={{ __html: heading }} />

                  {!isHideCloseIcon && (
                    <CloseButton onClick={closeOnX}>
                      <CloseIcon />
                    </CloseButton>
                  )}
                </HeadingCol>
              </Row>
            )}
            <PopUpCopyRow id="popUpCopyContainer">
              <Col id="popUpCopy" xs={8} md={10} lg={12}>
                {children}
              </Col>
            </PopUpCopyRow>
          </PopUpCol>
        </PopUpBackgroundRow>
      )}
    </PopUpContainer>
  );
}

PopUp.propTypes = {
  children: node,
  onPopUpClose: PropTypes.func,
  lockScrolling: PropTypes.bool,
  closeOutsideX: PropTypes.bool,
  isHeadless: PropTypes.bool,
  isHideCloseIcon: PropTypes.bool,
  popUpClass: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string.isRequired)]),
  heading: PropTypes.string,
  width: PropTypes.object,
  padding: PropTypes.object,
};
PopUp.defaultProps = {
  lockScrolling: false,
  closeOutsideX: false,
  isHeadless: false,
  isHideCloseIcon: false,
  popUpClass: [],
};

export default PopUp;
