import React, { useEffect } from 'react';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import { useStoryblokStory } from '../../../hooks';

const BusinessHeaderWrapper = () => {
  const { storyblokStoryData } = useStoryblokStory('business/header');

  const { hamburgerMenuIsOpen, contactFormIsOpen } = useBusinessAppState();

  // prevent scroll if menu is open
  useEffect(() => {
    const bodyEl = document.querySelector('body');
    if (contactFormIsOpen || hamburgerMenuIsOpen) {
      bodyEl.classList.add('overflow-hidden');
    } else {
      bodyEl.classList.remove('overflow-hidden');
    }
  }, [contactFormIsOpen, hamburgerMenuIsOpen]);

  return storyblokStoryData?.content && <StoryblokComponent blok={storyblokStoryData.content} />;
};

export default BusinessHeaderWrapper;
