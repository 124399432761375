import React, { useEffect, useState } from 'react';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { array } from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { bpWidth, sizes } from '../../../styles/variables';

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 937px;
  justify-content: space-between;
  overflow-y: scroll;

  .rectangle {
    padding: 5px 0;
    width: 100%;
  }

  .square {
    width: 50%;

    .gatsby-image-wrapper.gatsby-image-wrapper-constrained,
    video {
      height: 100%;
      max-width: 100%;
    }

    &.left {
      .gatsby-image-wrapper.gatsby-image-wrapper-constrained,
      video {
        margin-right: 2.5px;
      }
    }

    &.right {
      .gatsby-image-wrapper.gatsby-image-wrapper-constrained,
      video {
        margin-left: 2.5px;
      }
    }
  }

  @media (${bpWidth.desktopSm}) {
    height: 693px;
  }

  @media (${bpWidth.tablet}) {
    display: none;
  }
`;

const GridCarousel = (props) => {
  const { variantSlides } = props;
  const { viewWidth } = useViewport();
  let slideCount = 0;

  const [slides, setSlides] = useState([]);
  // Videos for GridSlider
  useEffect(() => {
    setSlides(variantSlides);

    const videoEl = document.querySelector('video');
    if (videoEl && variantSlides.length > 0) {
      videoEl.load();
    }
  }, [variantSlides]);

  return (
    <GridContainer>
      {slides.map((slide, index) => {
        if (!slide.assetLg?.filename) {
          return undefined;
        }

        let slideClass = '';

        // Set  up custom grid container sizes
        if ((index + 1) % 3 === 0) {
          // Count the slide
          slideCount = 3;

          // Every 3rd slide, give it a 'rectangle' className
          slideClass = 'rectangle';
        } else {
          // Count the slides
          if (slideCount === 3) {
            slideCount = 1;
          } else {
            slideCount += 1;
          }

          // All other slides are squares
          if (slideCount === 1) {
            // For the 1st square in the row, give it a 'left' className
            slideClass = 'square left';
          } else {
            // For the 2nd square in the row, give it a 'right' className
            slideClass = 'square right';
          }
        }

        const { _uid } = slide;
        const loadType = index < 4 ? 'eager' : 'lazy';

        let imageUrl =
          slide.assetLg?.filename || slide.assetMd?.filename || slide.assetSm?.filename || slide.assetXs?.filename;

        if (viewWidth < sizes.containerMinWidth.tablet) {
          if (slide.assetXs?.filename) {
            imageUrl = slide.assetXs.filename;
          }
        } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
          if (slide.assetSm?.filename) {
            imageUrl = slide.assetSm.filename;
          }
        } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
          if (slide.assetMd?.filename) {
            imageUrl = slide.assetMd.filename;
          }
        }

        /** @type { GetGatsbyImageOptions } */
        let options = {
          layout: slide.layout,
          quality: slide.quality,
        };

        const artDirected = [];
        if (slide.assetXs?.filename) {
          artDirected.push({
            media: `(${bpWidth.mobile})`,
            image: getGatsbyImage(slide.assetXs.filename, options),
          });
        }

        if (slide.assetSm?.filename) {
          artDirected.push({
            media: `(${bpWidth.tablet})`,
            image: getGatsbyImage(slide.assetSm.filename, options),
          });
        }

        if (slide.assetMd?.filename) {
          artDirected.push({
            media: `(${bpWidth.desktopSm})`,
            image: getGatsbyImage(slide.assetMd.filename, options),
          });
        }

        options = {
          ...options,
        };
        const imageData = withArtDirection(getGatsbyImage(imageUrl, options), artDirected);
        let media;

        if (slide.mediaType === 'video') {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          media = (
            <video autoPlay loop muted playsInline>
              <source src={`${imageUrl}`} type="video/webm" />
            </video>
          );
        } else {
          media = <GatsbyImage image={imageData} loading={loadType} alt="" className="slideImage" />;
        }

        return (
          <div key={_uid} className={slideClass}>
            {media}
          </div>
        );
      })}
    </GridContainer>
  );
};

GridCarousel.propTypes = {
  variantSlides: array,
};
GridCarousel.defaultProps = {
  variantSlides: [],
};

export default React.memo(GridCarousel);
