import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { bool } from 'prop-types';
import styled from 'styled-components';
import Chevron from '../../icons/arrow-DCerulean.svg';
import { bpWidth, colors } from '../../styles/variables.js';

const FixedCtaRow = styled(Row)`
  align-items: center;
  background: ${colors.beige100};
  height: 120px;
  pointer-events: none;
  width: 100vw;
  z-index: 15;

  &#floatScrollCtaContainer {
    background: none;
    position: fixed;
    bottom: 0;
    z-index: 14;

    visibility: hidden;
    transition-property: visibility;
    transition-duration: 0.5s;

    &.show {
      visibility: visible;
      transition-property: display;
      transition-duration: 0.5s;
    }
  }
`;

const CtaCol = styled(Col)`
  @media (${bpWidth.mobile}) {
    text-align: right;
    text-align: -webkit-right;
  }
`;

const Cta = styled.div`
  background: ${colors.beige200};
  border-radius: 100%;
  box-shadow: 0 8px 8px -2px ${colors.beige500};
  cursor: pointer;
  height: 62px;
  pointer-events: auto;
  text-align: center;
  width: 62px;

  &:hover {
    margin-bottom: 20px;
    transition-property: margin;
    transition-duration: 0.25s;
  }

  svg {
    width: 21.96px;
    transform: rotate(180deg);
  }

  @media (${bpWidth.desktopSm}) {
    height: 40px;
    width: 40px;

    svg {
      width: 14.01px;
    }
  }
`;

function FixedScrollArrow(props) {
  const { float } = props;

  const notSSR = typeof window !== 'undefined';
  const [scrollContainer, setScrollContainer] = useState(notSSR && document.getElementById('floatScrollCtaContainer'));
  const [midScroll, setMidScroll] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);

  // Toggle show class for the scroll CTA
  const toggleShowClass = () => {
    if (currentScroll < midScroll) {
      if (scrollContainer && scrollContainer.classList.contains('show')) {
        scrollContainer.classList.remove('show');
      }
    } else if (scrollContainer && !scrollContainer.classList.contains('show')) {
      scrollContainer.classList.add('show');
    }
  };

  /* Arrow to return to top will pop-up halfway through the scroll */

  useEffect(() => {
    toggleShowClass();
    const onScroll = () => {
      setCurrentScroll(document.documentElement.scrollTop);
    };

    if (notSSR) {
      setScrollContainer(document.getElementById('floatScrollCtaContainer'));
      setMidScroll((window.innerHeight * (window.innerHeight / document.body.offsetHeight)) / 2);
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (notSSR) {
        window.addEventListener('scroll', onScroll);
      }
    };
  }, []);

  useEffect(() => {
    toggleShowClass();
  }, [currentScroll]);

  // On click scroll to top of page
  const scrollToTop = () => {
    const headerHeight = document.getElementById('headerContainerRow').getBoundingClientRect().height;

    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: headerHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <FixedCtaRow id={float ? 'floatScrollCtaContainer' : undefined} onClick={scrollToTop}>
      <Col xsOffset={1} mdOffset={0} xs={7} md={10} lg={12}>
        <Row>
          <CtaCol xsOffset={6} smOffset={7} mdOffset={9} lgOffset={11} xs={1}>
            <Cta className={float && 'floatCta'}>
              <Chevron />
            </Cta>
          </CtaCol>
        </Row>
      </Col>
    </FixedCtaRow>
  );
}

FixedScrollArrow.propTypes = {
  float: bool,
};
FixedScrollArrow.defaultProps = {
  float: false,
};

export default FixedScrollArrow;
