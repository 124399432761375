import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';

const Slider1Item = ({ blok }) => {
  const { asset } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {asset?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </Col>
  );
};

Slider1Item.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default Slider1Item;
