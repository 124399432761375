import React from 'react';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useBusinessAppState } from '../../../context/business-app-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import { inputLabels } from '../../../state/settings/businessSettingsReducer';
import BusinessFooterWrapper from '../Footer/BusinessFooterWrapper';
import BusinessHeaderWrapper from '../Header/BusinessHeaderWrapper';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const BodyWrapper = styled.div`
  flex: 1;
  padding-top: ${({ headerHeight }) => `${headerHeight}px`};
`;

const BusinessLayout = ({ blok }) => {
  const { dispatchSetting, headerHeight } = useBusinessAppState();

  useIsomorphicLayoutEffect(() => {
    dispatchSetting({
      name: inputLabels.showContactButton,
      value: blok.showContactButton || false,
    });
  }, [blok.showContactButton]);

  return (
    <Main {...storyblokEditable(blok)} id="bodyContent">
      <BusinessHeaderWrapper />

      <BodyWrapper headerHeight={headerHeight}>
        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </BodyWrapper>

      <BusinessFooterWrapper />
    </Main>
  );
};

BusinessLayout.propTypes = {
  blok: object.isRequired,
};

export default BusinessLayout;
