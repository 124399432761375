/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useViewport } from '../../../context/viewport.context';
import { sizes } from '../../../styles/variables';

const CustomSlider = styled(Slider)`
  .slick-slide {
    padding: 0 1.40625rem;
  }
`;

const ProductDetailSliderComponent = styled.div`
  &.pff-kilo {
    .slick-slide {
      padding: 0;
    }
  }

  &.pff-ras {
    .slick-slide {
      padding: 0.4375rem;
    }
  }

  &.pff-ras-spec {
    padding-bottom: 0;

    .slick-list {
      padding: 0 !important;
    }

    .slick-slide {
      padding: 0;
    }

    .btn-prev,
    .btn-next {
      top: 20rem;
      bottom: auto;
    }
  }

  &.pff-ras-feature {
    padding-bottom: 0;

    .slick-list {
      padding: 0 !important;
    }

    .slick-slide {
      padding: 0;
    }
  }
`;

const ProductDetailSlider = ({ blok }) => {
  const { slides } = blok;
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [settings, setSettings] = useState(null);
  const [showForTabletKilo, setShowForTabletKilo] = useState(false);
  const { viewWidth } = useViewport();

  useEffect(() => {
    const _showForTabletKilo = viewWidth === sizes.containerMaxWidth.tablet && blok.customCssClass === 'pff-kilo';
    setShowForTabletKilo(_showForTabletKilo);

    const _showForSmallerTabletKilo =
      viewWidth >= sizes.containerMinWidth.tablet &&
      viewWidth < sizes.containerMinWidth.desktopSm &&
      blok.customCssClass === 'pff-kilo';

    setSettings({
      infinite: false,
      speed: 500,
      slidesToScroll: 1,
      arrows: false,
      centerMode: blok.centerMode ?? false,
      autoplay: false,
      variableWidth: blok.variableWidth ?? false,
      className: 'product-detail-slider',
      beforeChange: (current, next) => {
        setActiveIndex(next);
      },
      onInit: () => {
        setTimeout(() => {
          const slideToGo = _showForSmallerTabletKilo ? 1 : 0;
          sliderRef?.current?.slickGoTo(slideToGo, true);
        });
      },
    });

    setTimeout(() => {
      const goToSlide = _showForSmallerTabletKilo ? 1 : 0;
      sliderRef?.current?.slickGoTo(goToSlide, true);
    });
  }, [blok.centerMode, blok.variableWidth, viewWidth, blok.customCssClass]);

  return (
    <ProductDetailSliderComponent className={['relative pb-16', blok.customCssClass].filter(Boolean).join(' ')}>
      {!showForTabletKilo && (
        <CustomSlider ref={sliderRef} {...settings}>
          {slides?.map((slide) => (
            <StoryblokComponent blok={slide} key={slide._uid} />
          ))}
        </CustomSlider>
      )}
      {showForTabletKilo && (
        <div className="grid grid-cols-3 justify-between">
          {slides?.map((slide) => (
            <StoryblokComponent blok={slide} key={slide._uid} />
          ))}
        </div>
      )}
      {!showForTabletKilo && slides?.length > 0 && (
        <>
          <button
            className="absolute right-[96px] bottom-0 w-[30px] h-[30px] text-beige-700 disabled:opacity-50 btn-prev"
            type="button"
            disabled={activeIndex === 0}
            onClick={() => sliderRef?.current?.slickPrev()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34" fill="none">
              <path
                /* eslint-disable-next-line max-len */
                d="M17 34C7.62167 34 0 26.3783 0 17C0 7.62167 7.62167 0 17 0C26.3783 0 34 7.62167 34 17C34 26.3783 26.3783 34 17 34ZM27.2 16.0556H11.8717L17.51 10.4172L16.1783 9.08556L8.93445 16.3294C8.56611 16.6978 8.56611 17.2928 8.93445 17.6611L16.1783 24.905L17.51 23.5733L11.8717 17.9444H27.2V16.0556Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            className="absolute right-12 bottom-0 w-[30px] h-[30px] text-beige-700 disabled:opacity-50 btn-next"
            type="button"
            disabled={activeIndex === slides.length - 1}
            onClick={() => sliderRef?.current?.slickNext()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 34 34" fill="none">
              <path
                /* eslint-disable-next-line max-len */
                d="M17 0C26.3783 0 34 7.62167 34 17C34 26.3783 26.3783 34 17 34C7.62167 34 0 26.3783 0 17C0 7.62167 7.62167 0 17 0ZM6.8 17.9444L22.1283 17.9444L16.49 23.5828L17.8217 24.9144L25.0656 17.6706C25.4339 17.3022 25.4339 16.7072 25.0656 16.3389L17.8217 9.095L16.49 10.4267L22.1283 16.0556L6.8 16.0556V17.9444Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </>
      )}
    </ProductDetailSliderComponent>
  );
};

ProductDetailSlider.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default ProductDetailSlider;
