import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import styled from 'styled-components';
import { useAppState } from '../../../context/app-state.context';
import { spacerXxStyles } from '../../../styles/structureSpacing.js';
import {
  bodyLinkStyles,
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxlRegularUtopiaStyles,
  bodyXxxlRegularUtopiaStyles,
  bodyXxxsRegularBrownStyles,
} from '../../../styles/typography.js';
import { bpWidth, colors } from '../../../styles/variables.js';
import FormikWrapper from '../Footer/FormikWrapper';

const MenuContainerWrapperRow = styled(Row)`
  height: 100%;
  background-color: ${colors.beige100};
  overflow: scroll;
`;

const MenuContainerCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 100px;

  a {
    ${bodyLinkStyles};
    color: ${colors.navy600};
  }
`;

const PrimaryDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  a {
    ${bodyXxxlRegularUtopiaStyles};
    text-transform: none;
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  @media (${bpWidth.mobile}) {
    padding-bottom: 30px;

    a {
      ${bodyXxlRegularUtopiaStyles};
    }
  }
`;

const SecondaryDiv = styled.div`
  display: flex;
  flex-direction: column;

  a {
    ${bodySRegularBrownStyles};
    text-transform: none;
    padding-bottom: 15px;

    &:first-child {
      padding-bottom: 40px;
    }
  }

  @media (${bpWidth.mobile}) {
    a {
      ${bodyXsRegularBrownStyles};
      text-transform: none;
      padding-bottom: 10px;

      &:first-child {
        padding-bottom: 30px;
      }
    }
  }
`;

const FormRow = styled(Row)`
  display: none;

  @media (${bpWidth.tablet}) {
    display: flex;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  @media (${bpWidth.mobile}) {
    padding-top: 80px;
    padding-bottom: 30px;
  }
`;

const MenuFooterRow = styled(Row)`
  border-top: 0.75px solid ${colors.gray100};
`;

const MenuFooterCol = styled(Col)`
  ${bodyXxxsRegularBrownStyles}
  ${spacerXxStyles}

  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 53px;
  }
`;

function Menu() {
  const { primaryNavLinks: primaryLinks, footerBlok, hamburgerMenuIsOpen: isOpen } = useAppState();
  const { logo, navigationLinks: secondaryLinks, rightsText, privacyPolicyText } = footerBlok;

  const copyrightDate = React.useMemo(() => new Date().getFullYear(), []);

  // Increase top margin on section if promo banner is live

  const promoBannerHeight = React.useMemo(() => {
    const promoBanner = document.getElementById('activePromoBanner');
    return promoBanner ? promoBanner.offsetHeight.toString().concat('px') : '0px';
  }, []);

  return (
    <MenuContainerWrapperRow isOpen={isOpen}>
      <MenuContainerCol xs={8} style={{ marginTop: promoBannerHeight }}>
        <Row>
          <Col xsOffset={1} xs={6}>
            <PrimaryDiv>
              {primaryLinks.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </PrimaryDiv>
            <SecondaryDiv>
              {secondaryLinks.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </SecondaryDiv>
            <FormRow>
              <Col xs={7} sm={6}>
                <FormikWrapper privacyPolicyText={privacyPolicyText} />
              </Col>
            </FormRow>
          </Col>
        </Row>
        <MenuFooterRow>
          <MenuFooterCol xsOffset={1} xs={6}>
            <p>
              © {copyrightDate} {rightsText}
            </p>
            {logo?.filename && (
              <a href="https://www.piaggiogroup.com/en" target="_blank" rel="noreferrer">
                <img src={logo.filename} alt="Piaggio Group Logo" />
              </a>
            )}
          </MenuFooterCol>
        </MenuFooterRow>
      </MenuContainerCol>
    </MenuContainerWrapperRow>
  );
}

export default Menu;
