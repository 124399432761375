import React, { useMemo } from 'react';
import Imgix from 'react-imgix';
import { Col } from 'react-styled-flexboxgrid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled from 'styled-components';
import ArrowDown from '../../../icons/arrow-down.svg';
import { buttonSStyles } from '../../../styles/typography';
import { colors } from '../../../styles/variables';

const MediaKitCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const DownloadButtonRow = styled.div`
  svg {
    ${buttonSStyles};
    cursor: pointer;
    color: ${colors.cerulean400};
    background-color: ${colors.beige100};
    margin: 5px 0;
    width: 100%;
    height: 20px;
    min-height: 20px;

    &:hover {
      color: ${colors.cerulean700};
    }
  }
`;

const MediaKit = ({ blok }) => {
  const isSvg = useMemo(() => blok.asset?.filename?.endsWith('.svg'), [blok.asset?.filename]);
  const isImgix = useMemo(() => blok.asset?.filename?.indexOf('imgix.net/') > 0, [blok.asset?.filename]);
  const storyblokRegex = /^(https?:)?\/\/a.storyblok.com\//;
  const isStoryblokAsset = useMemo(() => storyblokRegex.test(blok.asset?.filename), [blok.asset?.filename]);
  const file = useMemo(() => {
    const options = {
      layout: 'constrained',
      quality: 50,
      width: 240,
    };

    return getGatsbyImage(blok.asset?.filename, options);
  }, [blok.asset?.filename]);

  const downloadHandler = (url, title) => {
    fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', url);
        if (title) {
          link.download = title;
        }
        document.body.appendChild(link);
        link.click();
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e.message));
  };

  const inverseBgCss = useMemo(() => (blok.inverse ? 'bg-black' : ''), [blok.inverse]);
  const hasBorderCss = useMemo(() => (blok.hasBorder ? 'border border-solid border-gray-200' : ''), [blok.hasBorder]);
  const assetCssClass = useMemo(
    () => [inverseBgCss, hasBorderCss].filter(Boolean).join(' '),
    [inverseBgCss, hasBorderCss],
  );

  return blok.asset?.filename ? (
    <MediaKitCol {...storyblokEditable(blok)} xs={8} sm={4} md={3} lg={3}>
      <div className="flex flex-col items-start">
        <a href={blok.asset.filename} target="_blank" rel="noreferrer" className={assetCssClass}>
          {isSvg && !isImgix && <img src={blok.asset.filename} alt={blok.title || ''} width={240} />}
          {!isSvg && isStoryblokAsset && <GatsbyImage image={file} alt={blok.title || ''} loading="lazy" width={240} />}
          {isImgix && (
            <Imgix
              src={blok.asset.filename}
              imgixParams={{
                fit: blok.fit,
                crop: 'faces',
              }}
              htmlAttributes={{
                alt: blok.altText,
                title: blok.title,
              }}
              width={240}
            />
          )}
        </a>
        <DownloadButtonRow>
          <ArrowDown onClick={() => downloadHandler(blok.asset.filename, blok.title)} />
        </DownloadButtonRow>
      </div>
    </MediaKitCol>
  ) : undefined;
};

MediaKit.propTypes = {
  blok: object.isRequired,
};

export default MediaKit;
