/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { array, bool, func, string } from 'prop-types';
import styled from 'styled-components';
import { consentChoiceValue } from '../../common/constants/privacyPolicyPopup.constant';
import { useSsr } from '../../hooks';
import CloseIcon from '../../icons/close-icon.svg';
import {
  btnLgPrimaryMediumBlueStyles,
  btnLgPrimaryMediumDisabledBlueStyles,
  btnSmPrimaryMediumBlueStyles,
  btnSmPrimaryMediumDisabledBlueStyles,
  defaultBlueHover,
} from '../../styles/buttons';
import {
  bodySRegularBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  eyebrowMStyles,
  eyebrowSStyles,
  eyebrowXsStyles,
} from '../../styles/typography.js';
import { bpWidth, colors } from '../../styles/variables.js';

const PopUpContainer = styled.div`
  h3 {
    ${eyebrowMStyles};
    color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    line-height: 1;
  }

  h4 {
    ${eyebrowSStyles};
    color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    padding-bottom: 20px;
  }

  p {
    ${bodySRegularBrownStyles};
    color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  sup {
    font-size: smaller;
    vertical-align: super;
  }

  @media (${bpWidth.desktopSm}) {
    h3 {
      ${eyebrowSStyles};
      color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
      line-height: 1;
    }

    h4 {
      ${eyebrowXsStyles};
      color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
      padding-bottom: 10px;
    }

    p {
      ${bodyXsRegularBrownStyles};
      color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    }
  }

  @media (${bpWidth.mobile}) {
    h3 {
      overflow-wrap: break-word;
    }
  }
`;

const PopUpBackgroundRow = styled(Row)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 23;
`;

const PopUpCol = styled(Col)`
  background: ${({ lightMode }) => (lightMode ? colors.beige100 : colors.navy500)};
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 541px;
  max-height: 80vh;
  padding: 50px 25px 50px 50px;
  width: 29.3vw;
  min-width: 562px;
  max-width: 90vw;

  @media (${bpWidth.desktopSm}) {
    min-height: 452px;
    padding: 40px 15px 40px 40px;
    width: 35.2vw;
    min-width: 450px;
  }
  @media (${bpWidth.tablet}) {
    min-height: unset;
    width: 59.4vw;
    min-width: unset;
  }
  @media (${bpWidth.mobile}) {
    min-height: unset;
    width: 90vw;
    min-width: unset;
  }
`;

const HeadingCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;

  button {
    margin-right: 25px;
  }

  @media (${bpWidth.desktopSm}) {
    button {
      margin-right: 15px;
    }
  }
`;

const PopUpCopyRow = styled(Row)`
  ${bodySRegularBrownStyles};
  color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 25px;

  &.fadedText {
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  }

  .body {
    margin-bottom: 32px;
  }

  .consent-choice {
    ${eyebrowSStyles};
    color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    margin-bottom: 40px;
    display: flex;

    label {
      flex: 1 0 auto;
      display: flex;
      align-items: flex-start;

      input {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }

      .error {
        color: ${colors.orange700};
        text-transform: none;
      }
    }
  }

  .buttons {
    margin-bottom: 40px;
    text-align: center;

    button {
      ${btnLgPrimaryMediumBlueStyles};

      &:hover(:not(:disabled)) {
        ${defaultBlueHover};
      }

      &:disabled {
        ${btnLgPrimaryMediumDisabledBlueStyles};
      }
    }
  }

  .note {
    p {
      ${bodyXsRegularBrownStyles};
      color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
    }
  }

  @media (${bpWidth.desktopSm}) {
    ${bodyXsRegularBrownStyles};
    color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};

    .consent-choice {
      ${eyebrowXsStyles};
      color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};

      label {
        input {
          width: 13px;
          height: 13px;
        }
      }
    }

    .buttons {
      button {
        ${btnSmPrimaryMediumBlueStyles};

        &:disabled {
          ${btnSmPrimaryMediumDisabledBlueStyles};
        }
      }
    }

    .note {
      p {
        ${bodyXxsRegularBrownStyles};
        color: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
      }
    }
  }
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    background: ${({ lightMode }) => (lightMode ? colors.beige100 : colors.navy500)};
    width: 12px;
    z-index: 1;

    polygon.st0 {
      fill: ${({ lightMode }) => (lightMode ? colors.navy600 : colors.beige100)};
      display: flex;
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 9px;
    }
  }
`;

function PrivacyPolicyConfirmationPopup(props) {
  // State
  const { onPopUpClose, lockScrolling, closeOutsideX, popUpClass, exemptPages, lightMode } = props;

  const [show, setShow] = useState(false);
  const { isBrowser } = useSsr();

  const [popUpBlur, setModalBlur] = useState('');
  const popUpStyle = {
    backgroundColor: popUpBlur,
  };
  const [popUpCopyContainer, setPopUpCopyContainer] = useState(null);
  const [popUpCopy, setPopUpCopy] = useState(null);
  const [privacyPolicyPopupConsentChoice, setPrivacyPolicyPopupConsentChoice] = useState(null);

  const popUp = {
    heading: 'YOUR CONSENT<sup>1</sup>',
    body: '<p>Piaggio Fast Forward seeks your consent for Piaggio Fast Forward or its subsidiaries to process your personal data for marketing purposes in line with your preferences, habits, tastes and needs, and to send advertising material. This will include customized advertising material relating to products, services, initiatives and events. Your consent will also include consent to the processing of your personal data for the conducting of market research and customer satisfaction surveys. By consenting below, you appoint Piaggio Fast Forward or its subsidiaries as Data Processors for these purposes, consistent with the Privacy Policy published on this site.</p>',
    note: '<p><sup>1</sup>With the granting of consent to the processing of personal data, the person concerned declares to be over 13 years of age or the equivalent age as specified by law in your jurisdiction.</p>',
  };

  const consentChoices = [
    {
      id: 'i_do_not_consent',
      label: 'I do not consent',
      name: 'privacyPolicyPopupConsentChoice',
      value: consentChoiceValue.doNotConsent,
      hasHelperText: true,
    },
    {
      id: 'i_consent',
      label: 'I consent',
      name: 'privacyPolicyPopupConsentChoice',
      value: consentChoiceValue.consent,
    },
  ];

  // Freeze scrolling when popUp opens
  const popUpFreezeScroll = () => {
    if (show && lockScrolling) {
      document.body.classList.add('popUpFreezeScroll');
    }
  };

  useEffect(() => {
    // Show popUp if there are popUp entries in Contentful
    setShow(true);

    // Show popUp if url is NOT exempt
    if (isBrowser && exemptPages && exemptPages.includes(window.location.pathname)) {
      setShow(false);
    }

    popUpFreezeScroll();

    // Setting popUp values
    if (isBrowser && window.location.href.includes('/')) {
      setModalBlur('rgba(0, 18, 25, .6)');
    } else {
      setModalBlur('unset');
    }
  });

  // Close popUp functions
  const closeOnX = () => {
    setShow(false);
    document.body.classList.remove('popUpFreezeScroll');

    if (onPopUpClose) {
      onPopUpClose(false);
    }
  };

  const closeOnAnyClick = () => {
    if (closeOutsideX) {
      closeOnX();
    }
  };

  const popUpButtonClick = (e) => {
    e.preventDefault();

    if (onPopUpClose) {
      onPopUpClose(privacyPolicyPopupConsentChoice);
    }

    closeOnX();
  };

  // Fade popup text if there's an overflow
  const fadeClassChange = (changeType) => {
    if (changeType !== 'addFade' && popUpCopyContainer?.classList.contains('fadedText')) {
      popUpCopyContainer?.classList.remove('fadedText');
    } else if (changeType === 'addFade' && !popUpCopyContainer?.classList.contains('fadedText')) {
      popUpCopyContainer?.classList.add('fadedText');
    }
  };

  const onScrollFadeClassChange = () => {
    if (popUpCopyContainer?.scrollTop > 0) {
      fadeClassChange('removeFade');
    } else if (popUpCopyContainer?.scrollTop === 0) {
      fadeClassChange('addFade');
    }
  };

  const onConsentChoiceChanged = (value) => {
    setPrivacyPolicyPopupConsentChoice(value);
  };

  useEffect(() => {
    const popUpCopyContainerEl = document.getElementById('popUpCopyContainer');
    const popUpCopyEl = document.getElementById('popUpCopy');
    setPopUpCopyContainer(popUpCopyContainerEl);
    setPopUpCopy(popUpCopyEl);

    if (show) {
      if (popUpCopyContainer?.offsetHeight < popUpCopy?.offsetHeight) {
        // On initial popup open
        fadeClassChange('addFade');

        // Remove fade once user scrolls down
        popUpCopyContainer?.addEventListener('scroll', onScrollFadeClassChange);
      } else {
        fadeClassChange('removeFade');
      }
    }

    // Clean up listening events
    return () => {
      popUpCopyContainer?.removeEventListener('scroll', onScrollFadeClassChange);
    };
  }, [show]);

  useEffect(() => {
    const handleWidthResize = () => {
      if (popUpCopyContainer?.offsetHeight < popUpCopy?.offsetHeight) {
        fadeClassChange('addFade');
      } else {
        fadeClassChange('removeFade');
      }
    };

    handleWidthResize();

    if (isBrowser) {
      // If window width is resized
      window.addEventListener('resize', handleWidthResize);
    }

    // Clean up listening events
    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', handleWidthResize);
      }
    };
  }, []);

  return (
    <PopUpContainer lightMode={lightMode}>
      {show && (
        <PopUpBackgroundRow style={popUpStyle} onClick={closeOnAnyClick}>
          <PopUpCol lightMode={lightMode} className={popUpClass}>
            <Row>
              <HeadingCol xs={8} md={10} lg={12}>
                <h3 dangerouslySetInnerHTML={{ __html: popUp.heading }} />

                <CloseButton type="button" onClick={closeOnX} lightMode={lightMode}>
                  <CloseIcon />
                </CloseButton>
              </HeadingCol>
            </Row>
            <PopUpCopyRow id="popUpCopyContainer" lightMode={lightMode}>
              <Col id="popUpCopy" xs={8} md={10} lg={12}>
                <div className="body" dangerouslySetInnerHTML={{ __html: popUp.body }} />
                <div className="consent-choice">
                  {consentChoices.map((choice) => (
                    <label key={choice.id} htmlFor={choice.id}>
                      <input
                        type="radio"
                        id={choice.id}
                        name={choice.name}
                        value={choice.value}
                        checked={choice.value === privacyPolicyPopupConsentChoice}
                        onChange={() => onConsentChoiceChanged(choice.value)}
                      />
                      <div>
                        <div>{choice.label}</div>
                        {choice.hasHelperText &&
                          privacyPolicyPopupConsentChoice === consentChoiceValue.doNotConsent && (
                            <p className="error">Consent is required.</p>
                          )}
                      </div>
                    </label>
                  ))}
                </div>
                <div className="buttons">
                  <button
                    type="button"
                    onClick={popUpButtonClick}
                    disabled={privacyPolicyPopupConsentChoice !== consentChoiceValue.consent}
                  >
                    Submit
                  </button>
                </div>
                <div className="note" dangerouslySetInnerHTML={{ __html: popUp.note }} />
              </Col>
            </PopUpCopyRow>
          </PopUpCol>
        </PopUpBackgroundRow>
      )}
    </PopUpContainer>
  );
}

PrivacyPolicyConfirmationPopup.propTypes = {
  onPopUpClose: func,
  lockScrolling: bool,
  closeOutsideX: bool,
  popUpClass: string,
  exemptPages: array,
  lightMode: bool,
};
PrivacyPolicyConfirmationPopup.defaultProps = {
  lockScrolling: false,
  closeOutsideX: false,
  exemptPages: [],
  lightMode: true,
};

export default PrivacyPolicyConfirmationPopup;
