import React, { useMemo, useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import { object } from 'prop-types';
import styled, { css } from 'styled-components';
import { useAppState } from '../../../context/app-state.context';
import { usePressPageState } from '../../../context/press-page-state.context';
import { useIsomorphicLayoutEffect } from '../../../hooks';
import TabContentRow from './TabContentRow';
import TabHeader from './TabHeader';

const TabHeaderRow = styled(Row)`
  &.is-sticky {
    position: sticky;
    z-index: 4;
    top: 0;
    transition: transform 0.5s;
  }
  ${({ headerHeight, translateY }) => {
    let yValue;
    if (translateY < 0) {
      yValue = 0;
    } else if (translateY > 1) {
      yValue = 1;
    } else {
      yValue = translateY;
    }

    yValue = (yValue || 0) * headerHeight * -1;

    return css`
      &.is-pinned {
        top: ${headerHeight}px;
        transform: translate3d(0, ${yValue}px, 0);
      }
    `;
  }};
`;

const Tabs = ({ blok }) => {
  const { headerHeight, headerTranslateY } = useAppState();
  const { tabHeaderRowRef } = usePressPageState();
  const [activeTab, setActiveTab] = useState(null);
  const customCssClass = useMemo(
    () => ['tabs-component', blok.customCssClass].filter(Boolean).join(' '),
    [blok.customCssClass],
  );
  const tabHeaderRowOffsetTop = useMemo(
    () => tabHeaderRowRef?.current?.offsetTop || 0,
    [tabHeaderRowRef?.current?.offsetTop],
  );
  const tabHeaderCssClass = useMemo(
    () =>
      [
        'tabs-component--header-row',
        blok.stickyTop ? 'is-sticky' : undefined,
        tabHeaderRowOffsetTop > 0 ? 'is-pinned' : undefined,
      ]
        .filter(Boolean)
        .join(' '),
    [blok.stickyTop, tabHeaderRowOffsetTop],
  );

  useIsomorphicLayoutEffect(() => {
    if (!activeTab && blok.items?.length > 0) {
      const [firstItem] = blok.items;
      setActiveTab(firstItem._uid);
    }
  }, [blok.items]);

  return (
    blok.items?.length > 0 && (
      <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12} className={customCssClass}>
        <>
          <TabHeaderRow
            headerHeight={headerHeight}
            translateY={headerTranslateY}
            className={tabHeaderCssClass}
            ref={tabHeaderRowRef}
          >
            {(blok.items || []).map((nestedBlok) => (
              <TabHeader
                uid={nestedBlok._uid}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                title={nestedBlok.heading}
                mobileTitle={nestedBlok.mobileHeading}
                key={nestedBlok._uid}
              />
            ))}
          </TabHeaderRow>
          {(blok.items || []).map((nestedBlok) => (
            <TabContentRow
              uid={nestedBlok._uid}
              activeTab={activeTab}
              key={nestedBlok._uid}
              className="tabs-component--content-row"
            >
              <StoryblokComponent blok={nestedBlok} stickyTop={blok.stickyTop} />
            </TabContentRow>
          ))}
        </>
      </Col>
    )
  );
};

Tabs.propTypes = {
  blok: object.isRequired,
};

export default Tabs;
