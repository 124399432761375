/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { array, bool, func } from 'prop-types';
import styled from 'styled-components';
import { StoreContext } from '../../context/store-context';
import { useCartDiscountStatus } from '../../hooks';
import {
  btnLgPrimaryMediumBlueStyles,
  btnLgPrimaryWideOrangeStyles,
  btnLgSecondaryMediumBlueStyles,
  btnSmPrimaryMediumBlueStyles,
  btnSmPrimaryWideOrangeStyles,
  btnSmSecondaryMediumBlueStyles,
} from '../../styles/buttons';
import {
  bodyLBoldBrownStyles,
  bodyMBoldBrownStyles,
  bodyXsRegularBrownStyles,
  bodyXxsRegularBrownStyles,
  eyebrowMStyles,
  eyebrowSStyles,
} from '../../styles/typography';
import { bpWidth, colors } from '../../styles/variables';
import { legacyLineItems } from '../../utils';
import { currencyFormatter } from '../../utils/currencyFormatter';
import { dataLayerCheckout } from '../../utils/dataLayerEvents';
import Accessories from './Accessories';

const CartFooterRow = styled(Row)`
  position: relative;
`;

const CartFooterColTotalAndShipping = styled(Col)`
  padding-right: 4.5%;
  padding-left: 4.5%;
  position: relative;
  z-index: 1000;
  background-color: ${colors.beige100};

  @media (${bpWidth.desktopSm}) {
    padding-right: 17%;
    padding-left: 17%;
  }

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const CartFooterColSavings = styled(CartFooterColTotalAndShipping)``;

const CartFooterColButtons = styled(Col)`
  padding-right: 0;
  padding-left: 0;
  position: relative;
  z-index: 1000;
  background-color: ${colors.beige100};

  @media (${bpWidth.desktopSm}) {
    padding-right: 14%;
    padding-left: 14%;
  }

  @media (${bpWidth.tablet}) {
    padding-right: 0;
    padding-left: 0;
  }

  @media (${bpWidth.mobile}) {
    flex-basis: 80%;
    max-width: 80%;
    margin-left: 10%;
  }
`;

const CartFooterColButtonsPaddingCol = styled(Col)`
  :nth-child(1) {
    padding-right: 5px;
  }
  :nth-child(2) {
    padding-left: 5px;
  }

  @media (${bpWidth.desktopSm}) {
    :nth-child(1) {
      padding-right: 10px;
    }
    :nth-child(2) {
      padding-left: 10px;
    }
  }

  @media (${bpWidth.tablet}) {
    :nth-child(1) {
      padding-right: 4%;
      text-align: right;
    }
    :nth-child(2) {
      padding-left: 4%;
      text-align: left;
    }
  }

  @media (${bpWidth.mobile}) {
    :nth-child(1) {
      padding-right: 4.5%;
    }
    :nth-child(2) {
      padding-left: 4.5%;
    }
  }
`;

const TotalRow = styled(Row)`
  ${(props) =>
    props.discount
      ? `
      border-top: 0;
    `
      : `
      border-top: 2px solid ${colors.navy600};
    `};

  padding-top: 40px;

  @media (${bpWidth.tablet}) {
    padding-top: 35px;
  }
`;
const TotalText = styled.p`
  ${eyebrowMStyles};

  @media (${bpWidth.mobile}) {
    ${eyebrowSStyles};
  }
`;
const PriceText = styled.p`
  ${bodyLBoldBrownStyles};
  text-align: right;

  @media (${bpWidth.mobile}) {
    ${bodyMBoldBrownStyles};
  }
`;

const ShippingRow = styled(Row)`
  padding-top: 10px;
`;

const ShippingText = styled.p`
  ${bodyXsRegularBrownStyles};
  color: ${colors.gray300};
  text-align: right;

  @media (${bpWidth.desktopSm}) {
    ${bodyXxsRegularBrownStyles};
    color: ${colors.gray300};
  }
`;

const SavingsRow = styled(TotalRow)``;
const SavingsText = styled(TotalText)`
  color: ${colors.orange700};
`;
const SavingsAmountText = styled(PriceText)`
  color: ${colors.orange700};
`;
const PromotionNameRow = styled(ShippingRow)``;
const PromotionNameText = styled(ShippingText)``;

const CheckoutButtonRow = styled(Row)`
  padding-top: 40px;
  padding-bottom: 60px;
  text-align: center;

  @media (${bpWidth.desktopSm}) {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  @media (${bpWidth.tablet}) {
    justify-content: center;
  }

  @media (${bpWidth.mobile}) {
    padding-top: 25px;
    padding-bottom: 30px;
  }
`;

const KeepShoppingButton = styled.button`
  ${btnLgPrimaryWideOrangeStyles};
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${btnSmPrimaryWideOrangeStyles};
  }
`;

const BackButton = styled.button`
  ${btnLgSecondaryMediumBlueStyles};
  cursor: pointer;
  max-width: 100%;

  @media (${bpWidth.desktopSm}) {
    ${btnSmSecondaryMediumBlueStyles};
  }
  @media (${bpWidth.tablet}) {
    width: 100%;
    max-width: 150px;
  }
`;

const CheckoutButton = styled.button`
  ${btnLgPrimaryMediumBlueStyles};
  cursor: pointer;
  max-width: 100%;

  @media (${bpWidth.desktopSm}) {
    ${btnSmPrimaryMediumBlueStyles};
  }
  @media (${bpWidth.tablet}) {
    width: 100%;
    max-width: 150px;
  }
`;

const CartFooter = React.forwardRef((props, ref) => {
  const { showAccessories, accessoriesExpanded, handleAccessoriesClick, mainProductsInCart } = props;

  const { accessoriesContainerEl, accessoriesHeaderRowEl, accessoriesBodyRowEl } = ref;

  const { cart, loading, onToggle: handleCartToggle } = useContext(StoreContext);

  const { cartDiscountStatus, cartDiscountNames, cartDiscountAmount } = useCartDiscountStatus(cart);

  const lineItems = useMemo(() => legacyLineItems(cart.lines), [cart]);

  const handleCheckout = () => {
    try {
      dataLayerCheckout(lineItems);
      window.open(cart.checkoutUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      window.open(cart.checkoutUrl);
    }
  };

  return (
    <CartFooterRow>
      {cart.totalQuantity > 0 && showAccessories && (
        <Accessories
          accessoriesExpanded={accessoriesExpanded}
          handleAccessoriesClick={handleAccessoriesClick}
          ref={{ accessoriesContainerEl, accessoriesHeaderRowEl, accessoriesBodyRowEl }}
          mainProductsInCart={mainProductsInCart}
        />
      )}

      {cartDiscountStatus && (
        <CartFooterColSavings xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={0} md={10} lgOffset={1} lg={10}>
          <SavingsRow>
            <Col xsOffset={0} xs={4} smOffset={0} sm={4} mdOffset={0} md={5} lgOffset={0} lg={6}>
              <SavingsText>Savings</SavingsText>
            </Col>
            <Col xsOffset={0} xs={4} smOffset={0} sm={4} mdOffset={0} md={5} lgOffset={0} lg={6}>
              <SavingsAmountText>
                {currencyFormatter(cartDiscountAmount, { currency: cart.cost?.totalAmount?.currencyCode })}
              </SavingsAmountText>
            </Col>
          </SavingsRow>

          <PromotionNameRow>
            <Col xs={8} sm={8} md={10} lg={12}>
              {cartDiscountNames && <PromotionNameText>{cartDiscountNames.join(', ')}</PromotionNameText>}
            </Col>
          </PromotionNameRow>
        </CartFooterColSavings>
      )}

      <CartFooterColTotalAndShipping xsOffset={1} xs={6} smOffset={1} sm={6} mdOffset={0} md={10} lgOffset={1} lg={10}>
        <TotalRow discount={cartDiscountStatus}>
          <Col xsOffset={0} xs={4} smOffset={0} sm={4} mdOffset={0} md={5} lgOffset={0} lg={6}>
            <TotalText>Total</TotalText>
          </Col>
          <Col xsOffset={0} xs={4} smOffset={0} sm={4} mdOffset={0} md={5} lgOffset={0} lg={6}>
            <PriceText>
              {loading
                ? 'Loading...'
                : cart.totalQuantity === 0
                  ? currencyFormatter(0)
                  : currencyFormatter(cart.cost?.totalAmount?.amount, {
                      currency: cart.cost?.totalAmount?.currencyCode,
                    })}
            </PriceText>
          </Col>
        </TotalRow>

        <ShippingRow>
          <Col xs={8} sm={8} md={10} lg={12}>
            <ShippingText>Includes free shipping</ShippingText>
            <ShippingText>5-10 business days</ShippingText>
          </Col>
        </ShippingRow>
      </CartFooterColTotalAndShipping>

      <CartFooterColButtons xsOffset={0} xs={8} smOffset={1} sm={6} mdOffset={0} md={10} lgOffset={1} lg={10}>
        <CheckoutButtonRow>
          {cart.totalQuantity === 0 ? (
            <Col xs={8} sm={8} md={10} lg={12}>
              <KeepShoppingButton onClick={() => handleCartToggle(false)}>SHOP NOW</KeepShoppingButton>
            </Col>
          ) : (
            <>
              <CartFooterColButtonsPaddingCol xs={4} sm={4} md={5} lg={6}>
                <BackButton onClick={handleCartToggle}>BACK</BackButton>
              </CartFooterColButtonsPaddingCol>

              <CartFooterColButtonsPaddingCol xs={4} sm={4} md={5} lg={6}>
                <CheckoutButton onClick={handleCheckout}>CHECKOUT</CheckoutButton>
              </CartFooterColButtonsPaddingCol>
            </>
          )}
        </CheckoutButtonRow>
      </CartFooterColButtons>
    </CartFooterRow>
  );
});

CartFooter.propTypes = {
  showAccessories: bool,
  accessoriesExpanded: bool,
  handleAccessoriesClick: func,
  mainProductsInCart: array,
};
CartFooter.defaultProps = {
  showAccessories: true,
  accessoriesExpanded: false,
  mainProductsInCart: [],
};

export default CartFooter;
