import React, { useMemo } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { func, number, string } from 'prop-types';
import styled from 'styled-components';
import Chevron from '../../../icons/arrow-LCerulean.svg';
import { bodyMBoldBrownStyles, bodySBoldBrownStyles } from '../../../styles/typography.js';
import { bpWidth, colors } from '../../../styles/variables.js';

const PaginationCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  height: fit-content;

  &.nextCtaOnly {
    justify-content: flex-end;
  }
  &.prevCtaOnly {
    justify-content: flex-start;
  }
  &.bothCtas {
    justify-content: space-between;
  }

  svg {
    margin-left: 10px;
    width: 5px;
    height: 10px;
    path#Side {
      stroke: ${colors.navy600};
    }
  }

  @media (${bpWidth.desktopSm}) {
    svg {
      width: 3px;
      height: 9px;
    }
  }
`;

const NextButton = styled.div`
  ${bodyMBoldBrownStyles};
  line-height: 1;
  color: ${colors.navy600};
  background: none;
  border: none;
  cursor: pointer;

  @media (${bpWidth.desktopSm}) {
    ${bodySBoldBrownStyles};
    line-height: 1;
    color: ${colors.navy600};
  }
`;

const PreviousButton = styled(NextButton)`
  svg {
    margin-left: 0;
    margin-right: 10px;
    transform: rotate(180deg);
  }
`;

function Pagination(props) {
  // Effects
  const { paginationClass, currentPage, perPage, total, goPrevious, goNext } = props;
  const totalLoaded = useMemo(() => currentPage * perPage, [currentPage, perPage]);

  return (
    <PaginationCol xsOffset={0} xs={8} md={10} lg={12} className={paginationClass}>
      {totalLoaded > perPage && (
        <PreviousButton onClick={() => goPrevious()}>
          <Chevron /> Previous
        </PreviousButton>
      )}
      {totalLoaded < total && (
        <NextButton onClick={() => goNext()}>
          Next <Chevron />
        </NextButton>
      )}
    </PaginationCol>
  );
}

Pagination.propTypes = {
  paginationClass: string,
  currentPage: number,
  perPage: number,
  total: number,
  goPrevious: func,
  goNext: func,
};
Pagination.defaultProps = {
  paginationClass: '',
  currentPage: 0,
  perPage: 0,
  total: 0,
  goPrevious: () => {},
  goNext: () => {},
};

export default Pagination;
