import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { storyblokEditable } from 'gatsby-source-storyblok';
import PropTypes from 'prop-types';
import '../../styles/fonts/brown.css';
import '../../styles/fonts/utopia.css';

const PDFViewer = ({ blok }) => {
  const { dataSrc } = blok;

  return (
    <Col {...storyblokEditable(blok)} xs={8} md={10} lg={12}>
      {dataSrc?.filename && (
        <div>
          <embed src={dataSrc.filename} width="100%" height="820px" type="application/pdf" />
        </div>
      )}
    </Col>
  );
};

PDFViewer.propTypes = {
  blok: PropTypes.any.isRequired,
};

export default PDFViewer;
